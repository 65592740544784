import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '@utils/utils.module';
import { MatDatepickerModule, MatDialog, MatDialogModule, MatDialogRef, MatFormFieldModule, MatInputModule, MatPaginatorModule, MatRadioModule, MatSelectModule, MatSlideToggleModule, MatSortModule, MatTableModule, MatTabsModule, MatTooltipModule, MAT_DIALOG_DATA } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { ContratoRoutingModule } from './contrato-routing.module';
import { ContratoGridPageComponent } from './pages/contrato-grid-page/contrato-grid-page.component';
import { ContratoGridComponent } from './components/contrato-grid/contrato-grid.component';
import { ContratoGridFiltroComponent } from './components/contrato-grid-filtro/contrato-grid-filtro.component';
import { ContratoSituacaoService } from './shared/services/contrato-situacao.service';
import { ContratoStatusService } from './shared/services/contrato-status.service';
import { ContratoService } from './shared/services/contrato.service';
import { ContratoDetalheComponent } from './components/contrato-detalhe/contrato-detalhe.component';
import { ContratoDetalheItemGridComponent } from './components/contrato-detalhe-item-grid/contrato-detalhe-item-grid.component';
import { ContratoDetalheItemGridFiltroComponent } from './components/contrato-detalhe-item-grid-filtro/contrato-detalhe-item-grid-filtro.component';
import { ContratoDetalhePageComponent } from './pages/contrato-detalhe-page/contrato-detalhe-page.component';
import { ContratoDetalheResolver } from './shared/resolvers/contrato-detalhe.resolver';
import { ContratoStatusListaResolver } from './shared/resolvers/contrato-status-lista.resolver';
import { ContratoSituacaoListaResolver } from './shared/resolvers/contrato-situacao-lista.resolver';
import { ContratoEdicaoService } from './shared/services/contrato-edicao.service';
import { EditarContratoComponent } from './components/editar-contrato/editar-contrato.component';
import { ModalEditarContratoComponent } from './modal/modal-editar-contrato/modal-editar-contrato.component';
import { ContratoModalService } from './shared/services/contrato-modal.service';
import { ContratoHistoricoComponent } from './components/contrato-historico/contrato-historico.component';
import { ContratoHistoricoDetalheComponent } from './components/contrato-historico-detalhe/contrato-historico-detalhe.component';
import { ContratoHistoricoService } from './shared/services/contrato-historico.service';
import { ModalHistoricoContratoComponent } from './modal/modal-historico-contrato/modal-historico-contrato.component';
import { ModalHistoricoDetalheContratoComponent } from './modal/modal-historico-detalhe-contrato/modal-historico-detalhe-contrato.component';
import { ContratoItemService } from './shared/services/contrato-item.service';
import { ContratoHistoricoItemComponent } from './components/contrato-historico-item/contrato-historico-item.component';
import { ContratoHistoricoDetalheItemComponent } from './components/contrato-historico-detalhe-item/contrato-historico-detalhe-item.component';
import { ModalHistoricoContratoItemComponent } from './modal/modal-historico-contrato-item/modal-historico-contrato-item.component';
import { ModalHistoricoDetalheContratoItemComponent } from './modal/modal-historico-detalhe-contrato-item/modal-historico-detalhe-contrato-item.component';
import { ContratoGuard } from './contrato.guard';
import { ModalContratoAtualizarReajusteComponent } from './modal/modal-contrato-atualizar-reajuste/modal-contrato-atualizar-reajuste.component';
import { ModalContratoAtualizarVigenciaComponent } from './modal/modal-contrato-atualizar-vigencia/modal-contrato-atualizar-vigencia.component';
import { ContratoAtualizarReajusteComponent } from './components/contrato-atualizar-reajuste/contrato-atualizar-reajuste.component';
import { ContratoAtualizarVigenciaComponent } from './components/contrato-atualizar-vigencia/contrato-atualizar-vigencia.component';
import { ContratoTipoReajusteService } from './shared/services/contrato-tipo-reajuste.service';
import { ContratoReajusteService } from './shared/services/contrato-reajuste.service';
import { ContratoAtualizarVigenciaService } from './shared/services/contrato-atualizar-vigencia.service';
import { ContratoAjustarValorComponent } from './components/contrato-ajustar-valor/contrato-ajustar-valor.component';
import { ModalContratoAjustarValorComponent } from './modal/modal-contrato-ajustar-valor/modal-contrato-ajustar-valor.component';
import { ContratoAtualizarValorService } from './shared/services/contrato-atualizar-valor.service';
import { ContratoDocumentoDownloadService } from './shared/services/contrato-documento-download.service';
import { ContratoDocumentoUploadService } from './shared/services/contrato-documento-upload.service';
import { ContratoDocumentacaoComponent } from './components/contrato-documentacao/contrato-documentacao.component';
import { ModalDocumentoContratoComponent } from './modal/modal-documento-contrato/modal-documento-contrato.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ContratoItemListaResolver } from './shared/resolvers/contrato-itens-lista.resolver';
import { PreContratoGridFiltroComponent } from './components/pre-contrato-grid-filtro/pre-contrato-grid-filtro.component';
import { PreContratoGridComponent } from './components/pre-contrato-grid/pre-contrato-grid.component';
import { PreContratoService } from './shared/services/pre-contrato.service';
import { ContratoPrioridadeListaResolver } from './shared/resolvers/contrato-prioridade-lista.resolver';
import { ContratoPrioridadeService } from './shared/services/contrato-prioridade.service';
import { ContratoSegmentoService } from './shared/services/contrato-segmento.service';
import { ContratoSegmentoListaResolver } from './shared/resolvers/contrato-segmento-lista.resolver';
import { PreContratoDetalhePageComponent } from './pages/pre-contrato-detalhe-page/pre-contrato-detalhe-page.component';
import { PreContratoDetalheComponent } from './components/pre-contrato-detalhe/pre-contrato-detalhe.component';
import { PreContratoDetalheResolver } from './shared/resolvers/pre-contrato-detalhe.resolver';
import { ContratoEstadoResolver } from './shared/resolvers/contrato-estado.resolver';
import { ContratoAdendoGridComponent } from './components/contrato-adendo-grid/contrato-adendo-grid.component';
import { ContratoAdendoFiltroComponent } from './components/contrato-adendo-filtro/contrato-adendo-filtro.component';
import { ModalContratoAddAditivoComponent } from './modal/modal-contrato-add-aditivo/modal-contrato-add-aditivo.component';
import { PreContratoAddAditivoComponent } from './components/pre-contrato-add-aditivo/pre-contrato-add-aditivo.component';


@NgModule({
  declarations: [
    //Components
    EditarContratoComponent,
    ContratoGridPageComponent,
    ContratoDetalhePageComponent,
    ContratoGridComponent,
    ContratoGridFiltroComponent,
    ContratoHistoricoComponent,
    ContratoHistoricoDetalheComponent,
    ContratoDetalheComponent,
    ContratoDetalheItemGridComponent,
    ContratoDetalheItemGridFiltroComponent,
    ContratoHistoricoItemComponent,
    ContratoHistoricoDetalheItemComponent,
    ContratoAtualizarReajusteComponent,
    ContratoAtualizarVigenciaComponent,
    ContratoAjustarValorComponent,
    ContratoDocumentacaoComponent,

    //Modais
    ModalHistoricoContratoComponent,
    ModalHistoricoDetalheContratoComponent,
    ModalHistoricoContratoItemComponent,
    ModalHistoricoDetalheContratoItemComponent,
    ModalContratoAtualizarVigenciaComponent,
    ModalEditarContratoComponent,
    ModalContratoAtualizarReajusteComponent,
    ModalContratoAjustarValorComponent,
    ModalDocumentoContratoComponent,
    PreContratoGridFiltroComponent,
    PreContratoGridComponent,
    PreContratoDetalhePageComponent,
    PreContratoDetalheComponent,
    ContratoAdendoGridComponent,
    ContratoAdendoFiltroComponent,
    ModalContratoAddAditivoComponent,
    PreContratoAddAditivoComponent,
  ],
  imports: [
    CommonModule,
    ContratoRoutingModule,

    UtilsModule,

    MatDialogModule,

    // TABLE
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatTooltipModule,


    // FORMULARIO
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatRadioModule,
    NgxMatSelectSearchModule,
    MatTabsModule,

  ],

  providers: [
    //Guards
    ContratoGuard,

    //Services
    ContratoService,
    ContratoSituacaoService,
    ContratoStatusService,
    ContratoEdicaoService,
    ContratoHistoricoService,
    ContratoItemService,
    ContratoTipoReajusteService,
    ContratoReajusteService,
    ContratoAtualizarVigenciaService,
    ContratoAtualizarValorService,
    ContratoDocumentoDownloadService,
    ContratoDocumentoUploadService,
    ContratoPrioridadeService,
    ContratoSegmentoService,

    PreContratoService,

    //Resolvers
    ContratoDetalheResolver,
    ContratoStatusListaResolver,
    ContratoSituacaoListaResolver,
    ContratoItemListaResolver,
    ContratoPrioridadeListaResolver,
    ContratoSegmentoListaResolver,
    PreContratoDetalheResolver,
    ContratoEstadoResolver,


    //Modais
    ContratoModalService,

    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
  ],
  entryComponents: [
    ModalEditarContratoComponent,
    ModalHistoricoContratoComponent,
    ModalHistoricoDetalheContratoComponent,
    ModalHistoricoContratoItemComponent,
    ModalHistoricoDetalheContratoItemComponent,
    ModalContratoAtualizarReajusteComponent,
    ModalContratoAtualizarVigenciaComponent,
    ModalContratoAjustarValorComponent,
    ModalDocumentoContratoComponent,
    ModalContratoAddAditivoComponent,
  ],
})
export class ContratoModule { }
