import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { Banco } from 'app/main/banco/shareds/interfaces/banco.interface';
import { Empresa } from 'app/main/empresa/shareds/interfaces/empresa.interface';
import { ChecklistRelacionamento } from '../../shared/interfaces/checklist-relacionamento.interface';
import { Checklist } from '../../shared/interfaces/checklist.interface';
import { FiltroChecklistRelacionamento } from '../../shared/interfaces/filtro-checklist-relacionamento.interface';
import { ChecklistRelacionamentoEmpresaModalService } from '../../shared/services/checklist-relacionamento-empresa-modal.service';
import { ChecklistRelacionamentoService } from '../../shared/services/checklist-relacionamento.service';

@Component({
    selector: 'checklist-relacionamento',
    templateUrl: './checklist-relacionamento.component.html',
    styleUrls: ['./checklist-relacionamento.component.scss'],
})
export class ChecklistRelacionamentoComponent
    extends GridBase<
        ChecklistRelacionamento,
        FiltroChecklistRelacionamento,
        ChecklistRelacionamentoService
    >
    implements OnInit {
    @Input() checklist: Checklist;
    @Output() gravando = new EventEmitter();
    bancos: Array<Banco> = [];
    empresas: Array<Empresa> = [];
    isVisualizacao: boolean = false;

    @Input() validate: (valid: boolean) => void;
    firstTime: boolean = true;

    constructor(
        protected _service: ChecklistRelacionamentoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        private _checklistRelacionamentoEmpresaModalService: ChecklistRelacionamentoEmpresaModalService
    ) {
        super(_service, _injector, _changeDetectorRefs);
    }

    ngOnInit(): void {
        if (this.checklist) {
            this.filtro.idChecklist = this.checklist.id;
        }
        this._route.data.subscribe((data) => {
            this.isVisualizacao = data.isVisualizacao;
            this.bancos = data.bancos;
            this.empresas = data.empresas;
        });
        this.paginacao = {
            length: 0,
            pageSize: 25,
        };
        this.initGrid(this.filtro || {});
    }
    ngOnChanges({ validate }) {
        this.atualizarGrid(this.filtro || {});
        validate.currentValue(true)
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'produto', label: '' },
            { chave: 'modulo', label: '' },
            { chave: 'bancosContradados', label: '' },
            { chave: 'bancosRelacionados', label: '' },
            { chave: 'empresasContratadas', label: '' },
            { chave: 'empresasRelacionadas', label: '' },
            { chave: 'contasContratadas', label: '' },
            { chave: 'contasRelacionadas', label: '' },
        ];
    }

    editar(relacionamento: ChecklistRelacionamento): void {
        this._checklistRelacionamentoEmpresaModalService
            .checklistRelacionamentoEmpresaCadastro({
                relacionamento,
                checklist: this.checklist,
                bancos: this.bancos,
                empresas: this.empresas,
            })
            .subscribe(() => {
                this.gravando.emit(true);
                this.atualizarGrid(this.filtro);
            });
    }

    visualizar(relacionamento: ChecklistRelacionamento): void {
        this._checklistRelacionamentoEmpresaModalService.checklistRelacionamentoEmpresaDetalhe(
            {
                relacionamento,
                checklist: this.checklist,
                bancos: this.bancos,
                empresas: this.empresas,
            }
        );
    }
}
