import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FormBase } from '@utils/base/form-base/form-base';
import { ChecklistContatoBanco } from '../../shared/interfaces/checklist-contato-banco.interface';
import { Checklist } from '../../shared/interfaces/checklist.interface';
import { ChecklistContatoBancoService } from '../../shared/services/checklist-contato-banco.service';
import { AuthLocalStorageService } from '@utils/services/auth-local-storage.service';
import { UsuarioLogado } from '@utils/interfaces/usuario/usuario-logado.interface';
import { ChecklistModalService } from '../../shared/services/checklist-modal.service';
import { Banco } from '../../../banco/shareds/interfaces/banco.interface';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { OptionsConfig } from '@utils/base/form-base/options-config.interface';

@Component({
    selector: 'checklist-contato-banco',
    templateUrl: './checklist-contato-banco.component.html',
    styleUrls: ['./checklist-contato-banco.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ChecklistContatoBancoComponent
    extends FormBase
    implements OnInit {
    @Input() checklist: Checklist;

    @Input() validate: (valid: boolean) => void;

    @Output() gravando = new EventEmitter();

    isVisualizacao: boolean = false;

    isEdit = false;
    indexEdit = {};
    firstTime: boolean = true;

    CELLPHONE = '(00) 0 0000-0000';
    LANDLINE = '(00) 0000-0000';

    phoneMask = this.LANDLINE;
    phoneNumber = '';
    cellphoneMask = this.CELLPHONE;
    cellphoneNumber = '';

    previusLength = 0;
    usuarioCliente = false;
    contatos: Array<ChecklistContatoBanco> = [];
    usuarioLogado: UsuarioLogado;
    listaBancos: Array<Banco> = [];
    isEditing = false;
    lists: {
        bancos: Banco[]
    } = {
            bancos: []
        };

    constructor(
        private _injector: Injector,
        private _authLocalStorage: AuthLocalStorageService,
        private _checklistContatoBancoService: ChecklistContatoBancoService,
        private _modalChecklist: ChecklistModalService,
    ) {
        super(_injector);
        this.usuarioLogado = _authLocalStorage.getUsuario();
    }

    ngOnInit(): void {
        this._route.data.subscribe((data) => {
            this.contatos = data.contatos.contatos;
            this.isVisualizacao = data.isVisualizacao;
            this.listaBancos = data.banco
            this.optionList.listaBancos = data.bancos;
            this.refreshOptionsConfig();
            this.filteredList['listaBancos'] = this.listaBancos
            this.form = this._formBuilder.group(
                this.crateForm() || null
            );

            const filter = this.form.get('filterBancos');
            filter.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    const { value } = filter;
                    if (!value) {
                        this.filteredList['listaBancos'] = [...this.listaBancos];
                        return;
                    }
                    const search = value.toLowerCase();
                    this.filteredList['listaBancos'] =
                        this.listaBancos.filter(result => result.descricaoCompleta.toLowerCase().indexOf(search) > -1)
                });

        });


        if (this.usuarioLogado.perfis[0].nome === 'Cliente') {
            this.usuarioCliente = true;
        }
    }

    ngOnChanges({ validate }) {
        if (this.firstTime) {
            this.firstTime = false;
            return;
        }
        const valid = !Object.values(this.form.controls).some((control) => control.dirty) && !this.contatos.some((contato) => !contato.id)
        validate.currentValue(valid)
    }

    checklistEmAndamento(checklist: Checklist): boolean {
        return checklist.checklistStatus.id !== 4 && checklist.checklistStatus.id !== 5;
    }

    protected crateForm(): { [key: string]: any } {
        return {
            nome: ['', Validators.required],
            telefone: ['', Validators.required],
            celular: ['', Validators.required],
            email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
            banco: ['', Validators.required],
            index: [],
            filterBancos: []
        };
    }

    limparForm(): void {
        this.isEdit = false;
        this.isEditing = false;
        this.form.get('telefone').setValue(' ');
        this.form.get('celular').setValue(' ');
        this.form.reset();
        this.form.controls.email.enable()
    }

    adicionar(): void {
        const contato: ChecklistContatoBanco = this.form.value;
        this.contatos.push(contato);
        this.limparForm();
        this.gravar()
    }

    atualizar(): void {
        this.form.controls.email.enable()
        this.isEditing = false;
        const index = this.form.get('index').value;
        this.contatos[index] = this.form.value;
        this.limparForm();
        this.gravar()
    }

    remover(contato: ChecklistContatoBanco, index: number): void {
        this._confirmation
            .confirm({ titulo: `Deseja remover o contato?` })
            .subscribe((result) => {
                if (result) {
                    this.contatos.splice(index, 1);
                }
                this.gravar()
            });
    }


    editar(contato: ChecklistContatoBanco, index: number): void {
        this.isEditing = true;
        this.isEdit = true;
        if (contato.telefone) {
            this.phoneMask = this.LANDLINE;
        }
        if (contato.celular) {
            this.cellphoneMask = this.CELLPHONE;
        }
        const banco = this.listaBancos.find((banco) => banco.id === contato.banco.id) || {}
        this.form.patchValue({ ...contato, banco: banco, idBanco: banco.id });
        this.form.controls.email.disable()
        this.form.get('index').setValue(index);
    }


    gravar(): void {
        this._spinner.show();
        this._checklistContatoBancoService
            .save(this.checklist.id, this.contatos)
            .subscribe(
                ({ data }) => {
                    this.contatos = (data as any).contatos;
                    this.gravando.emit(true);
                    this.sucess('Contatos salvos com sucesso!');
                    this.limparForm();
                },
                (error) => this.error(error));
    }

    validaContatos(): boolean {
        let isValid = false;

        this.contatos.forEach((contato) => {

        });
        return isValid;
    }

    onPhoneChanged() {
        this.phoneMask = this.LANDLINE;
        this.previusLength = this.phoneNumber ? this.phoneNumber.length : 0;
    }

    onCellPhonechanged() {
        this.cellphoneMask = this.CELLPHONE;
        this.previusLength = this.phoneNumber ? this.phoneNumber.length : 0;
    }

}
