import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GmudRecomendacoes } from '../../shared/interfaces/gmud-recomendacoes.interface';
import { Gmud } from '../../shared/interfaces/gmud.interface';
import { GmudRecomendacoesService } from '../../shared/services/gmud-recomendacoes.service';
import { FiltroGmudRecomendacoes } from '../../shared/interfaces/filtro-gmud-recomendacoes.interface';
import { GmudModalService } from '../../shared/services/gmud-modal.service';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';

@Component({
    selector: 'gmud-recomendacoes',
    templateUrl: './gmud-recomendacoes.component.html',
    styleUrls: ['./gmud-recomendacoes.component.scss'],
})
export class GmudRecomendacoesComponent
    extends GridBase<
        GmudRecomendacoes,
        FiltroGmudRecomendacoes,
        GmudRecomendacoesService
    >
    implements OnChanges, OnInit {
    recomendacoes: Array<GmudRecomendacoes> = [];

    private _gmud: Gmud;

    private gmudId: number;

    get gmud(): Gmud {
        return this._gmud;
    }

    @Input() set gmud(gmud: Gmud) {
        this._gmud = gmud;
        this.filtro.idGmud = gmud.gmud_id;
        this.initGrid(this.filtro || {});
    }

    @Input() visualizacao: boolean;
    // @Input() close: boolean;
    @Output() refetch = new EventEmitter<any>();

    constructor(
        protected _service: GmudRecomendacoesService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        protected _modalGmudRecomendacoesService: GmudModalService
    ) {
        super(_service, _injector, _changeDetectorRefs);

    }

    ngOnInit(): void {
        this._route.data.subscribe(data => {
            if (!data.gmud) return

            this.filtro.idGmud = data.gmud.gmud_id
            this.initGrid(this.filtro || {});
            this.recomendacoes = data.recomendacoes
        })

        this.refetch.subscribe(() => {
            this._service.find(this.filtro).subscribe(({ data }: any) => {
                this.initGrid(this.filtro || {});
                this.recomendacoes = data
            })
        })
    }

    ngOnChanges(changes: SimpleChanges): void { }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'usuario', label: 'Usuario' },
            { chave: 'created_at', label: 'Data de Criação' },
            { chave: 'gmr_descricao', label: 'Descrição' },
        ];
    }

    visualizarRecomendacao(gmudRecomendacao: GmudRecomendacoes) {
        this._modalGmudRecomendacoesService
            .recomendacaoDetalhe(gmudRecomendacao)
            .subscribe(() => {
            });
    }

    cadastrarRecomendacao() {
        this._modalGmudRecomendacoesService
            .recomendacaoCadastro({ id: this.filtro.idGmud, refetch: this.refetch })
            .subscribe(() => {
                // this.refetch
            });
    }

    cadastrarConsideracao(gmudRecomendacao: GmudRecomendacoes) {
        this._modalGmudRecomendacoesService
            .consideracaoCadastro(gmudRecomendacao)
            .subscribe(() => {
            });
    }

}
