import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TimeListaResolver } from '../time/shareds/resolvers/time-lista.resolver';
import { GmudGuard } from './gmud.guard';
import { GmudCadastroPageComponent } from './pages/gmud-cadastro-page/gmud-cadastro-page.component';
import { GmudConfiguracaoAprovacaoPageComponent } from './pages/gmud-configuracao-aprovacao-page/gmud-configuracao-aprovacao-page.component';
import { GmudGridPageComponent } from './pages/gmud-grid-page/gmud-grid-page.component';
import { GmudFluxoResolver } from './shared/resolvers/gmud-fluxo.resolver';
import { GmudDetalheResolver } from './shared/resolvers/gmud-detalhe.resolver';


import { GmudProdutoListaResolver } from './shared/resolvers/gmud-produto-lista.resolver';
import { GmudStatusListaResolver } from './shared/resolvers/gmud-status-lista.resolver';
import { GmudTipoMudancaListaResolver } from './shared/resolvers/gmud-tipo-mudanca-lista.resolver';
import { GmudSolucaoListaResolver } from './shared/resolvers/gmud-solucao-lista.resolver';


import { GmudHistoricoAprovacaoResolver } from './shared/resolvers/gmud-historico-aprovacao.resolver';
import { GmudTipoListaResolver } from './shared/resolvers/gmud-tipo-lista.resolver';
import { GmudJustificativaListaResolver } from './shared/resolvers/gmud-justificativa-lista.resolver';
import { GmudUrgenciaListaResolver } from './shared/resolvers/gmud-urgencia-lista.resolver';
import { GmudImpactoListaResolver } from './shared/resolvers/gmud-impacto-lista.resolver';
import { GmudExitoListaResolver } from './shared/resolvers/gmud-exito-lista.resolver';
import { GmudHistoricoResolver } from './shared/resolvers/gmud-historico.resolver';
// import { UsuarioListaResolver } from '../usuario/resolvers/usuario-lista.resolver';

const routes: Routes = [
    {
        path: '',
        canActivate: [GmudGuard],
        canLoad: [GmudGuard],
        resolve: {
            status: GmudStatusListaResolver,
            tipoMudanca: GmudTipoMudancaListaResolver,
            impacto: GmudImpactoListaResolver,
            produtos: GmudProdutoListaResolver,
            tipoSolucao: GmudSolucaoListaResolver,
            // usuarios: UsuarioListaResolver,
        },
        component: GmudGridPageComponent,
    },
    {
        path: 'cadastro',
        canActivate: [GmudGuard],
        canLoad: [GmudGuard],
        resolve: {
            tipoMudanca: GmudTipoMudancaListaResolver,
            impacto: GmudImpactoListaResolver,
            produtos: GmudProdutoListaResolver,
            tipoSolucao: GmudSolucaoListaResolver
        },
        component: GmudCadastroPageComponent,
    },
    {
        path: 'visualizacao/:id',
        canActivate: [GmudGuard],
        canLoad: [GmudGuard],
        resolve: {
            // gmudFluxo: GmudFluxoResolver,
            gmud: GmudDetalheResolver,
            // gmudHistoricoAprovacao: GmudHistoricoAprovacaoResolver,
            historico: GmudHistoricoResolver,
            // gmudStatus: GmudStatusListaResolver,
            // gmudTipos: GmudTipoListaResolver,
            // gmudJustificativas: GmudJustificativaListaResolver,
            // gmudUrgencias: GmudUrgenciaListaResolver,
            // gmudImpactos: GmudImpactoListaResolver,
            // gmudExitos: GmudExitoListaResolver,
            // times: TimeListaResolver,
            // gmudSolucoes: GmudSolucaoListaResolver,

            status: GmudStatusListaResolver,
            impacto: GmudImpactoListaResolver,
            produtos: GmudProdutoListaResolver,
            tipoSolucao: GmudSolucaoListaResolver,
            tipoMudanca: GmudTipoMudancaListaResolver

        },
        component: GmudCadastroPageComponent,
    },
    {
        path: 'edicao/:id',
        canActivate: [GmudGuard],
        canLoad: [GmudGuard],
        resolve: {
            // gmudFluxo: GmudFluxoResolver,
            gmud: GmudDetalheResolver,
            // gmudHistoricoAprovacao: GmudHistoricoAprovacaoResolver,
            historico: GmudHistoricoResolver,
            // gmudStatus: GmudStatusListaResolver,
            // gmudTipos: GmudTipoListaResolver,
            // gmudJustificativas: GmudJustificativaListaResolver,
            // gmudUrgencias: GmudUrgenciaListaResolver,
            // gmudImpactos: GmudImpactoListaResolver,
            // gmudExitos: GmudExitoListaResolver,
            // times: TimeListaResolver,
            // gmudSolucoes: GmudSolucaoListaResolver,

            status: GmudStatusListaResolver,
            impacto: GmudImpactoListaResolver,
            produtos: GmudProdutoListaResolver,
            tipoSolucao: GmudSolucaoListaResolver,
            tipoMudanca: GmudTipoMudancaListaResolver

        },
        component: GmudCadastroPageComponent,
    },

    // {
    //     path: 'configurar-aprovacao',
    //     canActivate: [GmudGuard],
    //     canLoad: [GmudGuard],
    //     resolve: {
    //         produtos: GmudProdutoListaResolver,
    //         // ambientes: GmudAmbienteListaResolver,
    //         times: TimeListaResolver,
    //     },
    //     component: GmudConfiguracaoAprovacaoPageComponent,
    // },
    { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class GmudRoutingModule { }
