import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Contrato } from '../../shared/interfaces/contrato.interface';
import { ContratoService } from '../../shared/services/contrato.service';
import { FormBase } from '@utils/base/form-base/form-base';
import { ContratoStatus } from '../../shared/interfaces/contrato-status.interface';
import { ContratoSituacao } from '../../shared/interfaces/contrato-situacao.interface';
import * as moment from 'moment';
import { ContratoModalService } from '../../shared/services/contrato-modal.service';

@Component({
    selector: 'contrato-detalhe',
    templateUrl: './contrato-detalhe.component.html',
    styleUrls: ['./contrato-detalhe.component.scss'],
})
export class ContratoDetalheComponent extends FormBase implements OnInit {
    isEdit: boolean = false;
    public form: FormGroup;
    public contrato: Contrato = {};

    listaStatus: Array<ContratoStatus> = []
    listaSituacao: Array<ContratoSituacao> = []

    @Output() atualizaGrid = new EventEmitter<boolean>();

    constructor(
        protected _injector: Injector,
        protected _service: ContratoService,
        protected _changeDetectorRefs: ChangeDetectorRef,
        protected _formBuilder: FormBuilder,
        private _modalContrato: ContratoModalService
    ) {
        super(_injector);
        this.form = _formBuilder.group(this.crateForm() || null);
    }

    ngOnInit(): void {
        this._route.data.subscribe((data) => {
            this.listaStatus = data.statuss
            this.listaSituacao = data.situacoes
            this.contrato = data.contrato
            this.setForm(this.contrato);
        });
    }

    public setForm(contrato: Contrato) {
        this.form.patchValue({
            situacao: contrato.situacao,
            status: contrato.status,
            vigenciaInicial: contrato.inicioVigencia ? new Date(contrato.inicioVigencia) : null,
            vigenciaFinal: contrato.finalVigencia ? new Date(contrato.finalVigencia) : null,
            vigenciaIndeterminado: contrato.finalVigenciaIndeterminado,
            nome: contrato.cotacao.nome,
            telefone: contrato.cotacao.telefone,
            email: contrato.cotacao.email,
            representanteLegal: contrato.representanteLegal,
            testemunha: contrato.testemunha,
            observacao: contrato.observacao
        })
    }

    protected crateForm(): { [key: string]: any } {
        return {
            situacao: ['', Validators.required],
            status: ['', Validators.required],
            nome: [],
            telefone: [],
            email: [],
            representanteLegal: ['', Validators.required],
            testemunha: ['', Validators.required],
            observacao: []
        };
    }

    editar() {
        this.isEdit = true;
    }

    salvar() {
        if (!this.isEdit) {
            return;
        }

        const situacao = this.form.get('situacao').value;
        const status = this.form.get('status').value;
        const representanteLegal = this.form.get('representanteLegal').value;
        const testemunha = this.form.get('testemunha').value;
        const observacao = this.form.get('observacao').value;


        const contrato: Contrato = {
            id: this.contrato.id,
            idSituacao: situacao && situacao.id ? situacao.id : null,
            idStatus: status && status.id ? status.id : null,
            representanteLegal: representanteLegal,
            testemunha: testemunha ? testemunha : "",
            observacao: observacao ? observacao : "",
        }

        this._spinner.show();
        this._service.update(contrato).subscribe(() => {
            this._spinner.hide();
            this._notification.sucess('Contrato atualizado com sucesso!')
        },
            error => {
                this._spinner.hide();

                if (error && error.error && error.error.errors) {
                    for (let es in error.error.errors) {
                        error.error.errors[es].forEach(e => {
                            this._notification.error(e)
                        });
                    }
                }

            })
    }

    limpaVigenciaFinal(indeterminado) {
        if (indeterminado.checked) {
            this.form.get('vigenciaFinal').setValue('');
        }
    }

    limpaVigenciaFinalIndeterminado(event) {
        const vigenciaFinal = this.form.get('vigenciaFinal').value
        if (vigenciaFinal) {
            this.form.get('vigenciaIndeterminado').setValue(false);
        }
    }

    atualizarVigencia() {
        this._modalContrato.atualizarVigencia(this.contrato.id).subscribe((result) => {
            if (result) {
                this.atualizaContrato();
                this.atualizaGrid.emit(true);
            }
        })
    }


    bloquear() {
        this.isEdit = false;
    }

    private atualizaContrato() {
        this._service.findById(this.contrato.id).subscribe(result => this.contrato = result.data);
    }

}
