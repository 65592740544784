import {
    ChangeDetectorRef,
    Component,
    Injector,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { environment } from 'environments/environment';
import { ChecklistGrid } from '../../shared/interfaces/checklist-grid.interface';
import { Checklist } from '../../shared/interfaces/checklist.interface';
import { FiltroChecklist } from '../../shared/interfaces/filtro-checklist.interface';
import { ChecklistModalService } from '../../shared/services/checklist-modal.service';
import { ChecklistService } from '../../shared/services/checklist.service';
import { mergeMap } from 'rxjs/operators';
import { ChecklistContatoEmpresaService } from '../../shared/services/checklist-contato-empresa.service';
import { element } from 'protractor';
import { AuthLocalStorageService } from '../../../../../@utils/services/auth-local-storage.service';
import { FuseConfigService } from '@fuse/services/config.service';

@Component({
    selector: 'checklist-grid',
    templateUrl: './checklist-grid.component.html',
    styleUrls: ['./checklist-grid.component.scss'],
})
export class ChecklistGridComponent extends GridBase<ChecklistGrid, FiltroChecklist, ChecklistService> implements OnChanges, OnInit {
    private usuarioLogado = null;
    private usuarioCliente = false;
    protected _fuseConfigService: FuseConfigService;
    constructor(
        protected _service: ChecklistService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        private _modalChecklist: ChecklistModalService,
        private _checklistContatoEmpresaService: ChecklistContatoEmpresaService,
        private _authLocalStorage: AuthLocalStorageService,

    ) {
        super(_service, _injector, _changeDetectorRefs, 'ChecklistGrid');
        this.usuarioLogado = _authLocalStorage.getUsuario();

        if (this.usuarioLogado.perfis[0].nome === 'Cliente') {
            this._fuseConfigService = this._injector.get(FuseConfigService);
            this._fuseConfigService.config = {
                layout: {
                    navbar: {
                        hidden: true,
                    },
                    footer: {
                        hidden: true,
                    },
                    sidepanel: {
                        hidden: true,
                    },
                },
            };
        }


    }

    ngOnInit(): void {
        this.initGrid(this.filtro || {});

        if (this.usuarioLogado.perfis[0].nome === 'Cliente') {
            this.usuarioCliente = true;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.atualizarGrid(this.filtro || {});
    }

    configDisplayedColumns(): Array<GridCollumn> {
        const dataBase = [
            { chave: 'seleciona', label: '' },
            { chave: 'status', label: 'Status' },
            { chave: 'nomeEmpresa', label: 'Empresa' },
            { chave: 'qtdeProjeto', label: 'Quantidade de Projetos' },
            { chave: 'dataCriacao', label: 'Data de criação' },
            { chave: 'dataPagamentoSetup', label: 'Data de pagamento setup' },
        ];
        if (!this.isClient()) {
            dataBase.splice(2, 0, { chave: 'cotacao', label: 'N. da cotação' });
        }
        return dataBase;
    }

    visualizar(checklist: Checklist): void {
        this._router.navigate(['checklist', checklist.id, 'visualizar', 'contato']);
    }

    editar(checklist: Checklist): void {
        this._router.navigate(['checklist', checklist.id, 'contato']);
    }

    historico(checklist: Checklist) {
        this._modalChecklist.historico(checklist);
    }

    isClient(): boolean {
        const user = this._authLocalStorage.getUsuario();
        return user.perfis[0].nome === 'Cliente';
    }

    sendEmailsChecklist(checklist: Checklist): void {
        const data = this._checklistContatoEmpresaService.findByChecklist(checklist.id);
        data.pipe(
            mergeMap(res => this._modalChecklist.confirmSendEmails(res.data.contatos, checklist.id))
        ).subscribe(result => {
            if (result) {
                const emails = result.data.emails.join(', ');
                this.sucess(`${result.message}: \n ${emails}`);
            }
        }, error => {
            console.error('Erro ao buscar dados da API:', error);
        }
        );
    }

    checklistEmAndamento(checklist: Checklist): boolean {
        return checklist.checklistStatus.id !== 4 && checklist.checklistStatus.id !== 5;
    }

    gerarRelatorio(): void {
        this._spinner.show();
        this._service.gerarRelatorio(this.filtro).subscribe(
            () => {
                this.sucess('Seu relatório está em processamento, acompanhe a tela de retirada de relatórios para download');
            },
            (error) => this.error(error)
        );
    }

    visualizarCotacao(idCotacao) {
        const link = `${environment.host}/cotacao/${idCotacao}`;
        this._router.navigate([]).then((result) => {
            window.open(link, '_blank');
        });
    }
}
