import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdquirenteRoutingModule } from './adquirente-routing.module';
import { AdquirenteGridPageComponent } from './page/adquirente-grid-page/adquirente-grid-page.component';
import { AdquirenteGridComponent } from './componentes/adquirente-grid/adquirente-grid.component';
import { AdquirenteGridFiltroComponent } from './componentes/adquirente-grid-filtro/adquirente-grid-filtro.component';
import { AdquirenteGuard } from './adquirente.guard';
import { AdquirenteService } from './shareds/services/adquirente.service';
import { UtilsModule } from '@utils/utils.module';
import { MatFormFieldModule, MatGridListModule, MatInputModule, MatListModule, MatPaginatorModule, MatSelectModule, MatSortModule, MatTableModule, MatTooltipModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AdquirenteListaResolver } from './shareds/resolvers/adquirente-lista.resolver';
import { AdquirenteCadastroPageComponent } from './page/adquirente-cadastro-page/adquirente-cadastro-page.component';
import { AdquirenteCadastroComponent } from './componentes/adquirente-cadastro/adquirente-cadastro.component';
import { AdquirenteDetalheResolver } from './shareds/resolvers/adquirente-detalhe.resolver';
import { AdquirenteListaChecklistResolver } from './shareds/resolvers/adquirente-lista-checklist.resolver';


@NgModule({
  declarations: [AdquirenteGridPageComponent, AdquirenteGridComponent, AdquirenteGridFiltroComponent, AdquirenteCadastroPageComponent, AdquirenteCadastroComponent],
  imports: [
    CommonModule,
    AdquirenteRoutingModule,
    UtilsModule,

    //TABLE
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatListModule,
    MatGridListModule,

    //FORMULARIO
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
  ],
  providers: [
    AdquirenteService,
    AdquirenteGuard,
    AdquirenteListaResolver,
    AdquirenteDetalheResolver,
    AdquirenteListaChecklistResolver,
  ]
})
export class AdquirenteModule { }
