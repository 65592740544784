import {
    Component, EventEmitter, Inject,
    Injector, OnInit,
    Output
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ChecklistContatoEmpresaService } from '../../shared/services/checklist-contato-empresa.service';
import { ComponentBase } from '../../../../../@utils/base/component-base/component-base';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OptionsConfig } from '@utils/base/form-base/options-config.interface';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import { ChecklistDadosEmpresaService } from '../../shared/services/checklist-dados-empresa.service';
import { ChecklistDadosEmpresa } from '../../shared/interfaces/checklist-dados-empresa.interface';


@Component({
    selector: 'modal-adicionar-empresa',
    templateUrl: './modal-adicionar-empresa.component.html',
    styleUrls: ['./modal-adicionar-empresa.component.scss'],
})
export class ModalAdicionarEmpresaComponent extends ComponentBase implements OnInit {
    tituloModal = 'Adicionar Empresa';
    listaBancos = [];
    listaEmpresas = [];
    checklist: any;
    relacionamentos = [];
    isEdit = false;
    form: FormGroup;
    @Output() gravando = new EventEmitter();
    public optionList: any = {};
    private optionsConfig: Array<OptionsConfig> = [];
    protected _onDestroy = new Subject<void>();
    public filteredList: Array<ReplaySubject<Array<Object>>> = [];
    lists: {
        cargos: any[]
    } = {
            cargos: []
        };

    constructor(
        private _injector: Injector,
        public dialogRef: MatDialogRef<ModalAdicionarEmpresaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _checklistContatoEmpresaService: ChecklistContatoEmpresaService,
        protected _formBuilder: FormBuilder,
        private _checklistDadosEmpresaService: ChecklistDadosEmpresaService,
    ) {
        super(_injector);
    }
    ngOnInit(): void {
        this.listaBancos = this.data.listaBancos;
        this.listaEmpresas = this.data.listaEmpresas;
        this.checklist = this.data.checklist;
        this.relacionamentos = this.data.relacionamentos;
        this.filteredList['listaBancos'] = this.listaBancos
        // this.filteredList['listaEmpresas'] = this.listaEmpresas
        this.form = this._formBuilder.group(
            this.createForm() || null
        );

        const filter = this.form.get('filterBancos');
        filter.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                const { value } = filter;
                if (!value) {
                    this.filteredList['listaBancos'] = [...this.listaBancos];
                    return;
                }
                const search = value.toLowerCase();
                console.log(search)
                this.filteredList['listaBancos'] =
                    this.listaBancos.filter(result => result.descricaoCompleta.toLowerCase().indexOf(search) > -1)
            });

    };


    limparForm(): void {
        this.isEdit = false;
        this.form.reset();
    }


    protected createForm(): { [key: string]: any } {
        return {
            banco: ['', Validators.required],
            empresa: ['', Validators.required],
            convenio: [],
            agencia: ['', Validators.required],
            agenciaDv: [],
            conta: ['', Validators.required],
            contaDv: ['', Validators.required],
            index: [],
            filterBancos: []

        };
    }



    selectedOption(o1: any, o2: any) {
        return o1.id == o2.id;
    }


    protected refreshOptionsConfig() {
        this.optionsConfig.forEach((options: OptionsConfig) => {
            if (this.optionList[options.listName]) {
                this.filteredList[options.listName].next(this.optionList[options.listName].slice());
            }
            if (options.filterName) {
                this.form.get(options.filterName).valueChanges
                    .pipe(takeUntil(this._onDestroy))
                    .subscribe(() => {
                        this.filterOptions(options);
                    });
            }
        });
    }


    protected filterOptions(options: OptionsConfig) {
        if (!this.optionList[options.listName]) {
            return;
        }
        // get the search keyword
        let search = this.form.get(options.filterName).value;
        if (!search) {
            this.filteredList[options.listName].next(this.optionList[options.listName].slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the options
        this.filteredList[options.listName].next(
            this.optionList[options.listName].filter(result => result[options.fieldValue].toLowerCase().indexOf(search) > -1)
        );
    }

    gravar() {
        const relacionamento: ChecklistDadosEmpresa = this.form.value;
        relacionamento.idBanco = relacionamento.banco.id;
        relacionamento.idEmpresa = relacionamento.empresa.id;

        this.relacionamentos.push(relacionamento);
        this.limparForm();

        this._spinner.show();
        this._checklistDadosEmpresaService
            .save(this.checklist.id, this.relacionamentos)
            .subscribe(
                () => {
                    this._spinner.hide();
                    this.gravando.emit(true);
                    this._notification.sucess(
                        'Relacionamentos salvos com sucesso!'
                    );
                },
                (error) => this.error(error)
            );
        this.dialogRef.close();
    }

}
export class ChecklistDadosEmpresaCombo {
    static combos() {
        return [
            {
                listName: 'listaBancos',
                filterName: 'filterBancos',
                fieldValue: 'descricaoCompleta',
            },
        ];
    }
}
