import { Component, ViewEncapsulation, Injector, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { AuthBaseController } from '../../shared/auth-base.controller';

@Component({
    selector: 'primeiro-acesso-cliente',
    templateUrl: './primeiro-acesso-cliente.component.html',
    styleUrls: ['./primeiro-acesso-cliente.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class PrimeiroAcessoClienteComponent extends AuthBaseController {
    constructor(protected _injector: Injector) {
        super(_injector);
    }
}
