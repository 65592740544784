import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { GmudHistorico } from '../interfaces/gmud-historico.interface';
import { GmudHistoricoService } from '../services/gmud-historico.service';

@Injectable()
export class GmudHistoricoResolver implements Resolve<GmudHistorico> {
    constructor(private _service: GmudHistoricoService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        let idGmud = route.params['id'];
        return this._service.find({ idGmud });
    }
}
