import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-bi-kpi-onboarding',
    templateUrl: './bi-kpi-onboarding.component.html',
    styleUrls: ['./bi-kpi-onboarding.component.scss'],
})
export class BiKpiOnboardingComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
