import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Cotacao } from '../../shared/interfaces/cotacao.interface';
import { CotacaoStatusEnum } from '../../shared/enums/cotacao-status.enum';

@Component({
    selector: 'cotacao-detalhe-page',
    templateUrl: './cotacao-detalhe-page.component.html',
    styleUrls: ['./cotacao-detalhe-page.component.scss'],
})
export class CotacaoDetalhePageComponent implements OnInit {
    abaAtiva: string = 'COTACAO';

    cotacao: Cotacao = {};

    constructor(private _route: ActivatedRoute, protected _router: Router) {
        this._route.data.subscribe((data) => {
            this.cotacao = data.cotacao || {};
        });

        // this._route.queryParams.subscribe(
        //     (params) => (this.abaAtiva = params.aba || 'COTACAO')
        // );
    }

    ngOnInit(): void {}

    public setAba(aba: string) {
        this.abaAtiva = aba;
        // this._router.navigate([], {
        //     queryParams: {
        //         aba,
        //     },
        // });
    }

    exibeAbaProjetos(cotacao: Cotacao): boolean {
        const status = cotacao.cotacaoStatus.id;
        const statusBloqueio = [CotacaoStatusEnum.CANCELADO];
        return !statusBloqueio.includes(status);
    }
}
