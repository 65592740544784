import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChecklistContatoBanco } from '../interfaces/checklist-contato-banco.interface';
import { ChecklistContatoBancoService } from '../services/checklist-contato-banco.service';

@Injectable()
export class ChecklistContatoBancoListaResolver
    implements Resolve<ChecklistContatoBanco> {
    constructor(
        private _checklistContatoBancoService: ChecklistContatoBancoService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const id = route.params['id'];
        return this._checklistContatoBancoService
            .findByChecklist(id)
            .pipe(map((result) => result.data));
    }
}
