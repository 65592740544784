import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { FiltroGmudRecomendacoes } from '../interfaces/filtro-gmud-recomendacoes.interface';
import { GmudRecomendacoes } from '../interfaces/gmud-recomendacoes.interface';
import { GmudRecomendacaoCadastro } from '../interfaces/gmud-recomendacao-cadastro.interface';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class GmudRecomendacoesService extends ServiceGridBase<
    GmudRecomendacoes,
    FiltroGmudRecomendacoes
> {
    private path: string = `${environment.server}/gmud-recomendacao`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(
        filtro?: FiltroGmudRecomendacoes
    ): Observable<Response<Array<GmudRecomendacoes>>> {
        const url = `${this.path}?gmud_id=${filtro.idGmud}`;
        return this._http.get<Response<Array<GmudRecomendacoes>>>(url);
    }

    findRecomendacao(gmr_id: number): Observable<Response<any>> {
        const url = `${this.path}/${gmr_id}`;
        return this._http.get<Response<any>>(url);
    }

    findConsideracao(gmr_id: number): Observable<Response<Array<any>>> {
        const url = `${this.path}?gmr_id=${gmr_id}`;
        return this._http.get<Response<Array<any>>>(url);
    }

    gravar(payload: { usu_id: number, gmud_id: number, gmr_descricao: string }): Observable<GmudRecomendacaoCadastro> {
        return this._http.post<Response<GmudRecomendacaoCadastro>>(this.path, payload).pipe(map(result => result.data));
    }
}
