import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProdutoListaResolver } from '../produto/shareds/resolvers/produto-lista.resolver';
import { ContratoGuard } from './contrato.guard';
import { ContratoDetalhePageComponent } from './pages/contrato-detalhe-page/contrato-detalhe-page.component';
import { ContratoGridPageComponent } from './pages/contrato-grid-page/contrato-grid-page.component';
import { PreContratoDetalhePageComponent } from './pages/pre-contrato-detalhe-page/pre-contrato-detalhe-page.component';
import { ContratoDetalheResolver } from './shared/resolvers/contrato-detalhe.resolver';
import { ContratoEstadoResolver } from './shared/resolvers/contrato-estado.resolver';
import { ContratoPrioridadeListaResolver } from './shared/resolvers/contrato-prioridade-lista.resolver';
import { ContratoSegmentoListaResolver } from './shared/resolvers/contrato-segmento-lista.resolver';
import { ContratoSituacaoListaResolver } from './shared/resolvers/contrato-situacao-lista.resolver';
import { ContratoStatusListaResolver } from './shared/resolvers/contrato-status-lista.resolver';
import { PreContratoDetalheResolver } from './shared/resolvers/pre-contrato-detalhe.resolver';
const routes: Routes = [
    {
        path: '',
        canActivate: [ContratoGuard],
        canLoad: [ContratoGuard],
        resolve: {
            statuss: ContratoStatusListaResolver,
            situacoes: ContratoSituacaoListaResolver,
            prioridade: ContratoPrioridadeListaResolver,
            segmentos: ContratoSegmentoListaResolver,
            produtos: ProdutoListaResolver,
        },
        component: ContratoGridPageComponent,
    },
    {
        path: ':id',
        canActivate: [ContratoGuard],
        canLoad: [ContratoGuard],
        resolve: {
            contrato: ContratoDetalheResolver,
            contratoProdutosLista: ProdutoListaResolver,
            situacoes: ContratoSituacaoListaResolver,
            statuss: ContratoStatusListaResolver
        },
        component: ContratoDetalhePageComponent,
    },
    {
        path: 'contratosituacao',
        canActivate: [ContratoGuard],
        canLoad: [ContratoGuard],
        resolve: {
        },
        component: ContratoGridPageComponent,
    },
    {
        path: 'pre-contrato/:id',
        canActivate: [ContratoGuard],
        canLoad: [ContratoGuard],
        resolve: {
            contrato: PreContratoDetalheResolver,
            contratoProdutosLista: ProdutoListaResolver,
            segmentos: ContratoSegmentoListaResolver,
            prioridades: ContratoPrioridadeListaResolver,
            produtos: ProdutoListaResolver,
            estados: ContratoEstadoResolver,
        },
        component: PreContratoDetalhePageComponent,
    },
    { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ContratoRoutingModule { }
