import { ChangeDetectorRef, Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import { FormBase } from '@utils/base/form-base/form-base';
import { DataUtil } from '@utils/utils/class/data.util';
import { GmudTipoSolucao } from '../../shared/interfaces/gmud-tipo-solucao.interface';
import { GmudDocumentoDownload } from '../../shared/interfaces/gmud-documento-download.interface';
import { GmudDocumentoUpload } from '../../shared/interfaces/gmud-documento-upload.interface';
import { GmudDocumentoUploadService } from '../../shared/services/gmud-documento-upload.service';
import { GmudDocumentoDownloadService } from '../../shared/services/gmud-documento-download.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { DownloadUtil } from '@utils/utils/class/download.util';

import { isFutureDate } from 'app/main/profile/shared/validators/is-future-date.validator';
import { isFutureHour } from 'app/main/profile/shared/validators/is-future-hour.validator';
import { Gmud, GmudRequest } from '../../shared/interfaces/gmud.interface';
import { GmudProduto } from '../../shared/interfaces/gmud-produto.interface';
import { GmudTipo } from '../../shared/interfaces/gmud-tipo.interface';
import { GmudImpacto } from '../../shared/interfaces/gmud-impacto.interface';
import { GmudService } from '../../shared/services/gmud.service';

@Component({
    selector: 'gmud-aba-gmud',
    templateUrl: './gmud-aba-gmud.component.html',
    styleUrls: ['./gmud-aba-gmud.component.scss']
})
export class GmudAbaGmudComponent extends FormBase implements OnInit {
    @Input() visualizacao: boolean;

    gmud: Gmud = {};
    isEdit: boolean = false;

    listaProdutos: Array<GmudProduto> = [];
    listaTipoMudanca: Array<GmudTipo> = [];
    listaImpacto: Array<GmudImpacto> = [];
    listaTipoSolucao: Array<GmudTipoSolucao> = [];


    //
    arquivosDownloads: Array<GmudDocumentoDownload> = [];

    arquivo: GmudDocumentoUpload;
    @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
    files: Array<any> = [];
    filesFormData: any;
    uploaded: Boolean;
    //


    constructor(
        protected _gmudService: GmudService,
        protected _injector: Injector,
        protected _changeDetectorRef: ChangeDetectorRef,
        private _gmudDocumentoUploadService: GmudDocumentoUploadService,
        private _gmudDocumentoDownloadService: GmudDocumentoDownloadService,

    ) {
        super(_injector);
    }

    ngOnInit() {
        this._route.data.subscribe(data => {
            this.listaProdutos = data.produtos.map(produto => ({ id: produto.gmp_id, descricao: produto.gmp_descricao }))
            this.listaImpacto = data.impacto.map(impacto => ({ id: impacto.gmi_id, descricao: impacto.gmi_descricao }));
            this.listaTipoMudanca = data.tipoMudanca.map(tipoMudanca => ({ id: tipoMudanca.gmt_id, descricao: tipoMudanca.gmt_descricao }));
            this.listaTipoSolucao = data.tipoSolucao.map(tipoSolucao => ({ id: tipoSolucao.gmts_id, descricao: tipoSolucao.gmts_descricao }));

            this.isEdit = data.gmud

            if (this.isEdit) {
                this.gmud = data.gmud;
                this.setForm(data.gmud);
                this.atualizaListaDownloads();
            }

            if (this.visualizacao) {
                this.form.controls.produto.disable()
                this.form.controls.motivoMudanca.disable()
                this.form.controls.clientesImpactados.disable()
                this.form.controls.tipoMudanca.disable()
                this.form.controls.dataMudanca.disable()
                this.form.controls.dataTerminoMudanca.disable()
                this.form.controls.horaMudanca.disable()
                this.form.controls.horaTermino.disable()
                this.form.controls.releaseNote.disable()
                this.form.controls.impacto.disable()
                this.form.controls.justificativaImpacto.disable()
                this.form.controls.tipoSolucao.disable()
                this.form.controls.detalhesTecnicosMudanca.disable()
                this.form.controls.planoRollback.disable()
            }
        })
    }

    protected crateForm(): { [key: string]: any } {
        return {
            produto: ['', Validators.required],
            // dataMudanca: ['', Validators.compose([
            //     Validators.required,
            //     isFutureDate,
            // ])],
            dataMudanca: ['', [Validators.required]],
            dataTerminoMudanca: ['', [Validators.required, isFutureDate]],
            horaMudanca: ['', [Validators.required, Validators.pattern(/([0-1][0-9]|2[0-3])(00|15|30|45)/)]],
            horaTermino: ['', [Validators.required, isFutureHour]],
            tipoMudanca: ['', Validators.required],
            motivoMudanca: ['', Validators.required],
            releaseNote: ['', Validators.required],
            impacto: ['', Validators.required],
            justificativaImpacto: ['', Validators.required],
            clientesImpactados: ['', Validators.required],
            tipoSolucao: ['', Validators.required],
            detalhesTecnicosMudanca: ['', Validators.required],
            planoRollback: ['', Validators.required],
        };
    }

    private setForm(gmud: Gmud) {
        this.form.patchValue({
            id: gmud.gmud_id,

            produto: gmud.produto,
            motivoMudanca: gmud.gmud_motivo,
            clientesImpactados: gmud.gmud_cli_impacto,
            status: gmud.status,
            tipoMudanca: gmud.tipo,
            dataMudanca: gmud.gmud_data,
            dataTerminoMudanca: gmud.gmud_data_termino,
            horaMudanca: gmud.gmud_hora,
            horaTermino: gmud.gmud_hora_termino,
            releaseNote: gmud.gmud_release_note,
            impacto: gmud.impacto,
            justificativaImpacto: gmud.gmud_just_impacto,
            tipoSolucao: gmud.tipo_solucao,
            detalhesTecnicosMudanca: gmud.gmud_det_tecnico,
            planoRollback: gmud.gmud_rollback
        })

        // if (gmud.gmudTipo && gmud.gmudTipo.value === "outros") {
        //     this.isOutros = true
        //     this.form.get('gmudTipoOutros').setValidators([Validators.required])
        //     this.form.get('gmudTipoOutros').updateValueAndValidity()
        // }
    }

    // isValidHour() {
    //     const horaMudanca = this.form.get('horaMudanca').value;
    //     if (!horaMudanca.length || horaMudanca.length < 4) return true

    //     const [h1, h2, m1, m2] = horaMudanca.split('')
    //     const hour = `${h1}${h2}:${m1}${m2}`

    //     return moment(hour, 'HH:mm', true).isValid()
    // }

    limparForm() {
        this.form.reset();
    }

    gravar() {
        const produto = this.form.get('produto').value;
        const dataMudanca = this.form.get('dataMudanca').value;
        const dataTerminoMudanca = this.form.get('dataTerminoMudanca').value;
        const horaMudanca = this.form.get('horaMudanca').value;
        const horaTermino = this.form.get('horaTermino').value;
        const tipoMudanca = this.form.get('tipoMudanca').value;
        const motivoMudanca = this.form.get('motivoMudanca').value;
        const releaseNote = this.form.get('releaseNote').value;
        const impacto = this.form.get('impacto').value;
        const justificativaImpacto = this.form.get('justificativaImpacto').value;
        const clientesImpactados = this.form.get('clientesImpactados').value;
        const tipoSolucao = this.form.get('tipoSolucao').value;
        const detalhesTecnicosMudanca = this.form.get('detalhesTecnicosMudanca').value;
        const planoRollback = this.form.get('planoRollback').value;

        this.form.markAllAsTouched()


        // const obj = {
        //     hello: "world"
        //   };
        //   const json = JSON.stringify(obj);
        //   const blob = new Blob([json], {
        //     type: 'application/json'
        //   });
        //   const data = new FormData();
        //   data.append("document", blob);

        const requestBody: any = {
            gmud_id: this.gmud.gmud_id,
            gmp_id: produto.id,
            gms_id: (this.gmud.status && this.gmud.status.id) || 1,
            gmt_id: tipoMudanca.id,
            gmi_id: impacto.id,
            gmts_id: tipoSolucao.id,
            gmud_data: DataUtil.toDateBackend(dataMudanca),
            gmud_data_termino: DataUtil.toDateBackend(dataTerminoMudanca),
            gmud_hora: horaMudanca.replace(/([0-9]{2})([0-9]{2})/gi, '$1:$2'),
            gmud_hora_termino: horaTermino.replace(/([0-9]{2})([0-9]{2})/gi, '$1:$2'),
            gmud_motivo: motivoMudanca,
            gmud_release_note: releaseNote,
            gmud_just_impacto: justificativaImpacto,
            gmud_cli_impacto: clientesImpactados,
            gmud_det_tecnico: detalhesTecnicosMudanca,
            gmud_rollback: planoRollback,
            // 'documento[]': this.filesFormData
        }

        // const json = JSON.stringify(requestBody)
        // const blob = new Blob([json], {
        //     type: 'application/json'
        // });

        // const data = new FormData();
        // data.append("data", blob);

        this._spinner.show();

        const method = this.isEdit ? 'alterar' : 'gravar'

        // @ts-ignore
        this._gmudService[method](requestBody).subscribe((response) => {
            this._spinner.hide();
            this._notification.sucess('GMUD gravado com sucesso!')

            this.gmud.gmud_id = response.gmud_id
            this.upload()
            this.redirectTo(`/gmud`)
        },
            error => this.error(error))
    }

    // pesquisaUsuarioProducao() {
    //     this._modalUsuario.usuarioPesquisa({}).subscribe((usuario) => {
    //         if (usuario) {
    //             this.responsavelProducao = usuario;
    //         }
    //     });
    // }

    // pesquisaUsuarioDesenvolvimento() {
    //     this._modalUsuario.usuarioPesquisa({}).subscribe((usuario) => {
    //         if (usuario) {
    //             this.responsavelDesenvolvimento = usuario;
    //         }
    //     });
    // }

    private redirectTo(uri: string) {
        this._router.navigateByUrl('/gmud', { skipLocationChange: true }).then(() =>
            this._router.navigate([uri]));
    }











    // ==========================================
    //                  UPLOAD
    // ==========================================
    onFileDropped($event): void {
        this.prepareFile($event);
    }

    fileBrowseHandler(files): void {
        this.prepareFile(files);
    }

    deleteFile(file, i): void {
        if (file.progress > 0 && file.progress < 100) {
            return;
        }
        this.files.splice(i, 1);
    }

    deleteFiles(): void {
        this.files = null
    }

    upload(): void {
        this.uploaded = true
        if (this.gmud.gmud_id && this.files && this.files.length) {
            this.files.forEach((file, i) => {
                this._gmudDocumentoUploadService
                    .upload(this.gmud.gmud_id, file)
                    .subscribe(
                        (event: HttpEvent<any>) => {
                            if (event.type === HttpEventType.Response) {
                                this.sucess('Upload realizado com sucesso!');

                                if (this.files) {
                                    if (this.files.length >= i + 1) {
                                        this.uploaded = false
                                        this.deleteFiles();
                                        this.atualizaListaDownloads();
                                    }
                                }
                            } else if (
                                event.type === HttpEventType.UploadProgress
                            ) {
                                file.progress = Math.round(
                                    (event.loaded * 100) / event.total
                                );
                            }
                        },
                        (error) => {
                            this._notification.error('Erro no upload do arquivo!');
                        }
                    );
            });
        } else {

        }
    }

    prepareFile(files: FileList): void {
        this.uploaded = false
        const arrayFiles = Array.from(files);
        this.files = [...[...this.files || []], ...arrayFiles]
        this.files.forEach(file => {
            file.progress = 0;
        })

        this.fileDropEl.nativeElement.value = '';
    }

    formatBytes(bytes, decimals = 2): string {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        );
    }
    // ==========================================
    //                  DOWNLOAD
    // ==========================================
    download(arquivoDownload: GmudDocumentoDownload): void {
        this._spinner.show();
        this._gmudDocumentoDownloadService.download(arquivoDownload.id).subscribe(
            (result) => {
                DownloadUtil.base64(
                    result.data.arquivo,
                    result.data.name,
                    result.data.type
                );
                this._spinner.hide();
            },
            (error) => this.error(error)
        );
    }

    remover(arquivoDownload: GmudDocumentoDownload): void {
        this._confirmation
            .confirm({ titulo: 'Deseja excluir esse arquivo?' })
            .subscribe((result) => {
                if (result) {
                    this._spinner.show();
                    this._gmudDocumentoDownloadService
                        .delete(arquivoDownload.id)
                        .subscribe(
                            () => {
                                this._spinner.hide();
                                this.sucess('Arquivo removido com sucesso!');
                                this.atualizaListaDownloads();
                            },
                            (error) => this.error(error)
                        );
                }
            });
    }

    private atualizaListaDownloads() {
        this._spinner.show();
        this._gmudDocumentoDownloadService.find(this.gmud.gmud_id).subscribe(
            (arquivos) => {
                this.arquivosDownloads = arquivos.data;
                this._spinner.hide();
            },
            (error) => this.error(error)
        );
    }

}
