import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Response } from '@utils/interfaces/http/response.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { OsEditar } from '../interfaces/os-editar.interface';

@Injectable({
    providedIn: 'root',
})
export class OsEdicaoService extends ServiceBase<OsEditar> {
    private path = `${environment.server}/os`;

    constructor(private _http: HttpClient) {
        super();
    }

    update(osEdicao: OsEditar): Observable<Response<OsEditar>> {
        osEdicao = Object.assign({}, osEdicao, {
            idOs: osEdicao.idsOs,
            idOsCategoria: osEdicao.idCategoria,
        });
        const url = `${this.path}`;
        return this._http.put<Response<OsEditar>>(url, osEdicao);
    }

    validaOsAbertura(osEdicao: OsEditar): Observable<Response<Array<any>>> {
        const url = `${environment.server}/os/osabertura/contar`;
        return this._http.post<Response<Array<any>>>(url, {
            idOs: osEdicao.idsOs,
        });
    }
}
