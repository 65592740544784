import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { Gmud, GmudRequest } from '../interfaces/gmud.interface';
import { FiltroGmud } from '../interfaces/filtro-gmud.interface';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class GmudService extends ServiceGridBase<Gmud, FiltroGmud> {
    private path = `${environment.server}/gmud`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro: FiltroBase): Observable<Response<Array<Gmud>>> {
        const params: string = this.toGetParams(filtro);
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<Gmud>>>(url);
    }

    findById(id: number | string): Observable<Gmud> {
        const url = `${environment.server}/gmud/${id}`;
        return this._http.get<Response<Gmud>>(url).pipe(map(result => result.data));
    }

    alterar(gmud: GmudRequest): Observable<GmudRequest> {
        const url = `${this.path}/${gmud.gmud_id}`;
        return this._http.put<Response<GmudRequest>>(url, gmud).pipe(map(result => result.data));
    }

    // aprovacao(aprovado: boolean, idGmud: number): Observable<void> {
    //     const url = `${this.path}/${idGmud}/aprovar`;
    //     return this._http.post<Response<void>>(url, { aprovado }).pipe(map(result => result.data));
    // }

    gerarRelatorio(relatorio: any, filtro: any) {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${environment.server}/relatorio/${relatorio}${params}`;
        return this._http.post<Response<any>>(url, {});
    }

    gravar(gmud: GmudRequest): Observable<GmudRequest> {
        return this._http.post<Response<GmudRequest>>(this.path, gmud).pipe(map(result => result.data));
    }

    finalizar(id: number | string): Observable<any> {
        const url = `${this.path}-finalizar/${id}`;

        return this._http.post<Response<any>>(url, {}).pipe(map(result => result.data));
    }

    rollback(id: number | string): Observable<any> {
        const url = `${this.path}-rollback/${id}`;

        return this._http.post<Response<any>>(url, {}).pipe(map(result => result.data));
    }

    cancelar(id: number | string): Observable<any> {
        const url = `${this.path}-cancelar/${id}`;

        return this._http.post<Response<any>>(url, {}).pipe(map(result => result.data));
    }
}
