import { Component, Injector, Input, OnInit, Output } from '@angular/core';
import { ComponentBase } from '@utils/base/component-base/component-base';
import { Checklist } from 'app/main/checklist/shared/interfaces/checklist.interface';
import { ChecklistNavegationStep } from '../../shared/interfaces/checklist-navegation-step.interface';
import { ChecklistProdutoPortal } from '../../shared/interfaces/checklist-produto-portal.interface';
import { ChecklistNavegacaoService } from '../../shared/services/checklist-navegacao.service';
import { ChecklistService } from '../../shared/services/checklist.service';
import { AuthLocalStorageService } from '@utils/services/auth-local-storage.service';
import { FuseConfigService } from '@fuse/services/config.service';

@Component({
    selector: 'checklist-navegation',
    templateUrl: './checklist-navegation.component.html',
    styleUrls: ['./checklist-navegation.component.scss'],
})
export class ChecklistNavegationComponent extends ComponentBase implements OnInit {
    private _recarregar: boolean;
    private _isVisualizacao: boolean = false;

    isVisualizaProduto: boolean = false;
    hasProdutoBu: boolean = false;
    isFinalizacaoStep: boolean = false;
    checklist: Checklist = {};
    listSteps: Array<ChecklistNavegationStep> = [];
    produtosBUs: Array<ChecklistProdutoPortal> = [];
    isBuStep: boolean = false;
    usuarioLogado = null;
    protected _fuseConfigService: FuseConfigService;

    private stepsConfig = {
        contato: 'stepContato',
        'contato-empresa': 'stepContato',
        conectividade: 'stepConectividade',
        'dados-empresa': 'stepEmpresa',
        // adquirente: 'stepAdquirente',
        relacionamento: 'stepRelacionamento',
        'contato-banco': 'stepContatoBanco',
        // 'produtoFinanceiro': 'stepProdutoFinanceiro',
        // 'produto-financeiro': 'stepProdutoFinanceiro',
        configuracao: 'stepConfiguracao',
        finalizacao: 'finalizadoSteps',
        finalizacaoStep: 'finalizadoSteps',
    };

    get recarregar(): any {
        return this._recarregar;
    }
    @Input() set recarregar(value: any) {
        this._recarregar = value;
        this.recarregarMenu();
    }

    constructor(
        private _checklistService: ChecklistService,
        private _checklistNavegacao: ChecklistNavegacaoService,
        private _injector: Injector,
        private _authLocalStorage: AuthLocalStorageService,

    ) {
        super(_injector);
        this.usuarioLogado = _authLocalStorage.getUsuario();
        if (this.usuarioLogado.perfis[0].nome === 'Cliente') {
            this._fuseConfigService = this._injector.get(FuseConfigService);
            this._fuseConfigService.config = {
                layout: {
                    navbar: {
                        hidden: true,
                    },
                    footer: {
                        hidden: true,
                    },
                    sidepanel: {
                        hidden: true,
                    },
                },
            };
        }
    }

    ngOnInit(): void {
        this._route.data.subscribe((data) => {
            this.checklist = data.checklist;
            this.produtosBUs = data.produtoPortal;
            this._isVisualizacao = data.isVisualizacao;
            this.render();
        });
    }

    selecionaProduto(
        produtoBu: ChecklistProdutoPortal,
        isFinalizadoStep: boolean
    ) {
        window.open(produtoBu.urlChecklistPortalComToken, '_blank');
    }

    private recarregarMenu() {
        this._spinner.show();
        const idChecklist = this._route.snapshot.params.id;
        this._checklistService.findById(idChecklist).subscribe(
            (result) => {
                this._spinner.hide();
                this.checklist = result.data;
                this.render();
            },
            (error) => this.error(error)
        );
    }

    private render() {
        this.isFinalizacaoStep = /\/checklist\/[0-9]+\/finalizacao-step/.test(
            this._route['_routerState'].snapshot.url);
        // this.isBuStep = /\/checklist\/[0-9]+\/bu/.test(
        //     this._route['_routerState'].snapshot.url);
        this.hasProdutoBu = this.produtosBUs.length > 0 && this.isFinalizacaoStep;
        this.isVisualizaProduto = false;
        const listSteps = [];
        const stepsClass = {
            'contato-empresa': 'contato-empresa-disabled',
            conectividade: 'conectividade-disabled',
            'dados-empresa': 'dados-empresa-disabled',
            adquirente: 'adquirente-disabled',
            relacionamento: 'relacionamento-disabled',
            // produtoFinanceiro: 'produtoFinanceiro-disabled',
            'contato-banco': 'contato-banco-disabled',
            configuracao: 'configuracao-disabled',
            finalizacao: 'finalizacao-disabled',
            'finalizacao-step': 'finalizacao-step-disabled',
        };



        if (this.checklist.finalizadoSteps && this.produtosBUs) {
            this.isVisualizaProduto = true;
        }

        if (this._isVisualizacao) {
            this._checklistNavegacao
                .getListaStepsVisualizacao(this.checklist.id, this.checklist.criarConfiguracao)
                .forEach((step) => {
                    step.id = `${step.chave}`;
                    step.isUrlEnable = true;
                    if (
                        !['finalizacao', 'finalizacao-step'].includes(step.chave)
                        // && !(step.chave === 'produtoFinanceiro' && !this.checklist.stepProdutoFinanceiro)
                    ) {
                        listSteps.push(step);
                    }
                })
            this.listSteps = listSteps;

        } else {
            let isPrimeiro = true;
            this._checklistNavegacao
                .getListaSteps(this.checklist.id, this.checklist.criarConfiguracao, this.checklist)
                .forEach((step) => {
                    const checked = this.checklist[this.stepsConfig[step.chave]];
                    let isAddStep = this.isStepAtivo(step.chave);
                    step.proximoStep = this.getProximoStep(step);
                    if (
                        step.chave === 'finalizacao-step' &&
                        this.produtosBUs &&
                        this.produtosBUs.length === 0
                    ) {
                        isAddStep = false;
                    }

                    if (isAddStep) {
                        if (
                            step.chave === 'finalizacao' &&
                            this.produtosBUs &&
                            this.produtosBUs.length === 0
                            && ((this.checklist.stepConfiguracao && this.checklist.criarConfiguracao)
                                ||
                                (this.checklist.stepRelacionamento && !this.checklist.criarConfiguracao))
                        ) {
                            step.id = 'finalizacao';
                            step.isUrlEnable = true;
                        }
                        else if (
                            this.checklist.finalizadoSteps &&
                            step.chave !== 'finalizacao'
                        ) {
                            //step.id = `${step.chave}-disabled-checked`;
                            step.id = `${step.chave}-checked`;
                            step.isUrlEnable = true;

                        }
                        else if (
                            this.checklist.finalizadoSteps &&
                            this.checklist.finalizadoProdutos
                        ) {
                            step.id = 'finalizacao';
                            step.isUrlEnable = true;
                        } else if (checked) {
                            const prossimoStep = this.getProximoStep(step);
                            stepsClass[step.chave] = `${step.chave}-checked`;
                            step.id = stepsClass[step.chave];
                            step.isUrlEnable = true;

                            if (prossimoStep) {
                                stepsClass[prossimoStep] = `${prossimoStep}`;
                            }
                        } else {
                            if (/disabled/.test(stepsClass[step.chave])) {
                                step.id = stepsClass[step.chave];
                                step.isUrlEnable = false;
                            } else {
                                step.id = stepsClass[step.chave];
                                step.isUrlEnable = true;
                            }
                        }

                        if (isPrimeiro) {
                            // if (step.id != `${step.chave}-checked`) {
                            //     step.id = step.chave;
                            // }
                            isPrimeiro = false;
                        }

                        if (
                            !(
                                !this.checklist.criarConfiguracao &&
                                step.chave === 'configuracao'
                            )
                        ) {
                            listSteps.push(step);
                        }
                    }

                });

            this.listSteps = listSteps;

        }

    }

    private getClass(chave: string) {
        const current = (this._route.url as any)._value.slice(-1)[0].path;

        if ((
            (['contato', 'contato-empresa'].includes(current) && 'contato-empresa' === chave && this.isStepAtivo(chave)) ||
            (['contatoBanco', 'contato-banco'].includes(current) && 'contato-banco' === chave && this.isStepAtivo(chave)) ||
            // (['produto-financeiro', 'produtoFinanceiro'].includes(current) && ('produto-financeiro' === chave || 'produtoFinanceiro' === chave) && this.isStepAtivo(chave)) ||
            current === chave && this.isStepAtivo(chave))) {
            return `icon`;
        }

        return 'icon disabled'
    }

    private getStep(chave: string) {
        return this._checklistNavegacao
            .getListaSteps(this.checklist.id, this.checklist.criarConfiguracao, this.checklist)
            .find((val) => val.chave === chave);
    }

    private getStepByOrdem(ordem: number) {
        return this._checklistNavegacao
            .getListaSteps(this.checklist.id, this.checklist.criarConfiguracao, this.checklist)
            .find((val) => val.ordem === ordem);
    }

    private getProximoStep(step: ChecklistNavegationStep): string {
        const totalSteps = this._checklistNavegacao.getListaSteps(this.checklist.id, this.checklist.criarConfiguracao, this.checklist).length;
        let proximoStep: any = {};

        for (let index = step.ordem + 1; index < totalSteps; index++) {
            proximoStep = this.getStepByOrdem(index);

            if (this.isStepAtivo(proximoStep.chave)) {
                break;
            }
        }
        return proximoStep.chave || null;
    }


    private isStepAtivo(chave: string): boolean {
        switch (chave) {
            case 'contato':
            case 'contato-empresa':
                return this.checklist.validaContato;
            case 'conectividade':
                return this.checklist.validaConectividade;
            case 'dados-empresa':
                return this.checklist.validaEmpresa;
            case 'relacionamento':
                return this.checklist.validaRelacionamento;
            case 'contato-banco':
            case 'contato-banco':
                return this.checklist.validaContatoBanco;
            // case 'adquirente':
            // return this.checklist.validaAdquirentes;
            // case 'produto-financeiro':
            // case 'produtoFinanceiro':
            //     return this.checklist.validaProdutoFinanceiro;
            case 'configuracao':
                return this.checklist.validaConfiguracao;
            case 'finalizacao':
                return true;
            case 'finalizacao-step':
                return true;
        }
        return false;
    }

    seleciona(step: ChecklistNavegationStep): void {
        if (step.isUrlEnable) {
            this._router.navigateByUrl(step.url);
        }
    }
}
