import { Injectable } from '@angular/core';
import { ChecklistNavegationStep } from '../interfaces/checklist-navegation-step.interface';
import { Checklist } from '../interfaces/checklist.interface';

@Injectable({
    providedIn: 'root',
})
export class ChecklistNavegacaoService {
    getListaSteps(
        idChecklist: number,
        isConfiguracao: boolean,
        checklist: Checklist
    ): Array<ChecklistNavegationStep> {
        return [
            {
                ordem: 0,
                isProduto: false,
                chave: 'contato-empresa',
                titulo: 'Contato Empresa',
                url: `/checklist/${idChecklist}/contato`,
                proximoStep: 'conectividade',
            },
            {
                ordem: 1,
                isProduto: false,
                chave: 'conectividade',
                titulo: 'Conectividade',
                url: `/checklist/${idChecklist}/conectividade`,
                proximoStep: 'dados-empresa',
            },
            {
                ordem: 2,
                isProduto: false,
                chave: 'dados-empresa',
                titulo: 'Dados da Empresa',
                url: `/checklist/${idChecklist}/dados-empresa`,
                proximoStep: checklist.validaAdquirentes ? 'adquirente' : 'relacionamento',

            },
            // {
            //     ordem: 3,
            //     isProduto: false,
            //     chave: 'adquirente',
            //     titulo: 'Adquirente',
            //     url: `/checklist/${idChecklist}/adquirente`,
            //     proximoStep: 'relacionamento',
            // },
            {
                ordem: 3,
                isProduto: false,
                chave: 'relacionamento',
                titulo: 'Relacionamento',
                url: `/checklist/${idChecklist}/relacionamento`,
                proximoStep: 'produtoFinanceiro',
            },
            // {
            //     ordem: 4,
            //     isProduto: false,
            //     chave: 'produtoFinanceiro',
            //     titulo: 'Produto Financeiro',
            //     url: `/checklist/${idChecklist}/produto-financeiro`,
            //     proximoStep: 'contato-banco',
            // },
            {
                ordem: 4,
                isProduto: false,
                chave: 'contato-banco',
                titulo: 'Contato Banco',
                url: `/checklist/${idChecklist}/contato-banco`,
                proximoStep: isConfiguracao ? 'configuracao' : 'finalizacao',

            },
            {
                ordem: 5,
                isProduto: false,
                chave: 'configuracao',
                titulo: 'Configuração',
                url: `/checklist/${idChecklist}/configuracao`,
                proximoStep: 'finalizacao-step',
            },
            {
                ordem: 6,
                isProduto: false,
                chave: 'finalizacao-step',
                titulo: 'Finalização Steps',
                url: `/checklist/${idChecklist}/finalizacao-step`,
                proximoStep: 'finalizacao',
            },
            {
                ordem: 7,
                isProduto: false,
                chave: 'finalizacao',
                titulo: 'Finalização',
                url: `/checklist/${idChecklist}/finalizacao`,
                proximoStep: null,
            },
        ];
    }

    getListaStepsVisualizacao(
        idChecklist: number,
        isConfiguracao: boolean
    ): Array<ChecklistNavegationStep> {
        return [
            {
                ordem: 0,
                isProduto: false,
                chave: 'contato-empresa',
                titulo: 'Contato Empresa',
                url: `/checklist/${idChecklist}/visualizar/contato`,
                proximoStep: 'conectividade',
            },
            {
                ordem: 1,
                isProduto: false,
                chave: 'conectividade',
                titulo: 'Conectividade',
                url: `/checklist/${idChecklist}/visualizar/conectividade`,
                proximoStep: 'dados-empresa',
            },
            {
                ordem: 2,
                isProduto: false,
                chave: 'dados-empresa',
                titulo: 'Dados da Empresa',
                url: `/checklist/${idChecklist}/visualizar/dados-empresa`,
                proximoStep: 'relacionamento',
            },
            {
                ordem: 3,
                isProduto: false,
                chave: 'relacionamento',
                titulo: 'Relacionamento',
                url: `/checklist/${idChecklist}/visualizar/relacionamento`,
                proximoStep: 'produtoFinanceiro',
            },

            // {
            //     ordem: 4,
            //     isProduto: false,
            //     chave: 'produtoFinanceiro',
            //     titulo: 'Produto Financeiro',
            //     url: `/checklist/${idChecklist}/visualizar/produto-financeiro`,
            //     proximoStep: 'contato-banco',
            // },
            {
                ordem: 4,
                isProduto: false,
                chave: 'contato-banco',
                titulo: 'Contato Banco',
                url: `/checklist/${idChecklist}/visualizar/contato-banco`,
                proximoStep: isConfiguracao ? 'configuracao' : 'finalizacao',
            },
            {
                ordem: 5,
                isProduto: false,
                chave: 'configuracao',
                titulo: 'Configuração',
                url: `/checklist/${idChecklist}/visualizar/configuracao`,
                proximoStep: 'finalizacao-step',
            },
            {
                ordem: 6,
                isProduto: false,
                chave: 'finalizacao-step',
                titulo: 'Finalização Steps',
                url: `/checklist/${idChecklist}/visualizar/finalizacao-step`,
                proximoStep: 'bu',
            },
            {
                ordem: 7,
                isProduto: false,
                chave: 'finalizacao',
                titulo: 'Finalização',
                url: `/checklist/${idChecklist}/visualizar/finalizacao`,
                proximoStep: null,
            },
        ];
    }
}
