import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Injector,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { DownloadUtil } from '@utils/utils/class/download.util';
import { CotacaoStatus } from '../../shared/interfaces/cotacao-status.interface';
import {
    Cotacao,
    CotacaoSituacao,
} from '../../shared/interfaces/cotacao.interface';
import { CotacaoItemCotacao } from '../../shared/interfaces/cotacao-item-cotacao.interface';
import { StatusFinanceiro } from '../../shared/interfaces/status-financeiro.interface';
import { TipoCobranca } from '../../shared/interfaces/tipo-cobranca.interface';
import { CotacaoDocumentoDownloadService } from '../../shared/services/cotacao-documento-download.service';
import { CotacaoDocumentoUploadService } from '../../shared/services/cotacao-documento-upload.service';
import { CotacaoItemCotacaoService } from '../../shared/services/cotacao-item-cotacao.service';
import { FiltroCotacaoItemCotacao } from '../../shared/interfaces/filtro-cotacao-item-cotacao.interface';
import { environment } from 'environments/environment';
import { CotacaoService } from '../../shared/services/cotacao.service';
import { Usuario } from 'app/main/usuario/shared/interfaces/usuario.interface';
import { Projeto } from 'app/main/projeto/shareds/interfaces/projeto.interface';
import { Contrato } from 'app/main/contrato/shared/interfaces/contrato.interface';
import { ModalContratoVincularComponent } from '../../modal/modal-contrato-vincular/modal-contrato-vincular.component';
import { CotacaoModalService } from '../../shared/services/cotacao-modal.service';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { DataUtil } from '@utils/utils/class/data.util';
import { PermissaoUtil } from '@utils/utils/class/permissao.utl';
import { AuthLocalStorageService } from '@utils/services/auth-local-storage.service';
import { Role } from 'app/fuse-config/role';
import { UsuarioLogado } from '@utils/interfaces/usuario/usuario-logado.interface';
import { CotacaoUtil } from '../../shared/utils/cotacao.util';
import { formatDate } from '@angular/common';
import { ProdutoFinanceiro } from 'app/main/os/shareds/interfaces/produto-financeiro.interface';

@Component({
    selector: 'cotacao-detalhe',
    templateUrl: './cotacao-detalhe.component.html',
    styleUrls: ['./cotacao-detalhe.component.scss'],
})
export class CotacaoDetalheComponent extends GridBase<CotacaoItemCotacao, FiltroCotacaoItemCotacao, CotacaoItemCotacaoService> implements OnInit {
    cotacao: Cotacao = {};
    cotacoes: Array<Cotacao> = [];
    contrato: Contrato = {};
    listaSituacao: Array<any> = [];
    listaStatusCotacao: Array<CotacaoStatus> = [];
    listaStatusFinanceiro: Array<StatusFinanceiro> = [];
    listaTipoCobrancas: Array<TipoCobranca> = [];
    listaProdutoFinanceiro: Array<ProdutoFinanceiro> = [];
    isEdit: boolean = false;
    isEditKickoff: boolean = false;
    isEditSituacao: boolean = false;
    notResend: boolean = true;

    situacoes: Array<any> = [];
    public form: FormGroup;

    CELLPHONE = '(00) 0 0000-0000';
    LANDLINE = '(00) 0000-0000';

    phoneMask = this.LANDLINE;
    phoneNumber = '';
    previusLength = 0;

    CPF_MASK = '000.000.000-00';
    CNPJ_MASK = '00.000.000/0000-00';
    cpfCnpjNumber = '';
    previusCpfCnpjLength = 0;
    cpfCnpjMask = this.CPF_MASK;

    arquivo: any;
    @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
    files: Array<any>;
    arquivosDownloads: Array<any> = [];
    usuarios: Array<Usuario> = [];
    // usuarioLogado: UsuarioLogado;

    constructor(
        protected _injector: Injector,
        protected _service: CotacaoItemCotacaoService,
        protected _changeDetectorRefs: ChangeDetectorRef,
        protected _formBuilder: FormBuilder,
        protected _cotacaoDocumentoUploadService: CotacaoDocumentoUploadService,
        protected _cotacaoDocumentoDownloadService: CotacaoDocumentoDownloadService,
        private _cotacaoService: CotacaoService,
        protected _modalService: CotacaoModalService,
        private _authLocalStorage: AuthLocalStorageService,
    ) {
        super(_service, _injector, _changeDetectorRefs);
        this.form = _formBuilder.group(this.crateForm() || null);
        // this.usuarioLogado = _authLocalStorage.getUsuario();
    }

    ngOnInit(): void {
        this._route.data.subscribe((data) => {
            this.cotacao = { ...data.cotacao, produtosFinanceiros: data.cotacaoProdutoFinanceiro || [] };
            this.listaStatusCotacao = data.statuss;
            this.listaStatusFinanceiro = data.statussFinanceiro;
            this.listaTipoCobrancas = data.tipoCobrancas;
            this.usuarios = data.usuarios;
            if (data.cotacao.permitirReenvioEmailPrimeiroAcesso !== undefined) {
                this.notResend = !data.cotacao
                    .permitirReenvioEmailPrimeiroAcesso;
            }

            this.listaProdutoFinanceiro = data.listaProdutoFinanceiro;


            this.filtro.idCotacao = this.cotacao.id;
            this.initGrid(this.filtro || {});
            this.atualizaListaDownloads();
            this.setForm(this.cotacao);

            // this.isEditSituacao = PermissaoUtil.isAccess(
            //     this.usuarioLogado,
            //     Role.COTACAO_EDITAR_SITUACAO_EMPRESA
            // );
        });
    }

    selectedOption(o1: any, o2: any) {
        return o1.id == o2.id;
    }

    selectedOptionBase(o1: any, o2: any) {
        return o1 == o2;
    }

    protected crateForm(): { [key: string]: any } {
        return {
            valorFaturado: [],
            cotacaoStatus: [],
            statusFinanceiro: [],
            tipoCobranca: ['', Validators.required],
            empresaInscricaoFaturamento: [],
            contatoNome: ['', Validators.required],
            contatoTelefone: ['', Validators.required],
            contatoEmail: ['', Validators.required],
            contatoCobranca: [],
            situacao: [],
            inscricaoEstadual: [],
            logradouro: [],
            numero: [],
            complemento: [],
            bairro: [],
            cep: [],
            municipio: [],
            estado: [],
            empresaNome: [],
            valorSetupFaturado: [],
            usuario: [],
            kickOffHabilitar: [],
            kickOffData: [],
            kickOffRealizado: [],
            kickOffERP: [],
            kickOffComentario: [],
            dataPagamentoSetup: []
        };
    }

    setForm(cotacao: Cotacao) {
        const situacao: CotacaoSituacao = cotacao.situacao
            ? cotacao.situacao
            : {};

        //mascara CPF CNPJ
        this.cpfCnpjNumber = cotacao.empresaInscricaoFaturamento;
        if (cotacao.empresaInscricaoFaturamento.length > 11) {
            this.cpfCnpjMask = this.CNPJ_MASK;
        }

        if (cotacao.dataPagamentoSetup != undefined) {
            cotacao.dataPagamentoSetup = DataUtil.toDateView(cotacao.dataPagamentoSetup)
        }

        this.form.patchValue({
            tipoCobranca: cotacao.tipoCobranca,
            cotacaoStatus: cotacao.cotacaoStatus,
            statusFinanceiro: cotacao.statusFinanceiro,
            empresaInscricaoFaturamento: cotacao.empresaInscricaoFaturamento,
            contatoNome: cotacao.contatoNome,
            contatoTelefone: cotacao.contatoTelefone,
            contatoEmail: cotacao.contatoEmail,
            contatoCobranca: cotacao.contatoCobranca,
            valorSetupFaturado: cotacao.valorSetupFaturado,
            numeroContrato: cotacao.numeroContrato,
            situacao: cotacao.situacao.situacaoCadastral,
            usuario: cotacao.usuario,
            empresaNome: situacao.razaoSocial,
            inscricaoEstadual: situacao.inscricaoEstadual,
            logradouro: situacao.logradouro,
            numero: situacao.numero,
            complemento: situacao.complemento,
            bairro: situacao.bairro,
            cep: situacao.cep,
            municipio: situacao.municipio,
            estado: situacao.estado,
            kickOffHabilitar: cotacao.kickOffHabilitar ? "1" : "",
            kickOffData: cotacao.kickOffData,
            kickOffRealizado: cotacao.kickOffRealizado ? "1" : "0",
            kickOffERP: cotacao.kickOffERP ? "1" : "0",
            kickOffComentario: cotacao.kickOffComentario,
            dataPagamentoSetup: cotacao.dataPagamentoSetup
        });

        // this.formatValidate(cotacao.kickOffHabilitar)
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'produto', label: '' },
            { chave: 'itemCotacao', label: '' },
            { chave: 'modulo', label: '' },
            { chave: 'bancosContratados', label: '' },
            { chave: 'empresasContratadas', label: '' },
            //{chave: 'visualizacaoBancosContratados', label: ''},
            { chave: 'contasContratados', label: '' },
            { chave: 'valorItem', label: '' },
            { chave: 'quantidade', label: '' },
            { chave: 'valorTotal', label: '' },
            { chave: 'percentualCobranca', label: '' },
            { chave: 'statusProjeto', label: '' },
        ];
    }

    limparForm(): void {
        this.form.reset();
    }

    reenviarAcesso(id: number | string): void {
        this._spinner.show();
        this._cotacaoService.sendAccess(id).subscribe(
            () => {
                this.sucess('Cotação atualizada com sucesso!')
            },
            (error) => this.error(error)
        );
    }

    salvar() {
        if (!this.isEdit) {
            return;
        }

        const tipoCobranca = this.form.get('tipoCobranca').value;
        const cotacaoStatus = this.form.get('cotacaoStatus').value;
        const statusFinanceiro = this.form.get('statusFinanceiro').value;
        const usuario = this.form.get('usuario').value;
        const valorSetupFaturado = CotacaoUtil.trataValorFaturado(
            this.form.get('valorSetupFaturado').value
        );
        // const situacaoCadastral = this.form.get('situacao').value;
        // const situacao: CotacaoSituacao = {
        //     situacaoCadastral: situacaoCadastral,
        //     situacaoCadastralDescricao: situacaoCadastral ? 'Ativo' : 'Inativo',
        //     empresaNome: this.form.get('empresaNome').value,
        //     inscricaoEstadual: this.form.get('inscricaoEstadual').value,
        //     inscricaoFaturamento: this.form.get('empresaInscricaoFaturamento')
        //         .value,
        //     logradouro: this.form.get('logradouro').value,
        //     numero: this.form.get('numero').value,
        //     complemento: this.form.get('complemento').value,
        //     bairro: this.form.get('bairro').value,
        //     cep: this.form.get('cep').value,
        //     municipio: this.form.get('municipio').value,
        //     estado: this.form.get('estado').value,
        // };
        const contatoCobranca = this.form.get('contatoCobranca').value;
        const contatoNome = this.form.get('contatoNome').value;
        const contatoTelefone = this.form.get('contatoTelefone').value;
        const contatoEmail = this.form.get('contatoEmail').value;

        const kickOffHabilitar = this.form.get('kickOffHabilitar').value;
        const kickOffData = this.form.get('kickOffData').value;
        const kickOffRealizado = this.form.get('kickOffRealizado').value;
        const kickOffERP = this.form.get('kickOffERP').value;
        const kickOffComentario = this.form.get('kickOffComentario').value;

        const cotacao: Cotacao = Object.assign({}, this.cotacao, {
            valorSetupFaturado: valorSetupFaturado,
            idCotacaoStatus: cotacaoStatus ? cotacaoStatus.id : null,
            idStatusFinanceiro: statusFinanceiro ? statusFinanceiro.id : null,
            idCotacaoTipoCobranca: tipoCobranca ? tipoCobranca.id : null,
            idUsuario: usuario ? usuario.id : null,
            emailsCobranca: contatoCobranca
                ? contatoCobranca.split(/[,;]/g)
                : [],
            // situacao: situacao,
            contatoNome: contatoNome,
            contatoTelefone: contatoTelefone,
            contatoEmail: contatoEmail,

            kickOffHabilitar: kickOffHabilitar ? 1 : 0,
            kickOffData: kickOffHabilitar ? DataUtil.toDateBackend(kickOffData) : "",
            kickOffRealizado: kickOffHabilitar ? parseInt(kickOffRealizado) : 0,
            kickOffERP: kickOffHabilitar ? parseInt(kickOffERP) : 0,
            kickOffComentario: kickOffHabilitar ? kickOffComentario : "",
            produtosFinanceiros: this.cotacao.produtosFinanceiros

        });

        cotacao.situacao = undefined;

        if (cotacaoStatus) {
            this._confirmation
                .confirm({
                    titulo:
                        'Tem certeza que deseja continuar com a Alteração do Status da Cotação?',
                    mensagem:
                        'Esta alteração impacta nos Projetos relacionados.',
                })
                .subscribe((result) => {
                    if (result) {
                        this.execSave(cotacao);
                    }
                });
        } else {
            this.execSave(cotacao);
        }
    }

    visualizarBancosContratados(itemCotacao: CotacaoItemCotacao) {
        this._modalService.empresasContratadas(itemCotacao)
    }

    vincularContrato() {
        this._modalService.contratoVincular(this.cotacao).subscribe(() => {
            if (this.cotacao && this.cotacao.id) {
                this._cotacaoService.findById(this.cotacao.id).subscribe(result => {
                    this.cotacao = result.data
                })
            }
        })
    }

    editar() {
        let kickOffHabilitar = this.form.get('kickOffHabilitar').value
        if (kickOffHabilitar) {
            this.isEditKickoff = true;
        }
        this.isEdit = true;
        this.notResend = true;
    }

    isMostaEdit(): boolean {
        return !(this.cotacao && this.cotacao.cotacaoStatus && this.cotacao.cotacaoStatus.descricao === 'Cancelado')
    }

    editarKickoff(event) {
        if (this.isEdit) {
            if (event.checked) {
                this.isEditKickoff = true;
            } else {
                if (this.form.get('kickOffRealizado').value === "1") {
                    this._notification.error(
                        'Para desabilitar o Kick-ff é preciso marcar "Kick-off Realizado" como "NÃO"',
                        'Atenção!'
                    );
                    this.form.patchValue({
                        kickOffHabilitar: "1",
                    });

                    this.isEditKickoff = true;
                } else {
                    this.isEditKickoff = false;
                }
            }
            this.formatValidate(event.source.value)
        }
    }

    private formatValidate(kickOffRealizado) {
        if (kickOffRealizado === "1" || kickOffRealizado === true) {
            this.form.get("kickOffData").setValidators([Validators.required]);
            this.form.get("kickOffData").updateValueAndValidity();
        } else {
            this.form.get("kickOffData").setValidators([]);
            this.form.get("kickOffData").updateValueAndValidity();
        }
    }

    bloquear(permitirReenvio) {
        this.isEditKickoff = false;
        this.isEdit = false;
        if (permitirReenvio) {
            this.notResend = false;
        }
    }

    visualizarProjeto(idProjeto) {
        const link = `${environment.host}/projeto/visualizar/${idProjeto}`;
        this._router.navigate([]).then((result) => {
            window.open(link, '_blank');
        });
    }

    onPhoneChanged() {
        this.phoneMask = this.LANDLINE;
        if (
            this.phoneNumber &&
            this.phoneNumber.length >= 10 &&
            this.phoneMask >= this.LANDLINE &&
            this.previusLength >= 10
        ) {
            this.phoneMask = this.CELLPHONE;
        }

        this.previusLength = this.phoneNumber ? this.phoneNumber.length : 0;
    }

    oncpfCnpjChanged() {
        this.phoneMask = this.CPF_MASK;
        if (
            this.cpfCnpjNumber &&
            this.cpfCnpjNumber.length >= 11 &&
            this.cpfCnpjMask >= this.CPF_MASK &&
            this.previusCpfCnpjLength >= 11
        ) {
            this.cpfCnpjMask = this.CNPJ_MASK;
        }

        this.previusCpfCnpjLength = this.cpfCnpjNumber
            ? this.cpfCnpjNumber.length
            : 0;
    }

    visualizarContrato(idContrato) {
        const link = `${environment.host}/contrato/${idContrato}`;
        this._router.navigate([]).then((result) => {
            window.open(link, '_blank');
        });
    }

    // ==========================================
    //                  UPLOAD
    // ==========================================
    onFileDropped($event): void {
        this.prepareFile($event);
    }

    fileBrowseHandler(files): void {
        this.prepareFile(files);
    }

    deleteFile(file, i): void {
        if (file.progress > 0 && file.progress < 100) {
            return;
        }
        this.files.splice(i, 1);
    }

    deleteFiles(): void {
        this.files = null;
    }

    upload(): void {
        this.files.forEach((file, i) => {
            this._cotacaoDocumentoUploadService
                .upload(this.cotacao.id, file)
                .subscribe(
                    (event: HttpEvent<any>) => {
                        if (event.type === HttpEventType.Response) {
                            this._notification.sucess(
                                'Upload realizado com sucesso!',
                                'Acompanhe o status da importação pela grid'
                            );
                            if (this.files.length >= i + 1) {
                                this.deleteFiles();
                                this.atualizaListaDownloads();
                            }
                        } else if (
                            event.type === HttpEventType.UploadProgress
                        ) {
                            file.progress = Math.round(
                                (event.loaded * 100) / event.total
                            );
                        }
                    },
                    (error) => {
                        this._notification.error('Erro no upload do arquivo!');
                    }
                );
        });
    }

    prepareFile(files: FileList): void {
        this.files = Array.from(files);
        this.files.forEach((file) => {
            file.progress = 0;
        });

        this.fileDropEl.nativeElement.value = '';
    }

    formatBytes(bytes, decimals = 2): string {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        );
    }
    // ==========================================
    //                  DOWNLOAD
    // ==========================================
    download(arquivoDownload: any): void {
        this._spinner.show();
        this._cotacaoDocumentoDownloadService
            .download(arquivoDownload.id)
            .subscribe(
                (result) => {
                    DownloadUtil.base64(
                        result.data.arquivo,
                        result.data.name,
                        result.data.type
                    );
                    this._spinner.hide();
                },
                (error) => this.error(error)
            );
    }

    remover(arquivoDownload: any): void {
        this._confirmation
            .confirm({ titulo: 'Deseja excluir esse arquivo?' })
            .subscribe((result) => {
                if (result) {
                    this._spinner.show();
                    this._cotacaoDocumentoDownloadService
                        .delete(arquivoDownload.id)
                        .subscribe(
                            () => {
                                this._spinner.hide();
                                this._notification.sucess(
                                    'Arquivo removido com sucesso!'
                                );
                                this.atualizaListaDownloads();
                            },
                            (error) => this.error(error)
                        );
                }
            });
    }

    exibeProjeto(projetos: Array<Projeto>) {
        let retorno = '';
        projetos.forEach(projeto => {
            retorno += `${projeto.id} - ${projeto.projetoStatus.nome},`
        })
        return retorno;
    }

    alterarStatus(status) {

    }

    private atualizaListaDownloads() {
        this._spinner.show();
        this._cotacaoDocumentoDownloadService.find(this.cotacao.id).subscribe(
            (c) => {
                this.arquivosDownloads = c.data;
                this._spinner.hide();
            },
            (error) => this.error(error)
        );
    }



    private execSave(cotacao: Cotacao) {
        this._spinner.show();
        this._cotacaoService.update(cotacao).subscribe(
            () => {
                this.sucess('Cotação atualizada com sucesso!');
            },
            (error) => this.error(error)
        );
    }


    private temProdutoFinanceiro(produto: ProdutoFinanceiro) {
        return !!this.cotacao.produtosFinanceiros.find(({ id }) => (produto as any).codigo === id);
    }

    private alterarProdutoFinanceiro(produto: ProdutoFinanceiro) {
        if (this.temProdutoFinanceiro(produto)) {
            this.cotacao = { ...this.cotacao, produtosFinanceiros: this.cotacao.produtosFinanceiros.filter(({ id }) => (produto as any).codigo !== id) }
            return;
        }

        this.cotacao = { ...this.cotacao, produtosFinanceiros: [...this.cotacao.produtosFinanceiros, { id: (produto as any).codigo, nome: produto.nome }] }
    }

}
