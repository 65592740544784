import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormBase } from '@utils/base/form-base/form-base';
import { StatusFinanceiro } from '../../shared/interfaces/status-financeiro.interface';
import { TipoCobranca } from '../../shared/interfaces/tipo-cobranca.interface';
import { CotacaoStatusService } from '../../shared/services/cotacao-status.service';
import { StatusFinanceiroService } from '../../shared/services/status-financeiro.service';
import { TipoCobrancaService } from '../../shared/services/tipo-cobranca.service';
import { CotacaoStatus } from '../../shared/interfaces/cotacao-status.interface';
import { CotacaoEditar } from '../../shared/interfaces/cotacao-editar.interface';
import { CotacaoEdicaoService } from '../../shared/services/cotacao-edicao.service';
import { ConfirmationService } from '@utils/utils/confirmation/confirmation.service';

@Component({
    selector: 'editar-cotacao',
    templateUrl: './editar-cotacao.component.html',
    styleUrls: ['./editar-cotacao.component.scss'],
})
export class EditarCotacaoComponent extends FormBase implements OnInit {
    private _cotacaoEditar: CotacaoEditar;
    isView: boolean = false;
    listaCotacaoStatus: Array<CotacaoStatus> = [];
    listaStatusFinanceiro: Array<StatusFinanceiro> = [];
    listaTipoCobranca: Array<TipoCobranca> = [];

    checkCotacaoStatus: boolean = false;
    checkStatusFinanceiro: boolean = false;
    checkTipoCobranca: boolean = false;
    todosFull: boolean = false;

    @Output() atualizado = new EventEmitter<boolean>();

    get idsCotacao() {
        return this._cotacaoEditar;
    }

    @Input() set idsCotacao(cotacaoEditar: CotacaoEditar) {
        const idsCotacoes = cotacaoEditar.idCotacoes;
        this._cotacaoEditar = cotacaoEditar;
        if (idsCotacoes && idsCotacoes.length > 0) {
            this.isView = true;
        }
    }

    constructor(
        private _injector: Injector,
        private _statusService: CotacaoStatusService,
        private _statusFinanceiroService: StatusFinanceiroService,
        private _tipoCobrancaService: TipoCobrancaService,
        private _service: CotacaoEdicaoService,
    ) {
        super(_injector);
    }

    ngOnInit() {
        this._spinner.show();
        this._statusService.find({}).subscribe(
            (result) => {
                this.listaCotacaoStatus = result.data;
                this._spinner.hide();
            },
            (error) => {
                this._spinner.hide();
                this._notification.error(error.error.message);
            }
        );
        this._statusFinanceiroService.find({}).subscribe(
            (result) => {
                this.listaStatusFinanceiro = result.data;
                this._spinner.hide();
            },
            (error) => {
                this._spinner.hide();
                this._notification.error(error.error.message);
            }
        );
        this._tipoCobrancaService.find({}).subscribe(
            (result) => {
                this.listaTipoCobranca = result.data;
                this._spinner.hide();
            },
            (error) => {
                this._spinner.hide();
                this._notification.error(error.error.message);
            }
        );
    }

    selecionaCheckCotacaoStatus(check) {
        this.checkCotacaoStatus = check;
        if (!check) {
            this.form.get('statusCotacao').setValue('');
        }
    }
    selecionaCheckStatusFinanceiro(check) {
        this.checkStatusFinanceiro = check;
        if (!check) {
            this.form.get('statusFinanceiro').setValue('');
        }
    }
    selecionaCheckTipoCobranca(check) {
        this.checkTipoCobranca = check;
        if (!check) {
            this.form.get('tipoCobranca').setValue('');
        }
    }

    selecionaTodos(check) {
        if (!check) {
            this.form.reset();
        }
        this.checkCotacaoStatus = check;
        this.checkStatusFinanceiro = check;
        this.checkTipoCobranca = check;
    }

    isDesabled(chave: string) {
        switch (chave) {
            case 'checkCotacaoStatus':
                return !this.checkCotacaoStatus;
                break;
            case 'checkStatusFinanceiro':
                return !this.checkStatusFinanceiro;
                break;
            case 'checkTipoCobranca':
                return !this.checkTipoCobranca;
                break;
        }
    }

    alterar() {
        this._cotacaoEditar = Object.assign({}, this._cotacaoEditar, {
            idCotacaoStatus: this.form.get('statusCotacao').value,
            idStatusFinanceiro: this.form.get('statusFinanceiro').value,
            idTipoCobranca: this.form.get('tipoCobranca').value,
        });

        if (this._cotacaoEditar.idCotacaoStatus) {
            this._confirmation
                .confirm({
                    titulo:
                        'Tem certeza que deseja continuar com a Alteração do Status da Cotação?',
                    mensagem:
                        'Esta alteração impacta nos Projetos relacionados.',
                })
                .subscribe((result) => {
                    if (result) {
                        this.alterarExec();
                    }
                });
        } else {
            this.alterarExec();
        }
    }

    private alterarExec() {
        this._spinner.show();
        this._service.update(this._cotacaoEditar).subscribe(
            () => {
                this._spinner.hide();
                this._notification.sucess('Cotações alteradas com sucesso!');
                this.atualizado.emit(true);
            },
            (error) => {
                this._spinner.hide();
                this._notification.error(error.error.message);
            }
        );
    }

    protected crateForm(): { [key: string]: any } {
        return {
            statusCotacao: [],
            statusFinanceiro: [],
            tipoCobranca: [],
        };
    }
}
