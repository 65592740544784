import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Checklist } from '../../shared/interfaces/checklist.interface';
import { ChecklistContatoEmpresaService } from '../../shared/services/checklist-contato-empresa.service';
import { ComponentBase } from '../../../../../@utils/base/component-base/component-base';

@Component({
    selector: 'modal-checklist-emails',
    templateUrl: './modal-checklist-emails.component.html',
    styleUrls: ['./modal-checklist-emails.component.scss'],
})
export class ModalChecklistEmailsComponent extends ComponentBase implements OnInit {
    tituloModal = 'Confirmar contatos para envio de checklist';

    constructor(
        private _injector: Injector,
        public dialogRef: MatDialogRef<ModalChecklistEmailsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(MAT_DIALOG_DATA) public idChecklist: number,
        private _checklistContatoEmpresaService: ChecklistContatoEmpresaService
    ) {
        super(_injector);
    }
    ngOnInit(): void {
    }

    fechar(result): void {
        this.dialogRef.close(result);
    }

    enviarNotificacao(): void {
        this._spinner.show();
        this._checklistContatoEmpresaService.sendNotificacaoNovoChecklist(this.data.idChecklist)
            .subscribe(
                (response) => {
                    this._spinner.hide();
                    this.dialogRef.close(response);
                },
                (error) => {
                    this._spinner.hide();
                    this.dialogRef.close(error);
                });

    }
}
