import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

export const isFutureDate: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent || !control) {
        return null
    }

    // const dataAtual = Date.now()
    const dataAtual = control.parent.get('dataMudanca')
    const inputDataMudanca = control.parent.get('dataTerminoMudanca')

    if (!inputDataMudanca) {
        return null
    }

    if (inputDataMudanca.value === '') {
        return null
    }

    if (!inputDataMudanca.value || !inputDataMudanca.value._d) {
        return null
    }

    const inputDataAtual = new Date(dataAtual.value._d)
    const inputDataTermino = new Date(inputDataMudanca.value._d)

    if (!(+inputDataAtual > +inputDataTermino)) return null

    return {
        isFuture: (+inputDataAtual > +inputDataTermino) ? true : null
    }
}
