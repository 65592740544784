import { Injectable } from '@angular/core';
import { BaseGuard, GuardConfigPermission } from '@utils/guard/base.guard';
import { Role } from 'app/fuse-config/role';

@Injectable()
export class BiGuard extends BaseGuard {
    protected configPermissions(): Array<GuardConfigPermission> {
        return [
            { path: '/bi/suporte', role: Role.BI_SUPORTE },
            { path: '/bi/bu', role: Role.BI_BU },
            { path: '/bi/kpi-dev-team', role: Role.BI_KPI_DEV_TEAM },
            { path: '/bi/kpi-negocios', role: Role.BI_KPI_NEGOCIOS },
            { path: '/bi/kpi-onboarding', role: Role.BI_KPI_ONBOARDING },
            {
                path: '/bi/portal-boletos-gerencial',
                role: Role.BI_PORTAL_BOLETOS_GERENCIAL,
            },
            { path: '/bi/painel-suporte', role: Role.BI_PAINEL_SUPORTE },
            { path: '/bi/painel-fomento', role: Role.BI_PAINEL_FOMENTO },
            { path: '/bi/csc', role: Role.BI_CSC_TECNOLOGIA },
            { path: '/bi/relatorio', role: Role.BI_DIRETORIA },
            { path: '/bi/jbs', role: Role.BI_JBS },
            { path: '/bi/produto', role: Role.BI_PRODUTO },
        ];
    }
}
