import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContratoPrioridade } from '../interfaces/contrato-prioridade.interface';
import { ContratoPrioridadeService } from '../services/contrato-prioridade.service';

@Injectable()
export class ContratoPrioridadeListaResolver implements Resolve<ContratoPrioridade> {
    constructor(private _contratoStatusService: ContratoPrioridadeService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._contratoStatusService.find({}).pipe(map((result) => result.data));
    }
}
