import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContratoSegmento } from '../interfaces/contrato-segmento.interface';
import { ContratoSegmentoService } from '../services/contrato-segmento.service';

@Injectable()
export class ContratoSegmentoListaResolver implements Resolve<ContratoSegmento> {
    constructor(private _contratoStatusService: ContratoSegmentoService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._contratoStatusService.find({}).pipe(map((result) => result.data));
    }
}
