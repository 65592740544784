import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Adquirente } from '../interfaces/adquirente.interfaces';
import { CadastroAdquirente } from '../interfaces/cadastro-adquirente.interfaces';
import { FiltroAdquirente } from '../interfaces/filtro-adquirente.interfaces';

@Injectable({
  providedIn: 'root'
})
export class AdquirenteService extends ServiceGridBase<Adquirente, FiltroAdquirente>{
  private path: string = `${environment.server}/adquirente`;

  constructor(private _http: HttpClient) {
    super();
  }

  find(filtro?: FiltroAdquirente): Observable<Response<Array<Adquirente>>> {
    const params: string = filtro ? this.toGetParams(filtro) : '';
    const url = `${this.path}/listar${params}`;
    return this._http.get<Response<Array<Adquirente>>>(url);
  }

  findByChecklist(filtro?: FiltroAdquirente): Observable<Response<Array<Adquirente>>> {
    const url = `${environment.server}/checklist/${filtro.idChecklist}/adquirentes/lista`;
    return this._http.get<Response<Array<Adquirente>>>(url);
  }

  findby(id: number): Observable<Response<Array<Adquirente>>> {
    const url = `${this.path}/${id}`;
    return this._http.get<Response<Array<Adquirente>>>(url);
  }

  save(adquirente: CadastroAdquirente | Adquirente): Observable<Response<Adquirente>> {
    const modulosProdutos = [];
    adquirente.modulosProdutos.forEach((field) => {
      modulosProdutos.push({ idProduto: field.idProduto, idModulo: field.idModulo });
    });

    adquirente.modulosProdutos = modulosProdutos;

    if (adquirente.id != undefined) {
      return this.update(adquirente);
    }
    return this.create(adquirente);
  }

  create(adquirente: Adquirente): Observable<Response<Adquirente>> {
    const url = `${this.path}`;
    return this._http.post<Response<Adquirente>>(url, adquirente);
  }

  update(adquirente: Adquirente): Observable<Response<Adquirente>> {
    const url = `${this.path}/${adquirente.id}`;
    return this._http.put<Response<Adquirente>>(url, adquirente);
  }

  delete(id: number): Observable<Response<Adquirente>> {
    const url = `${this.path}/${id}`;
    return this._http.delete<Response<Adquirente>>(url);
  }
}
