import { Component, Injector, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Location } from '@angular/common';
import { FormGroup, Validators } from '@angular/forms';
import { FormBase } from '@utils/base/form-base/form-base';
import { TabelaDominio } from 'app/main/tabela-dominio/shareds/interfaces/tabela-dominio.interface';
import { ProjetoAberturaConfiguracao, ProjetoAberturaData } from '../../shareds/interfaces/projeto-abertura-configuracao.interface';
import { ProjetoAberturaService } from '../../shareds/services/projeto-abertura.service';
import { LayoutService } from 'app/main/os/shareds/services/layout.service';
import { ProjetoModalService } from '../../shareds/services/projeto-modal.service';
import { Banco } from 'app/main/banco/shareds/interfaces/banco.interface';
import { ProjetoAberturaConfiguracaoService } from '../../shareds/services/projeto-abertura-configuracao.service';
import { ProjetoAberturaStatusService } from '../../shareds/services/projeto-abertura-status.service';
import { ProjetoAberturaStatusConfiguracao } from '../../shareds/interfaces/projeto-abertura-status-configuracao.interface';
import { isNumber } from 'lodash';


@Component({
    selector: 'projeto-abertura-cadastro',
    templateUrl: './projeto-abertura-cadastro.component.html',
    styleUrls: ['./projeto-abertura-cadastro.component.scss']
})
export class ProjetoAberturaCadastroComponent extends FormBase implements OnInit {


    form!: FormGroup;
    isView: boolean = false;
    bancos: Array<Banco> = [];
    layouts: Array<TabelaDominio> = [];
    custosTraf: Array<TabelaDominio> = [];
    formatos: Array<TabelaDominio> = [];
    CRLF: Array<TabelaDominio> = [];
    removeAcentos: Array<TabelaDominio> = [];
    insertTimestamp: Array<TabelaDominio> = [];
    insertSeq: Array<TabelaDominio> = [];
    insertSeqType: Array<TabelaDominio> = [];
    timestampTX: Array<TabelaDominio> = [];
    timestampTR: Array<TabelaDominio> = [];
    projetoAbertura: ProjetoAberturaConfiguracao = {};
    remessa_caixaPostalOrigemId: Number = null;
    remessa_caixaPostalDestinoId: Number = null;
    retorno_caixaPostalOrigemId: Number = null;
    retorno_caixaPostalDestinoId: Number = null;
    portal_caixaPostalOrigemId: Number = null;
    portal_caixaPostalDestinoId: Number = null;

    remessa_status: any = {
        data: '',
        nome: 'Aguardando configuração',
        cor: 'silver',
        icone: false,
        retorno: '',
    };
    retorno_status: any = {
        data: '',
        nome: 'Aguardando configuração',
        cor: 'silver',
        icone: false,
        retorno: '',
    };
    portal_status: any = {
        data: '',
        nome: 'Aguardando configuração',
        cor: 'silver',
        icone: false,
        retorno: '',
    };
    showButtonRemessaConfigurarDisabled = true;
    showButtonRetornoConfigurarDisabled = true;
    showButtonPortalConfigurarDisabled = true;
    aberturaSalva: boolean = false;
    isViewRemessa: boolean = false;
    isViewRetorno: boolean = false;
    isViewPortal: boolean = false;

    constructor(
        private _location: Location,
        private _injector: Injector,
        private _projetoModalService: ProjetoModalService,
        private _projetoAberturaService: ProjetoAberturaService,
        private _projectoAberturaConfiguracaoService: ProjetoAberturaConfiguracaoService,
        private _projetoAberturaStatusService: ProjetoAberturaStatusService,
        private _layoutService: LayoutService,
    ) {
        super(_injector, ProjetoAberturaCadastroCombos.combos());
    }

    get f() { return this.form.controls; }

    ngOnInit() {

        if (/abertura\/visualizar\/\:id/.test(this._route.snapshot.routeConfig.path)) {
            this.isView = true;
            this.form.clearValidators();
        }
        this._route.data.subscribe((data) => {
            this.optionList.listaLayouts = data.layouts;
            this.optionList.listaCustosTraf = data.custos;
            this.optionList.listaFormatos = data.formatos;
            this.optionList.listaCustoTrafego = data.custoTrafego;


            this.optionList.listaTimestampTX = data.timestamptx;
            this.optionList.listaTimestampTR = data.timestamptr;

            this.optionList.listaCRLF = data.crlf;
            this.optionList.listaRemoveAcentos = data.removeacentos;
            this.optionList.listaInsertTimestamp = data.inserttimestamp;
            this.optionList.listaInsertSeqType = data.insertseqtype;
            this.optionList.listaInsertSeq = data.insertseq;

            if (data.projetoAbertura) {
                this.projetoAbertura = data.projetoAbertura;
                if (data.projetoAbertura.produtoFinanceiro) {
                    this.loadLayoutPortal(data.projetoAbertura.produtoFinanceiro.id, data.projetoAbertura)
                }

                if (this.projetoAbertura.dsnames) {
                    this.remessa_status = this.valiateConfiguradorEnvioDsNameStatus(this.projetoAbertura.dsnames.remessa);
                    this.showButtonRemessaConfigurarDisabled = this.remessa_status.buttons;
                    this.retorno_status = this.valiateConfiguradorEnvioDsNameStatus(this.projetoAbertura.dsnames.retorno);
                    this.showButtonRetornoConfigurarDisabled = this.retorno_status.buttons;
                    this.portal_status = this.valiateConfiguradorEnvioDsNameStatus(this.projetoAbertura.dsnames.ativacao);
                    this.showButtonPortalConfigurarDisabled = this.portal_status.buttons;
                    this.setStatus(this.projetoAbertura.dsnames);
                }

                this.formatValidate();
                this.setForm(data.projetoAbertura);
            }
            this.refreshOptionsConfig();
        });
    }

    valiateConfiguradorEnvioDsNameStatus(dsnameOrigem: any) {
        let status: any = {};

        status.data = '';
        status.cor = 'silver';
        status.icone = false;
        status.nome = 'Aguardando configuração';
        status.retorno = '';
        status.buttons = false

        if (!dsnameOrigem.configuradorEnvioDsNameStatus) {
            status.buttons = (!dsnameOrigem.id) ? true : false;
            return status;
        }

        let pos = dsnameOrigem.configuradorEnvioDsNameStatus.nome.search('erro');
        if (pos > 0) {
            status.data = dsnameOrigem.configuradorEnvioDsNameStatus.nome
            status.cor = 'red';
            status.icone = true;
            status.nome = dsnameOrigem.configuradorEnvioDsNameStatus.nome;
            status.retorno = '';
            status.buttons = false;
            this.aberturaSalva = false;
        } else {
            status.data = dsnameOrigem.configuradorEnvioDsNameStatus.nome;
            status.cor = 'green';
            status.icone = false;
            status.nome = dsnameOrigem.configuradorEnvioDsNameStatus.nome;
            status.retorno = '';
            status.buttons = true;
            this.aberturaSalva = true;
            let poi = dsnameOrigem.configuradorEnvioDsNameStatus.nome.search('Deploy');
            if (poi > 0) {
                status.buttons = false;
                this.aberturaSalva = false;
            }
        }

        return status;

    }

    private formatValidate() {

        if (this.projetoAbertura &&
            this.projetoAbertura.dsnames &&
            this.projetoAbertura.dsnames.remessa &&
            this.projetoAbertura.dsnames.remessa.insertSeq &&
            this.projetoAbertura.dsnames.remessa.insertSeq.toString() == 'S') {
            this.form.get("remessa_insertSeqNumberLen").setValidators([Validators.required]);
            this.form.get("remessa_insertSeqNumberLen").updateValueAndValidity();

            this.form.get("remessa_insertSeqNumberMax").setValidators([Validators.required]);
            this.form.get("remessa_insertSeqNumberMax").updateValueAndValidity();

            this.form.get("remessa_insertSeqNumbermin").setValidators([Validators.required]);
            this.form.get("remessa_insertSeqNumbermin").updateValueAndValidity();
        }

        if (
            this.projetoAbertura &&
            this.projetoAbertura.dsnames &&
            this.projetoAbertura.dsnames.remessa
        ) {
            // Objeto existe então valide caixa de destino e origem
            if ((
                this.projetoAbertura.dsnames.remessa.cxpIdDestino &&
                this.projetoAbertura.dsnames.remessa.cxpIdDestino.toString() == 'EBCDIC'
            ) && (
                    this.projetoAbertura.dsnames.remessa.cxpIdOrigem &&
                    this.projetoAbertura.dsnames.remessa.cxpIdOrigem.toString() == 'EBCDIC'
                )) {
                // Caixa de destino e origem existem sob mesmas condições então execute as regras
                this.form.get("remessa_fileRegexEBCDIC").setValidators([Validators.required]);
                this.form.get("remessa_fileRegexEBCDIC").updateValueAndValidity();

                this.form.get("remessa_maxRecSizeEBCDIC").setValidators([Validators.required]);
                this.form.get("remessa_maxRecSizeEBCDIC").updateValueAndValidity();
            }

            if ((
                this.projetoAbertura.dsnames.remessa.cxpIdDestino &&
                this.projetoAbertura.dsnames.remessa.cxpIdDestino.toString() == 'BRADESCOXFB'
            ) && (
                    this.projetoAbertura.dsnames.remessa.cxpIdOrigem &&
                    this.projetoAbertura.dsnames.remessa.cxpIdOrigem.toString() == 'BRADESCOXFB'
                )) {
                // Caixa de destino e origem existem sob mesmas condições então execute as regras
                this.form.get("remessa_formato").setValidators([Validators.required]);
                this.form.get("remessa_formato").updateValueAndValidity();

                this.form.get("remessa_maxRecSize").setValidators([Validators.required]);
                this.form.get("remessa_maxRecSize").updateValueAndValidity();

                this.form.get("remessa_CRLF").setValidators([Validators.required]);
                this.form.get("remessa_CRLF").updateValueAndValidity();
            }

        } // Fim da validação do objeto


    }

    private setForm(projetoAbertura: ProjetoAberturaConfiguracao) {
        this.remessa_caixaPostalOrigemId = projetoAbertura.dsnames.remessa.cxpIdOrigem;
        this.retorno_caixaPostalOrigemId = projetoAbertura.dsnames.retorno.cxpIdOrigem;
        this.portal_caixaPostalOrigemId = projetoAbertura.dsnames.ativacao.cxpIdOrigem;

        this.remessa_caixaPostalDestinoId = projetoAbertura.dsnames.remessa.cxpIdDestino;
        this.retorno_caixaPostalDestinoId = projetoAbertura.dsnames.retorno.cxpIdDestino;
        this.portal_caixaPostalDestinoId = projetoAbertura.dsnames.ativacao.cxpIdDestino;

        this.form.patchValue({
            empresa: projetoAbertura.core.empresaNome,
            inscricao: projetoAbertura.core.empresaInsc,
            banco: projetoAbertura.core.banco,
            agencia: projetoAbertura.core.agencia,
            agenciadv: projetoAbertura.core.agenciaDV,
            conta: projetoAbertura.core.conta,
            contadv: projetoAbertura.core.contaDV,
            convenio: projetoAbertura.core.convenio,

            remessa_idAbertura: projetoAbertura.dsnames.remessa.id,
            remessa_layout: projetoAbertura.layoutRemessaId,
            remessa_custoTrafego: projetoAbertura.dsnames.remessa.custoTrafego,
            remessa_dsName: projetoAbertura.dsnames.remessa.dsname,
            remessa_caixaPostalOrigem: projetoAbertura.dsnames.remessa.cxpCodigoOrigem,
            remessa_rename: projetoAbertura.dsnames.remessa.rename,
            remessa_caixaPostalDestino: projetoAbertura.dsnames.remessa.cxpCodigoDestino,
            remessa_formato: projetoAbertura.dsnames.remessa.formato,
            remessa_maxRecSize: projetoAbertura.dsnames.remessa.maxRecSize,
            remessa_CRLF: projetoAbertura.dsnames.remessa.crlf,
            remessa_timestampTX: projetoAbertura.dsnames.remessa.timestampTx,
            remessa_timestampTR: projetoAbertura.dsnames.remessa.timestampRx,
            remessa_removeAcentos: projetoAbertura.dsnames.remessa.removeAcentos,
            remessa_insertTimestamp: projetoAbertura.dsnames.remessa.insertTimestamp,
            remessa_insertSeqType: projetoAbertura.dsnames.remessa.insertSeqType,
            remessa_insertSeq: projetoAbertura.dsnames.remessa.insertSeq,
            remessa_insertSeqNumberLen: projetoAbertura.dsnames.remessa.insertSeqNumberLen,
            remessa_insertSeqNumberMax: projetoAbertura.dsnames.remessa.insertSeqNumberMax,
            remessa_insertSeqNumbermin: projetoAbertura.dsnames.remessa.insertSeqNumberMin,
            remessa_mapas: projetoAbertura.dsnames.remessa.mapas,
            remessa_fileRegexEBCDIC: projetoAbertura.dsnames.remessa.fileRegexEbcdic,
            remessa_maxRecSizeEBCDIC: projetoAbertura.dsnames.remessa.maxRecSizeEbcdic,
            remessa_configuradorEnvioDsNameStatus: (projetoAbertura.dsnames.remessa.configuradorEnvioDsNameStatus) ? projetoAbertura.dsnames.remessa.configuradorEnvioDsNameStatus.nome : null,

            retorno_idAbertura: projetoAbertura.dsnames.retorno.id,
            retorno_layout: projetoAbertura.layoutRetornoId,
            retorno_custoTrafego: projetoAbertura.dsnames.retorno.custoTrafego,
            retorno_Dsname: projetoAbertura.dsnames.retorno.dsname,
            retorno_caixaPostalOrigem: projetoAbertura.dsnames.retorno.cxpCodigoOrigem,
            retorno_rename: projetoAbertura.dsnames.retorno.rename,
            retorno_caixaPostalDestino: projetoAbertura.dsnames.retorno.cxpCodigoDestino,
            retorno_formato: projetoAbertura.dsnames.retorno.formato,
            retorno_maxRecSize: projetoAbertura.dsnames.retorno.maxRecSize,
            retorno_CRLF: projetoAbertura.dsnames.retorno.crlf,
            retorno_timestampTX: projetoAbertura.dsnames.retorno.timestampTx,
            retorno_timestampTR: projetoAbertura.dsnames.retorno.timestampRx,
            retorno_removeAcentos: projetoAbertura.dsnames.retorno.removeAcentos,
            retorno_insertTimestamp: projetoAbertura.dsnames.retorno.insertTimestamp,
            retorno_insertSeqType: projetoAbertura.dsnames.retorno.insertSeqType,
            retorno_insertSeq: projetoAbertura.dsnames.retorno.insertSeq,
            retorno_insertSeqNumberLen: projetoAbertura.dsnames.retorno.insertSeqNumberLen,
            retorno_insertSeqNumberMax: projetoAbertura.dsnames.retorno.insertSeqNumberMax,
            retorno_insertSeqNumbermin: projetoAbertura.dsnames.retorno.insertSeqNumberMin,
            retorno_mapas: projetoAbertura.dsnames.retorno.mapas,
            retorno_fileRegexEBCDIC: projetoAbertura.dsnames.retorno.fileRegexEbcdic,
            retorno_maxRecSizeEBCDIC: projetoAbertura.dsnames.retorno.maxRecSizeEbcdic,
            retorno_configuradorEnvioDsNameStatus: (projetoAbertura.dsnames.retorno.configuradorEnvioDsNameStatus) ? projetoAbertura.dsnames.retorno.configuradorEnvioDsNameStatus.nome : null,

            portal_idAbertura: projetoAbertura.dsnames.ativacao.id,
            portal_layout: projetoAbertura.layoutAtivacaoId,
            portal_custoTrafego: projetoAbertura.dsnames.ativacao.custoTrafego,
            portal_Dsname: projetoAbertura.dsnames.ativacao.dsname,
            portal_caixaPostalOrigem: projetoAbertura.dsnames.ativacao.cxpCodigoOrigem,
            portal_rename: projetoAbertura.dsnames.ativacao.rename,
            portal_caixaPostalDestino: projetoAbertura.dsnames.ativacao.cxpCodigoDestino,
            portal_formato: projetoAbertura.dsnames.ativacao.formato,
            portal_maxRecSize: projetoAbertura.dsnames.ativacao.maxRecSize,
            portal_CRLF: projetoAbertura.dsnames.ativacao.crlf,
            portal_timestampTX: projetoAbertura.dsnames.ativacao.timestampTx,
            portal_timestampTR: projetoAbertura.dsnames.ativacao.timestampRx,
            portal_removeAcentos: projetoAbertura.dsnames.ativacao.removeAcentos,
            portal_insertTimestamp: projetoAbertura.dsnames.ativacao.insertTimestamp,
            portal_insertSeqType: projetoAbertura.dsnames.ativacao.insertSeqType,
            portal_insertSeq: projetoAbertura.dsnames.ativacao.insertSeq,
            portal_insertSeqNumberLen: projetoAbertura.dsnames.ativacao.insertSeqNumberLen,
            portal_insertSeqNumberMax: projetoAbertura.dsnames.ativacao.insertSeqNumberMax,
            portal_insertSeqNumbermin: projetoAbertura.dsnames.ativacao.insertSeqNumberMin,
            portal_mapas: projetoAbertura.dsnames.ativacao.mapas,
            portal_fileRegexEBCDIC: projetoAbertura.dsnames.ativacao.fileRegexEbcdic,
            portal_maxRecSizeEBCDIC: projetoAbertura.dsnames.ativacao.maxRecSizeEbcdic,
            portal_configuradorEnvioDsNameStatus: (projetoAbertura.dsnames.ativacao.configuradorEnvioDsNameStatus) ? projetoAbertura.dsnames.ativacao.configuradorEnvioDsNameStatus.nome : null
        });
    }

    private setStatus(projetoAbertura: ProjetoAberturaConfiguracao['dsnames']) {
        this.remessa_status.data = '';
        this.remessa_status.cor = 'silver';
        this.remessa_status.icone = false;
        this.remessa_status.nome = 'Aguardando configuração';
        this.remessa_status.retorno = '';
        if (projetoAbertura.remessa.id) {
            this._projetoAberturaStatusService.findById(projetoAbertura.remessa.id)
                .subscribe(
                    (data) => {
                        if (!data.data) {
                            this.remessa_status.retorno = '';
                        } else {
                            let pos = data.status.nome.search('erro');
                            let fall = data.status.nome.search('aguardando');
                            if (pos > 0) {
                                this.remessa_status.data = data.data
                                this.remessa_status.retorno = data.retorno;
                                this.remessa_status.cor = 'red';
                                this.remessa_status.nome = data.status.nome;
                            } else if (fall > 0) {
                                this.remessa_status.data = data.data;
                                this.remessa_status.retorno = data.retorno;
                            } else {
                                this.remessa_status.data = data.data
                                this.remessa_status.retorno = data.retorno;
                                this.remessa_status.cor = 'green';
                                this.remessa_status.nome = data.status.nome;
                                this.isViewRemessa = true;
                            }
                        }
                    },
                    (error) => {
                        this.error(error)
                    });
        }

        this.retorno_status.data = '';
        this.retorno_status.cor = 'silver';
        this.retorno_status.icone = false;
        this.retorno_status.nome = 'Aguardando configuração'
        this.retorno_status.retorno = '';
        if (projetoAbertura.retorno.id) {
            this._projetoAberturaStatusService.findById(projetoAbertura.retorno.id)
                .subscribe(
                    (data) => {
                        if (!data.data) {
                            this.retorno_status.data = '';
                            this.retorno_status.retorno = '';
                        } else {
                            let pos = data.status.nome.search('erro');
                            let fall = data.status.nome.search('aguardando');
                            if (pos > 0) {
                                this.retorno_status.data = data.data;
                                this.retorno_status.retorno = data.retorno;
                                this.retorno_status.cor = 'red';
                                this.retorno_status.nome = data.status.nome;
                            } else if (fall > 0) {
                                this.retorno_status.data = data.data;
                                this.retorno_status.retorno = data.retorno;
                            } else {
                                this.retorno_status.data = data.data
                                this.retorno_status.retorno = data.retorno;
                                this.retorno_status.cor = 'green';
                                this.retorno_status.nome = data.status.nome;
                                this.isViewRetorno = true;
                            }
                        }
                    },
                    (error) => {
                        this.error(error)
                    });
        }

        this.portal_status.data = '';
        this.portal_status.cor = 'silver';
        this.portal_status.icone = false;
        this.portal_status.nome = 'Aguardando configuração';
        this.portal_status.retorno = '';
        if (projetoAbertura.ativacao.id) {
            this._projetoAberturaStatusService.findById(projetoAbertura.ativacao.id)
                .subscribe(
                    (data) => {
                        if (!data.data) {
                            this.portal_status.data = '';
                            this.portal_status.retorno = '';
                        } else {
                            let pos = data.status.nome.search('erro');
                            let fall = data.status.nome.search('Aguardando');
                            if (pos > 0) {
                                this.portal_status.data = data.data;
                                this.portal_status.retorno = data.retorno;
                                this.portal_status.cor = 'red';
                                this.portal_status.nome = data.status.nome;
                            } else if (fall == 0) {
                                this.portal_status.data = data.data;
                                this.portal_status.retorno = data.retorno;
                            } else {
                                this.portal_status.data = data.data
                                this.portal_status.retorno = data.retorno;
                                this.portal_status.cor = 'green';
                                this.portal_status.nome = data.status.nome;
                                this.isViewPortal = true;
                            }
                        }
                    },
                    (error) => {
                        this.error(error)
                    });
        }

    }

    protected crateForm(): { [key: string]: any; } {
        return {
            agencia: ['', Validators.required],
            agenciadv: [''],
            conta: ['', Validators.required],
            contadv: [''],
            convenio: [''],

            remessa_idAbertura: ['', Validators.required],
            remessa_layout: ['', Validators.required],
            remessa_custoTrafego: ['', Validators.required],
            remessa_dsName: ['', Validators.required],
            remessa_caixaPostalOrigem: ['', Validators.required],
            remessa_rename: ['', Validators.required],
            remessa_caixaPostalDestino: ['', Validators.required],
            remessa_formato: ['', Validators.required],
            remessa_maxRecSize: [''],
            remessa_CRLF: ['', Validators.required],
            remessa_timestampTX: ['', Validators.required],
            remessa_timestampTR: ['', Validators.required],
            remessa_removeAcentos: ['', Validators.required],
            remessa_insertTimestamp: ['', Validators.required],
            remessa_insertSeqType: ['', Validators.required],
            remessa_insertSeq: ['', Validators.required],
            remessa_insertSeqNumberLen: [''],
            remessa_insertSeqNumberMax: [''],
            remessa_insertSeqNumbermin: [''],
            remessa_mapas: ['', Validators.required],
            remessa_fileRegexEBCDIC: [''],
            remessa_maxRecSizeEBCDIC: [''],
            remessa_configuradorEnvioDsNameStatus: [''],

            retorno_idAbertura: ['', Validators.required],
            retorno_layout: ['', Validators.required],
            retorno_custoTrafego: ['', Validators.required],
            retorno_Dsname: ['', Validators.required],
            retorno_caixaPostalOrigem: ['', Validators.required],
            retorno_rename: ['', Validators.required],
            retorno_caixaPostalDestino: ['', Validators.required],
            retorno_formato: ['', Validators.required],
            retorno_maxRecSize: [''],
            retorno_CRLF: ['', Validators.required],
            retorno_timestampTX: ['', Validators.required],
            retorno_timestampTR: ['', Validators.required],
            retorno_removeAcentos: ['', Validators.required],
            retorno_insertTimestamp: ['', Validators.required],
            retorno_insertSeqType: ['', Validators.required],
            retorno_insertSeq: ['', Validators.required],
            retorno_insertSeqNumberLen: [''],
            retorno_insertSeqNumberMax: [''],
            retorno_insertSeqNumbermin: [''],
            retorno_mapas: ['', Validators.required],
            retorno_fileRegexEBCDIC: [''],
            retorno_maxRecSizeEBCDIC: [''],
            retorno_configuradorEnvioDsNameStatus: [''],

            portal_idAbertura: ['', Validators.required],
            portal_layout: ['', Validators.required],
            portal_custoTrafego: ['', Validators.required],
            portal_Dsname: ['', Validators.required],
            portal_caixaPostalOrigem: ['', Validators.required],
            portal_rename: ['', Validators.required],
            portal_caixaPostalDestino: ['', Validators.required],
            portal_formato: ['', Validators.required],
            portal_maxRecSize: [''],
            portal_CRLF: ['', Validators.required],
            portal_timestampTX: ['', Validators.required],
            portal_timestampTR: ['', Validators.required],
            portal_removeAcentos: ['', Validators.required],
            portal_insertTimestamp: ['', Validators.required],
            portal_insertSeqType: ['', Validators.required],
            portal_insertSeq: ['', Validators.required],
            portal_insertSeqNumberLen: [''],
            portal_insertSeqNumberMax: [''],
            portal_insertSeqNumbermin: [''],
            portal_mapas: ['', Validators.required],
            portal_fileRegexEBCDIC: ['', Validators.required],
            portal_maxRecSizeEBCDIC: [''],
            portal_configuradorEnvioDsNameStatus: [''],
        }
    }

    salvar() {
        const produtoFinanceiroId = this.projetoAbertura &&
            this.projetoAbertura.produtoFinanceiro &&
            this.projetoAbertura.produtoFinanceiro.id ?
            this.projetoAbertura.produtoFinanceiro.id :
            undefined;

        const empresaId = this.projetoAbertura &&
            this.projetoAbertura.empresaId ?
            this.projetoAbertura.empresaId :
            undefined;

        const agencia = this.form.get('agencia').value;
        const agenciaDV = this.form.get('agenciadv').value;
        const conta = this.form.get('conta').value;
        const contaDV = this.form.get('contadv').value;
        const convenio = this.form.get('convenio').value;

        const remessa_layout = this.form.get('remessa_layout').value;
        const retorno_layout = this.form.get('retorno_layout').value;
        const portal_layout = this.form.get('portal_layout').value;

        const configuracao: ProjetoAberturaData = {
            agencia,
            agenciaDV,
            conta,
            contaDV,
            convenio,
            produtoFinanceiroId: produtoFinanceiroId,
            empresaId: empresaId,
            configurador: true,
            configuracao: {
                layoutRemessaId: remessa_layout,
                layoutRetornoId: retorno_layout,
                layoutAtivacaoId: portal_layout,
                dsnames: this.createDNSObject(remessa_layout, retorno_layout, portal_layout)
            }
        };

        let remessa_mapas: string = null;
        let retorno_mapas: string = null;
        let ativacao_mapas: string = null;
        let validateRemessaMap = true;
        let validateRetornoMap = true;
        let validateAtivacaoMap = true;

        if (configuracao.configuracao &&
            configuracao.configuracao.dsnames) {

            if (configuracao.configuracao.dsnames.remessa &&
                configuracao.configuracao.dsnames.remessa.mapas != null &&
                configuracao.configuracao.dsnames.remessa.mapas != undefined) {
                remessa_mapas = configuracao.configuracao.dsnames.remessa.mapas;
            }

            if (configuracao.configuracao.dsnames.retorno &&
                configuracao.configuracao.dsnames.retorno.mapas != null &&
                configuracao.configuracao.dsnames.retorno.mapas != undefined) {
                retorno_mapas = configuracao.configuracao.dsnames.retorno.mapas;
            }

            if (configuracao.configuracao.dsnames.ativacao &&
                configuracao.configuracao.dsnames.ativacao.mapas != null &&
                configuracao.configuracao.dsnames.ativacao.mapas != undefined) {
                ativacao_mapas = configuracao.configuracao.dsnames.ativacao.mapas;
            }
        }

        if (remessa_mapas != null) {
            this._projetoAberturaService.validarMapa(remessa_mapas).subscribe(
                (data: any) => {
                    this.sucess(data.message);
                },
                (error) => {
                    error.message += ' da remesa'
                    this.error(error)
                    validateRemessaMap = false;
                }
            );
        }

        if (retorno_mapas != null) {
            this._projetoAberturaService.validarMapa(retorno_mapas).subscribe(
                (data: any) => {
                    this.sucess(data.message);
                },
                (error) => {
                    error.message += ' da retorno'
                    this.error(error)
                    validateRetornoMap = false;
                }
            );
        }

        if (ativacao_mapas != null) {
            this._projetoAberturaService.validarMapa(ativacao_mapas).subscribe(
                (data: any) => {
                    this.sucess(data.message);
                },
                (error) => {
                    error.message += ' do portal'
                    this.error(error)
                    validateAtivacaoMap = false;
                }
            );
        }

        if (validateRemessaMap &&
            validateRetornoMap &&
            validateAtivacaoMap) {
            let id = this._route.snapshot.paramMap.get('id');
            this._projetoAberturaService.update(id, configuracao).subscribe(
                () => {
                    this.sucess('Abertura salva com sucesso!');
                    this.aberturaSalva = true;
                },
                (error) => this.error(error)
            );
        }
    }

    openErros(id: number, arg: number) {

        let obj: ProjetoAberturaStatusConfiguracao = {
            idDNSAbertura: id,
            data: '',
            status: {
                id: 0,
                nome: '',
                cor: '',
                icone: ''
            },
            payload: '',
            retorno: ''
        };
        if (arg == 1) {
            obj.data = this.remessa_status.data;
            obj.status.id = this.remessa_status.id;
            obj.status.nome = this.remessa_status.nome;
            obj.status.cor = this.remessa_status.cor;
            obj.status.icone = this.remessa_status.icone;
            obj.retorno = this.remessa_status.retorno;
        }
        if (arg == 2) {
            obj.data = this.retorno_status.data;
            obj.status.id = this.retorno_status.id;
            obj.status.nome = this.retorno_status.nome;
            obj.status.cor = this.retorno_status.cor;
            obj.status.icone = this.retorno_status.icone;
            obj.retorno = this.retorno_status.retorno;
        }
        if (arg == 3) {
            obj.data = this.portal_status.data;
            obj.status.id = this.portal_status.id;
            obj.status.nome = this.portal_status.nome;
            obj.status.cor = this.portal_status.cor;
            obj.status.icone = this.portal_status.icone;
            obj.retorno = this.portal_status.retorno;
        }
        let arr = [];
        arr.push(obj);

        this._projetoModalService.statusProjetoAbertura(arr).subscribe(data => { });
    }

    openModal(arg: number, direcao: number) {
        let typeCampo = '';

        if (arg == 1) {
            typeCampo = (direcao == 1) ? 'DsnameRemessa' : 'RenameRemessa';
        }
        if (arg == 2) {
            typeCampo = (direcao == 1) ? 'DsnameRetorno' : 'RenameRetorno';
        }
        if (arg == 3) {
            typeCampo = (direcao == 1) ? 'DsnameAtivacao' : 'RenameAtivacao';
        }

        if (typeCampo != '') {
            let caixaPostalLista = { aberturaId: this._route.snapshot.paramMap.get('id'), direcaoId: arg, campo: typeCampo };
            this._projetoModalService.caixaPostaProjetoAbertura(caixaPostalLista).subscribe(data => {
                if (data) {
                    if (arg == 1) {
                        if (direcao == 1) {
                            this.form.get('remessa_caixaPostalOrigem').setValue(data.codigo);
                            this.remessa_caixaPostalOrigemId = data.id;
                        } else {
                            this.form.get('remessa_caixaPostalDestino').setValue(data.codigo);
                            this.remessa_caixaPostalDestinoId = data.id;
                        }
                    }

                    if (arg == 2) {
                        if (direcao == 1) {
                            this.form.get('retorno_caixaPostalOrigem').setValue(data.codigo);
                            this.retorno_caixaPostalOrigemId = data.id;
                        } else {
                            this.form.get('retorno_caixaPostalDestino').setValue(data.codigo);
                            this.retorno_caixaPostalDestinoId = data.id;
                        }
                    }

                    if (arg == 3) {
                        if (direcao == 1) {
                            this.form.get('portal_caixaPostalOrigem').setValue(data.codigo);
                            this.portal_caixaPostalOrigemId = data.id;
                        } else {
                            this.form.get('portal_caixaPostalDestino').setValue(data.codigo);
                            this.portal_caixaPostalDestinoId = data.id;
                        }
                    }
                }
            });
        } else {
            this._notification.error('Não foi póssivel identificar o tipo de caixa postal salve e atualize a pagina');
        }

    }

    private createDNSObject(remessa_layout: any, retorno_layout: any, portal_layout: any) {

        const remessa_idAbertura = this.form.get('remessa_idAbertura').value;
        const remessa_custoTrafego = this.form.get('remessa_custoTrafego').value;
        const remessa_Dsname = this.form.get('remessa_dsName').value;
        const remessa_caixaPostalOrigem = this.form.get('remessa_caixaPostalOrigem').value;
        const remessa_rename = this.form.get('remessa_rename').value;
        const remessa_caixaPostalDestino = this.form.get('remessa_caixaPostalDestino').value;
        const remessa_formato = this.form.get('remessa_formato').value;
        const remessa_maxRecSize = this.form.get('remessa_maxRecSize').value;
        const remessa_CRLF = this.form.get('remessa_CRLF').value;
        const remessa_timestampTX = this.form.get('remessa_timestampTX').value;
        const remessa_timestampTR = this.form.get('remessa_timestampTR').value;
        const remessa_removeAcentos = this.form.get('remessa_removeAcentos').value;
        const remessa_insertTimestamp = this.form.get('remessa_insertTimestamp').value;
        const remessa_insertSeqType = this.form.get('remessa_insertSeqType').value;
        const remessa_insertSeq = this.form.get('remessa_insertSeq').value;
        const remessa_insertSeqNumberLen = this.form.get('remessa_insertSeqNumberLen').value;
        const remessa_insertSeqNumberMax = this.form.get('remessa_insertSeqNumberMax').value;
        const remessa_insertSeqNumbermin = this.form.get('remessa_insertSeqNumbermin').value;
        const remessa_mapas = this.form.get('remessa_mapas').value;
        const remessa_fileRegexEBCDIC = this.form.get('remessa_fileRegexEBCDIC').value;
        const remessa_maxRecSizeEBCDIC = this.form.get('remessa_maxRecSizeEBCDIC').value;

        const retorno_idAbertura = this.form.get('retorno_idAbertura').value;
        const retorno_custoTrafego = this.form.get('retorno_custoTrafego').value;
        const retorno_Dsname = this.form.get('retorno_Dsname').value;
        const retorno_caixaPostalOrigem = this.form.get('retorno_caixaPostalOrigem').value;
        const retorno_rename = this.form.get('retorno_rename').value;
        const retorno_caixaPostalDestino = this.form.get('retorno_caixaPostalDestino').value;
        const retorno_formato = this.form.get('retorno_formato').value;
        const retorno_maxRecSize = this.form.get('retorno_maxRecSize').value;
        const retorno_CRLF = this.form.get('retorno_CRLF').value;
        const retorno_timestampTX = this.form.get('retorno_timestampTX').value;
        const retorno_timestampTR = this.form.get('retorno_timestampTR').value;
        const retorno_removeAcentos = this.form.get('retorno_removeAcentos').value;
        const retorno_insertTimestamp = this.form.get('retorno_insertTimestamp').value;
        const retorno_InsertSeqType = this.form.get('retorno_insertSeqType').value;
        const retorno_insertSeq = this.form.get('retorno_insertSeq').value;
        const retorno_insertSeqNumberLen = this.form.get('retorno_insertSeqNumberLen').value;
        const retorno_insertSeqNumberMax = this.form.get('retorno_insertSeqNumberMax').value;
        const retorno_insertSeqNumbermin = this.form.get('retorno_insertSeqNumbermin').value;
        const retorno_mapas = this.form.get('retorno_mapas').value;
        const retorno_fileRegexEBCDIC = this.form.get('retorno_fileRegexEBCDIC').value;
        const retorno_maxRecSizeEBCDIC = this.form.get('retorno_maxRecSizeEBCDIC').value;

        const portal_idAbertura = this.form.get('portal_idAbertura').value;
        const portal_custoTrafego = this.form.get('portal_custoTrafego').value;
        const portal_Dsname = this.form.get('portal_Dsname').value;
        const portal_caixaPostalOrigem = this.form.get('portal_caixaPostalOrigem').value;
        const portal_rename = this.form.get('portal_rename').value;
        const portal_caixaPostalDestino = this.form.get('portal_caixaPostalDestino').value;
        const portal_formato = this.form.get('portal_formato').value;
        const portal_maxRecSize = this.form.get('portal_maxRecSize').value;
        const portal_CRLF = this.form.get('portal_CRLF').value;
        const portal_timestampTX = this.form.get('portal_timestampTX').value;
        const portal_timestampTR = this.form.get('portal_timestampTR').value;
        const portal_removeAcentos = this.form.get('portal_removeAcentos').value;
        const portal_insertTimestamp = this.form.get('portal_insertTimestamp').value;
        const portal_insertSeqType = this.form.get('portal_insertSeqType').value;
        const portal_insertSeq = this.form.get('portal_insertSeq').value;
        const portal_insertSeqNumberLen = this.form.get('portal_insertSeqNumberLen').value;
        const portal_insertSeqNumberMax = this.form.get('portal_insertSeqNumberMax').value;
        const portal_insertSeqNumbermin = this.form.get('portal_insertSeqNumbermin').value;
        const portal_mapas = this.form.get('portal_mapas').value;
        const portal_fileRegexEBCDIC = this.form.get('portal_fileRegexEBCDIC').value;
        const portal_maxRecSizeEBCDIC = this.form.get('portal_maxRecSizeEBCDIC').value;


        let dsname = new Object;
        if (remessa_layout != undefined && remessa_layout != '') {
            dsname['remessa'] = {
                id: (remessa_idAbertura) ? remessa_idAbertura : null,
                custoTrafego: remessa_custoTrafego,
                dsname: remessa_Dsname,
                cxpIdOrigem: this.remessa_caixaPostalOrigemId,
                rename: remessa_rename,
                cxpIdDestino: this.remessa_caixaPostalDestinoId,
                formato: (remessa_formato.id) ? remessa_formato.id : (isNumber(remessa_formato)) ? remessa_formato : null,
                maxRecSize: parseInt(remessa_maxRecSize).valueOf(),
                crlf: (remessa_CRLF.id) ? remessa_CRLF.id : (remessa_CRLF) ? remessa_CRLF : null,
                timestampRx: remessa_timestampTR,
                timestampTx: remessa_timestampTX,
                removeAcentos: (remessa_removeAcentos.id) ? remessa_removeAcentos.id : (remessa_removeAcentos) ? remessa_removeAcentos : null,
                insertTimestamp: (remessa_insertTimestamp.id) ? remessa_insertTimestamp.id : (remessa_insertTimestamp) ? remessa_insertTimestamp : null,
                insertSeqType: (remessa_insertSeqType.id) ? remessa_insertSeqType.id : (remessa_insertSeqType) ? remessa_insertSeqType : null,
                insertSeq: (remessa_insertSeq.id) ? remessa_insertSeq.id : (remessa_insertSeq) ? remessa_insertSeq : null,
                insertSeqNumberLen: parseInt(remessa_insertSeqNumberLen).valueOf(),
                insertSeqNumberMax: parseInt(remessa_insertSeqNumberMax).valueOf(),
                insertSeqNumberMin: parseInt(remessa_insertSeqNumbermin).valueOf(),
                mapas: remessa_mapas,
                fileRegexEbcdic: remessa_fileRegexEBCDIC,
                maxRecSizeEbcdic: parseInt(remessa_maxRecSizeEBCDIC).valueOf()
            };
        }

        if (retorno_layout != undefined && retorno_layout != '') {
            dsname['retorno'] = {
                id: (retorno_idAbertura) ? retorno_idAbertura : null,
                custoTrafego: retorno_custoTrafego,
                dsname: retorno_Dsname,
                cxpIdOrigem: this.retorno_caixaPostalOrigemId,
                rename: retorno_rename,
                cxpIdDestino: this.retorno_caixaPostalDestinoId,
                formato: (retorno_formato.id) ? retorno_formato.id : (isNumber(retorno_formato)) ? retorno_formato : null,
                maxRecSize: retorno_maxRecSize,
                crlf: (retorno_CRLF.id) ? retorno_CRLF.id : (retorno_CRLF) ? retorno_CRLF : null,
                timestampRx: retorno_timestampTR,
                timestampTx: retorno_timestampTX,
                removeAcentos: (retorno_removeAcentos.id) ? retorno_removeAcentos.id : (retorno_removeAcentos) ? retorno_removeAcentos : null,
                insertTimestamp: (retorno_insertTimestamp.id) ? retorno_insertTimestamp.id : (retorno_insertTimestamp) ? retorno_insertTimestamp : null,
                insertSeqType: (retorno_InsertSeqType.id) ? retorno_InsertSeqType.id : (retorno_InsertSeqType) ? retorno_InsertSeqType : null,
                insertSeq: (retorno_insertSeq.id) ? retorno_insertSeq.id : (retorno_insertSeq) ? retorno_insertSeq : null,
                insertSeqNumberLen: retorno_insertSeqNumberLen,
                insertSeqNumberMax: retorno_insertSeqNumberMax,
                insertSeqNumberMin: retorno_insertSeqNumbermin,
                mapas: retorno_mapas,
                fileRegexEbcdic: retorno_fileRegexEBCDIC,
                maxRecSizeEbcdic: (retorno_maxRecSizeEBCDIC) ? parseInt(retorno_maxRecSizeEBCDIC) : null
            }
        }

        if (portal_layout != undefined && portal_layout != '') {
            dsname['ativacao'] = {
                id: (portal_idAbertura) ? portal_idAbertura : null,
                custoTrafego: portal_custoTrafego,
                dsname: portal_Dsname,
                cxpIdOrigem: this.portal_caixaPostalOrigemId,
                rename: portal_rename,
                cxpIdDestino: this.portal_caixaPostalDestinoId,
                formato: (portal_formato.id) ? portal_formato.id : (isNumber(portal_formato)) ? portal_formato : null,
                maxRecSize: portal_maxRecSize,
                crlf: (portal_CRLF.id) ? portal_CRLF.id : (portal_CRLF) ? portal_CRLF : null,
                timestampRx: portal_timestampTR,
                timestampTx: portal_timestampTX,
                removeAcentos: (portal_removeAcentos.id) ? portal_removeAcentos.id : (portal_removeAcentos) ? portal_removeAcentos : null,
                insertTimestamp: (portal_insertTimestamp.id) ? portal_insertTimestamp.id : (portal_insertTimestamp) ? portal_insertTimestamp : null,
                insertSeqType: (portal_insertSeqType && portal_insertSeqType.id) ? portal_insertSeqType.id : null,
                insertSeq: (portal_insertSeq && portal_insertSeq.id) ? portal_insertSeq.id : null,
                insertSeqNumberLen: portal_insertSeqNumberLen,
                insertSeqNumberMax: portal_insertSeqNumberMax,
                insertSeqNumberMin: portal_insertSeqNumbermin,
                mapas: portal_mapas,
                fileRegexEbcdic: portal_fileRegexEBCDIC,
                maxRecSizeEbcdic: (portal_maxRecSizeEBCDIC) ? parseInt(portal_maxRecSizeEBCDIC) : null
            }
        }

        return dsname;
    }

    voltar() {
        this._location.back();
    }

    private loadLayoutPortal(idFinanceiro: number, projetoAbertura: ProjetoAberturaConfiguracao) {
        this._layoutService
            .find({
                produtoFinanceiro: idFinanceiro,
            })
            .subscribe(
                (result) => {
                    this.optionList.listaLayouts = result.data;
                    this.refreshOptionsConfig();
                    this.form.patchValue({
                        'remessa_layout': projetoAbertura.layoutRemessaId,
                        'retorno_layout': projetoAbertura.layoutRetornoId,
                        'portal_layout': projetoAbertura.layoutAtivacaoId
                    })
                },
                (error) => {
                    this.error(error);
                }
            );
    }

    configurar(type: number) {
        const remessa_layout = this.form.get('remessa_layout').value;
        const retorno_layout = this.form.get('retorno_layout').value;
        const portal_layout = this.form.get('portal_layout').value;
        let dnsObject = this.createDNSObject(remessa_layout, retorno_layout, portal_layout);
        let id = this._route.snapshot.paramMap.get('id');
        let config = { abertura_id: id };
        if (type == 1) {
            config['remessa_id'] = dnsObject['remessa']['id'];
        } else if (type == 2) {
            config['retorno_id'] = dnsObject['retorno']['id'];
        } else if (type == 3) {
            config['portal_id'] = dnsObject['ativacao']['id'];
        }
        this._projectoAberturaConfiguracaoService.configuracaoItem(config, type)
            .subscribe(
                (data) => {
                    this.sucess(data['message']);
                    if (type == 1) {
                        this.showButtonRemessaConfigurarDisabled = true;
                    } else if (type == 2) {
                        this.showButtonRetornoConfigurarDisabled = true;
                    } else if (type == 3) {
                        this.showButtonPortalConfigurarDisabled = true;
                    }
                },
                (error) => {
                    this.error(error);
                });
    }

    selectedOption(o1: any, o2: any) {
        return o1.id == o2.id;
    }
}

export class ProjetoAberturaCadastroCombos {
    static combos() {
        return [
            {
                listName: 'listaLayouts',
                filterName: 'filterLayouts',
                fieldValue: 'descricao',
            },
            {
                listName: 'listaCustoTrafego',
                filterName: 'filterCustoTrafego',
                fieldValue: 'descricao',
            },
            {
                listName: 'listaFormatos',
                filterName: 'filterFormato',
                fieldValue: 'descricao',
            },
            {
                listName: 'listaTimestampTX',
                filterName: 'filterTimestampTX',
                fieldValue: 'descricao',
            },
            {
                listName: 'listaTimestampTR',
                filterName: 'filterTimestampTR',
                fieldValue: 'descricao',
            },
            {
                listName: 'listaInsertTimestamp',
                filterName: 'filterInsertTimestamp',
                fieldValue: 'descricao',
            },
            {
                listName: 'listaInsertSeq',
                filterName: 'filterInsertSeq',
                fieldValue: 'descricao',
            },

            {
                listName: 'listaInsertSeqType',
                filterName: 'filterInsertSeqType',
                fieldValue: 'descricao',
            },
            {
                listName: 'listaCRLF',
                filterName: 'filterCRLF',
                fieldValue: 'descricao',
            },
            {
                listName: 'listaRemoveAcentos',
                filterName: 'filterRemoveAcentos',
                fieldValue: 'descricao',
            },
        ];
    }
}
