import { Component, Injector, OnInit } from '@angular/core';
import { FormBase } from '@utils/base/form-base/form-base';
import { SimNao } from '@utils/interfaces/shareds/sim-nao.interface';
import { DataUtil } from '@utils/utils/class/data.util';
import { ChecklistConfiguracaoLayout } from 'app/main/checklist/shared/interfaces/checklist-configuracao.interface';
import { Direcao } from 'app/main/checklist/shared/interfaces/direcao.interface';
import { TimeModalService } from 'app/main/time/shareds/services/time-modal.service';
import { UsuarioModalService } from 'app/main/usuario/shared/services/usuario-modal.service';
import { environment } from 'environments/environment';
import { FiltroProjetoAbertura } from '../../shareds/interfaces/filtro-projeto-abertura.interface';
import { ProjetoProcessoStatus } from '../../shareds/interfaces/projeto-processo-status.interface';
import { ProjetoStatus } from '../../shareds/interfaces/projeto-status.interface';
import {
    Projeto,
    ProjetoRequest,
} from '../../shareds/interfaces/projeto.interface';
import { StatusReport } from '../../shareds/interfaces/status-report';
import { ProjetoService } from '../../shareds/services/projeto.service';
import { ProjetoModalService } from '../../shareds/services/projeto-modal.service';

@Component({
    selector: 'projeto-cadastro',
    templateUrl: './projeto-cadastro.component.html',
    styleUrls: ['./projeto-cadastro.component.scss'],
})
export class ProjetoCadastroComponent extends FormBase implements OnInit {
    filtro: FiltroProjetoAbertura = {};
    projeto: Projeto = {
        adquirentes: undefined,
        projetoDesvioCronogramaFinnet: false
    };
    direcoes: Array<Direcao> = [];
    dsName: ChecklistConfiguracaoLayout = {
        checklistConfiguracaoDsname: [],
    };
    isView = false;
    statuss: Array<ProjetoStatus> = [];
    adquirentes: any;
    listaStatusReport: Array<StatusReport> = [];
    listaSimNao: Array<SimNao> = [];
    listaProcessoStatus: Array<ProjetoProcessoStatus> = [];

    constructor(
        private _projetoService: ProjetoService,
        private _modalUsuario: UsuarioModalService,
        private _modalTime: TimeModalService,
        private _modalArquivo: ProjetoModalService,
        private _injector: Injector
    ) {
        super(_injector);
    }

    ngOnInit(): void {
        if (
            /\/projeto\/visualizar\/[0-9]+/.test(
                this._route['_routerState'].snapshot.url
            )
        ) {
            this.isView = true;
        }
        this._route.data.subscribe((data) => {
            this.projeto = data.projeto;
            this.adquirentes = this.projeto.adquirentes;
            this.direcoes = data.direcoes;
            this.statuss = data.statuss;
            this.listaStatusReport = data.listaStatusReport;
            this.listaSimNao = data.listaSimNao;
            this.listaProcessoStatus = data.listaProcessoStatus;

            this.setForm(data.projeto);
        });

        if (this.projeto) {
            this.dsName = this.projeto.dsName.find(
                (d) => d.moduloId === this.projeto.modulo.id
            ) || { checklistConfiguracaoDsname: [] };
        }
    }

    protected crateForm(): { [key: string]: any; } {
        return {
            projetoStatus: [],
            dataPlanejadaInicio: [],
            dataPlanejadaFim: [],
            dataRealInicio: [],
            dataRealFim: [],
            statusReport: [],
            projetoFaturado: [],
            projetoProcessoStatus: [],
            projetoDesvioCronogramaFinnet: false
        };
    }

    filtrar(filtro: FiltroProjetoAbertura): void {
        this.filtro = Object.assign({}, filtro);
    }

    pesquisaUsuario(): void {
        this._modalUsuario.usuarioPesquisa({}).subscribe((usuario) => {
            this.projeto.usuario = usuario;
        });
    }

    limparUsuario(): void {
        this.projeto.usuario = {};
    }

    pesquisaTime(): void {
        this._modalTime.timePesquisa({}).subscribe((time) => {
            this.projeto.time = time;
        });
    }

    limparTime(): void {
        this.projeto.time = {};
    }

    salvar(): void {
        const projetoStatus = this.form.get('projetoStatus').value;
        const dataPlanejadaInicio = this.form.get('dataPlanejadaInicio').value;
        const dataPlanejadaFim = this.form.get('dataPlanejadaFim').value;

        const dataRealInicio = this.form.get('dataRealInicio').value;
        const dataRealFim = this.form.get('dataRealFim').value;

        const statusReport = this.form.get('statusReport').value;
        const projetoFaturado = this.form.get('projetoFaturado').value;

        const projetoProcessoStatus = this.form.get('projetoProcessoStatus').value;

        this.projeto.projetoStatus = projetoStatus;
        this._spinner.show();
        const projeto: ProjetoRequest = {
            id: this.projeto.id,
            idStatus: projetoStatus.id,
            idProcessoStatus: projetoProcessoStatus.id,
            idUsuario: this.projeto.usuario.id ? this.projeto.usuario.id : null,
            idTime: this.projeto.time.id ? this.projeto.time.id : null,
            idStatusReport: statusReport.id,
            dataPlanejadaInicio: dataPlanejadaInicio ? DataUtil.toDateBackend(dataPlanejadaInicio) : undefined,
            dataPlanejadaFim: dataPlanejadaFim ? DataUtil.toDateBackend(dataPlanejadaFim) : undefined,
            dataRealInicio: dataRealInicio ? DataUtil.toDateBackend(dataRealInicio) : undefined,
            dataRealFim: dataRealFim ? DataUtil.toDateBackend(dataRealFim) : undefined,
            faturado: projetoFaturado.codigo ? parseInt(projetoFaturado.codigo) : undefined,
            projetoDesvioCronogramaFinnet: this.projeto.projetoDesvioCronogramaFinnet
        };
        this._projetoService.update(projeto).subscribe(
            () => {
                this._spinner.hide();
                this._notification.sucess('Projeto salvo com sucesso!');
            },
            (error) => this.error(error)
        );
    }

    modalArquivo(): void {
        this._modalArquivo.download(this.projeto);
    }

    visualizarCotacao(idCotacao): void {
        const link = `${environment.host}/cotacao/${idCotacao}`;
        this._router.navigate([]).then((result) => {
            window.open(link, '_blank');
        });
    }

    visualizarChecklist(idChecklist): void {
        const link = `${environment.host}/checklist/${idChecklist}/visualizar`;
        this._router.navigate([]).then((result) => {
            window.open(link, '_blank');
        });
    }

    getNomeDirecao(idDirecao): string {
        let retorno = '';
        if (this.direcoes) {
            const direcao = this.direcoes.find((d) => d.id === idDirecao);
            retorno = direcao.nome || '';
        }
        return retorno;
    }

    getNomeDsname(dsnames: Array<any>): string {
        let nome = '';
        dsnames.forEach((d) => (nome += d.dsnameCampo.exemplo));
        return nome;
    }

    selectedOptionId(o1: any, o2: any): boolean {
        return o1.id == o2.id;
    }

    setForm(projeto: Projeto): void {
        const faturado = projeto.faturado ? this.listaSimNao.find(result => result.codigo === "1") : this.listaSimNao.find(result => result.codigo === "0")
        this.form.patchValue({
            projetoStatus: projeto.projetoStatus,
            dataPlanejadaInicio: projeto.dataPlanejadaInicio,
            dataPlanejadaFim: projeto.dataPlanejadaFim,
            dataRealInicio: projeto.dataRealInicio,
            dataRealFim: projeto.dataRealFim,
            statusReport: projeto.projetoStatusReport,
            projetoFaturado: faturado,
            projetoProcessoStatus: projeto.projetoProcessoStatus,
            projetoDesvioCronogramaFinnet: projeto.projetoDesvioCronogramaFinnet
        });
    }

    selecionaDesvioCronogramaFinnet(check): void {
        this.projeto.projetoDesvioCronogramaFinnet = check;
        if (!check) {
            this.form.get('projetoDesvioCronogramaFinnet').setValue(false);
        }
    }

    copyDsName(textDsName: string): void {
        navigator.clipboard.writeText(textDsName);
        this._notification.sucess('DsName copiado para área de transferencia com sucesso');
    }
}
