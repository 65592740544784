import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-bi-kpi-dev-team',
    templateUrl: './bi-kpi-dev-team.component.html',
    styleUrls: ['./bi-kpi-dev-team.component.scss']
})
export class BiKpiDevTeamComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
