export const environment = {
    production: false,
    hmr: false,
    homol: false,
    dev: false,
    preprod: true,

    host: 'https://preprod.projectfinnet.com.br/#',
    server: 'https://api-preprod.projectfinnet.com.br',
    websocket: 'http://api-dev.projectfinnet.com.br:6001',
    isRecaptcha: true,
    recaptchaKey: '6LdasOMZAAAAADSbn7bOp5NTlgC61PcNAIDj8KCz',
};
