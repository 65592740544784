import { Component, Injector, Input, OnInit } from '@angular/core';
import { ComponentBase } from '@utils/base/component-base/component-base';
import { Checklist } from '../../shared/interfaces/checklist.interface';
import { ChecklistService } from '../../shared/services/checklist.service';

@Component({
    selector: 'checklist-confirmacao-checklist',
    templateUrl: './checklist-confirmacao-checklist.component.html',
    styleUrls: ['./checklist-confirmacao-checklist.component.scss'],
})
export class ChecklistConfirmacaoChecklistComponent extends ComponentBase implements OnInit {
    @Input() checklist: Checklist;

    @Input() validate: (valid: boolean) => void;

    firstTime: boolean = true;
    constructor(
        private _checklistService: ChecklistService,
        private _inject: Injector,
    ) {
        super(_inject);
    }

    ngOnInit(): void { }

    ngOnChanges({ validate }) {
        if (this.firstTime) {
            this.firstTime = false;
            return;
        }
        const valid = true
        validate.currentValue(valid)
    }


    concluir(): void {
        this._confirmation
            .confirm({
                titulo: 'Deseja finalizar o checklist?',
                mensagem:
                    'Ao finalizar o checklist, o mesmo não poderá ser mais alterado!',
            })
            .subscribe((result) => {
                if (result) {
                    this._spinner.show();
                    this._checklistService
                        .finalizar(this.checklist.id)
                        .subscribe(
                            () => {
                                this._spinner.hide();
                                this._notification.sucess(
                                    'Checklist finalizado com sucesso!'
                                );
                                this._router.navigate(['checklist']);
                            },
                            (error) => this.error(error));
                }
            });
    }
}
