export default {
    emailConfirmacao: {
        titulo: 'Confirme seu endereço de e-mail!',
        texto: `
            <p>
                Um e-mail de confirmação foi enviado para <b>{#!EMAIL}</b>.
            </p>
            <p>
                Verifique sua caixa de entrada e clique no link "Confirmar meu e-mail" para confirmar seu endereço de e-mail.
            </p>
        `,
    },
    emailRecuperacaoSenha: {
        titulo: 'Recuperação de senha!',
        texto: `
            <p>
                Um e-mail com os dados para a recuperação de senha foi enviado para <b>{#!EMAIL}</b>.
            </p>
            <p>
            Verifique sua caixa de entrada e siga as instruções para rescuperar a sua senha.
            </p>
        `,
    },
    emailRedefinicaoSenha: {
        titulo: 'Redefinição de senha!',
        texto: `
            <p>
                Um e-mail com os dados para a redefinição de senha foi enviado para <b>{#!EMAIL}</b>.
            </p>
            <p>
            Verifique sua caixa de entrada e siga as instruções para redefinir a sua senha.
            </p>
        `,
    },
};
