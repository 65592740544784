import { FormGroup, Validators } from '@angular/forms';
import { OnInit, ViewEncapsulation, Component, Injector } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { SpinnerService } from '@utils/utils/spinner/spinner.service';
import { AuthBaseController } from '../../../shared/auth-base.controller';
import { environment } from 'environments/environment';
import { v4 as uuid } from 'uuid';

@Component({
    selector: 'login-admin-center',
    templateUrl: './login-admin-center.component.html',
    styleUrls: ['./login-admin-center.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class LoginAdminCenterComponent
    extends AuthBaseController
    implements OnInit {
    loginForm: FormGroup;
    erro: string = null;
    recaptcha: any[];
    recaptchaKey: string;
    csrfToken: string;

    constructor(protected _injector: Injector) {
        super(_injector);
        this.recaptchaKey = environment.recaptchaKey;
    }

    ngOnInit(): void {
        this.csrfToken = uuid();
        this.loginForm = this._formBuilder.group({
            usuario: ['', [Validators.required]],
            password: ['', Validators.required],
        });
    }

    logar(): void {
        if (this.recaptchaKey) {
            this._spinner.show();
            this._authService.login({
                usuario: this.loginForm.get('usuario').value,
                senha: this.loginForm.get('password').value,
                recaptcha: this.recaptcha,
            });
        }
    }

    recaptchaResolved(captchaResponse: any[]): void {
        this.recaptcha = captchaResponse;
    }
}
