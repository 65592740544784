import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Gmud } from '../../shared/interfaces/gmud.interface';
import { ComponentBase } from '@utils/base/component-base/component-base';

@Component({
    selector: 'gmud-cadastro',
    templateUrl: './gmud-cadastro.component.html',
    styleUrls: ['./gmud-cadastro.component.scss']
})
export class GmudCadastroComponent extends ComponentBase implements OnInit {
    @Output() modal = new EventEmitter();

    visualizacao: boolean;
    menuSelecionado: string;
    menuDesabilitado = {
        gmud: false,
        documento: true,
        recomendacoes: true,
        historico: true
    }

    constructor(
        protected _injector: Injector,
        private _routerr: ActivatedRoute,
    ) {
        super(_injector)
    }

    ngOnInit() {
        this.visualizacao = !!this._routerr.snapshot.routeConfig.path.includes('visualizacao')
        this._routerr.data.subscribe(data => {
            const idGmud = this._routerr.snapshot.params.id;

            if (idGmud) {
                this.menuDesabilitado.documento = false;
                this.menuDesabilitado.recomendacoes = false;
                this.menuDesabilitado.historico = false;
            }

            // if (data.gmud) {
            //     const gmud: Gmud = data.gmud

            //     if (gmud.impStatus.id === 5) {
            //         this.menuDesabilitado.gmud = false;
            //     }
            // }

            // if (data.gmudFluxo) {
            //     const gmudFluxo = data.gmudFluxo
            // }
        })

        this.selecionar('GMUD');
    }

    selecionar(menu: string) {
        this.menuSelecionado = menu;
    }

    voltar() {
        if (this.visualizacao) {
            return this.modal.emit(true)
        }

        this._confirmation
            .confirm({
                titulo:
                    'Realmente deseja sair da edição?',
                mensagem:
                    'Todas as informações não salvas serão perdidas.',
            })
            .subscribe((result) => {
                if (result) {
                    this.modal.emit(true);
                }
            });
    }
}
