import { Component, EventEmitter, Inject, Injector, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Gmud } from '../../shared/interfaces/gmud.interface';
import { ComponentBase } from '@utils/base/component-base/component-base';
import { GmudRecomendacoesService } from '../../shared/services/gmud-recomendacoes.service';
import { AuthLocalStorageService } from '@utils/services/auth-local-storage.service';
import { UsuarioLogado } from '@utils/interfaces/usuario/usuario-logado.interface';

@Component({
    selector: 'modal-recomendacao-gmud',
    templateUrl: './modal-recomendacao-gmud.component.html',
    styleUrls: ['./modal-recomendacao-gmud.component.scss'],
})
export class ModalRecomendacaoGmudComponent extends ComponentBase implements OnInit {
    tituloModal: string = 'Recomendação';
    descricao: string = '';
    usuarioLogado: UsuarioLogado;

    constructor(
        public dialogRef: MatDialogRef<ModalRecomendacaoGmudComponent>,
        private _injector: Injector,
        private _authLocalStorage: AuthLocalStorageService,
        protected _service: GmudRecomendacoesService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super(_injector);
        this.usuarioLogado = _authLocalStorage.getUsuario();
    }
    ngOnInit() { }

    selecionado(gmud: Gmud) {
        this.dialogRef.close(gmud);
    }

    fechar(): void {
        this.dialogRef.close();
    }

    gravar(): void {
        this._spinner.show();

        const payload: { usu_id: number, gmud_id: number, gmr_descricao: string } = {
            usu_id: this.usuarioLogado.id,
            gmud_id: this.data.gmud_id,
            gmr_descricao: this.descricao
        }

        this._service
            .gravar(payload)
            .subscribe(
                (result) => {
                    this.sucess('Recomendação salva com sucesso!');
                    this.fechar()
                    this.descricao = ''
                    this.data.refetch.emit()
                },
                (error) => {
                    this.error(error)
                });
    }
}
