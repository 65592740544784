import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsuarioRoutingModule } from './usuario-routing.module';
import { UsuarioGridPageComponent } from './pages/usuario-grid-page/usuario-grid-page.component';
import { UsuarioGridComponent } from './components/usuario-grid/usuario-grid.component';
import { UsuarioService } from './shared/services/usuario.service';
import { UsuarioEditarService } from './shared/services/usuario-editar.service';

import { ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@utils/utils.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UsuarioGuard } from './usuario.guard';
import { UsuarioDetalheComponent } from './components/usuario-detalhe/usuario-detalhe.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { UsuarioDetalhesModalComponent } from './modal/usuario-detalhes-modal/usuario-detalhes-modal.component';
import { UsuarioModalService } from './shared/services/usuario-modal.service';
import { UsuarioAlterarPerfilModalComponent } from './modal/usuario-alterar-perfil-modal/usuario-alterar-perfil-modal.component';
import { UsuarioDetalhePerfilComponent } from './components/usuario-detalhe-perfil/usuario-detalhe-perfil.component';
import { UsuarioFormPerfilComponent } from './components/usuario-form-perfil/usuario-form-perfil.component';
import { UsuarioTipoResolver } from './resolvers/usuario-tipo.resolver';
import { UsuarioTipoService } from './shared/services/usuario-tipo.service';
import { PesquisaUsuarioComponent } from './components/pesquisa-usuario/pesquisa-usuario.component';
import { ModalPesquisaUsuarioComponent } from './modal/modal-pesquisa-usuario/modal-pesquisa-usuario.component';
import { UsuarioListaResolver } from './resolvers/usuario-lista.resolver';
import { UsuarioFinnetAtivoResolver } from './resolvers/usuario-finnet-ativo.resolver'
@NgModule({
    declarations: [
        UsuarioGridPageComponent,
        UsuarioGridComponent,
        UsuarioDetalheComponent,
        UsuarioDetalhesModalComponent,
        UsuarioAlterarPerfilModalComponent,
        UsuarioDetalhePerfilComponent,
        UsuarioFormPerfilComponent,
        PesquisaUsuarioComponent,
        ModalPesquisaUsuarioComponent
    ],
    imports: [
        CommonModule,
        UsuarioRoutingModule,

        UtilsModule,

        // TABLE
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTooltipModule,

        // FORMULARIO
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
    ],
    providers: [
        UsuarioService,
        UsuarioModalService,
        UsuarioGuard,
        UsuarioTipoService,
        UsuarioTipoResolver,
        UsuarioListaResolver,
        UsuarioFinnetAtivoResolver,
        UsuarioEditarService,
    ],
    entryComponents: [
        UsuarioDetalhesModalComponent,
        UsuarioAlterarPerfilModalComponent,
        ModalPesquisaUsuarioComponent
    ],
})
export class UsuarioModule { }
