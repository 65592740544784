import { Component, Injector, OnInit } from '@angular/core';
import { FormBase } from '@utils/base/form-base/form-base';
import { Location } from '@angular/common';
import { AdquirenteService } from '../../shareds/services/adquirente.service';
import { ProdutoModuloCadastroAdquirente } from '../../shareds/interfaces/produto-modulo-cadastro-adquirente.interface';
import { Adquirente } from '../../shareds/interfaces/adquirente.interfaces';
import { CadastroAdquirente } from '../../shareds/interfaces/cadastro-adquirente.interfaces';
import { values } from 'lodash';

@Component({
  selector: 'adquirente-cadastro',
  templateUrl: './adquirente-cadastro.component.html',
  styleUrls: ['./adquirente-cadastro.component.scss']
})
export class AdquirenteCadastroComponent extends FormBase implements OnInit {

  isView: boolean = false;
  listaDataSource: Array<ProdutoModuloCadastroAdquirente> = [];

  constructor(
    private _location: Location,
    private _injector: Injector,
    private _service: AdquirenteService
  ) {
    super(_injector, AdquirenteCadastroCombos.combos());
  }

  ngOnInit() {
    if (/cadastro\/\:id\/visualizar/.test(this._route.snapshot.routeConfig.path)) {
      this.isView = true;
      this.form.clearValidators();
    }
    this._route.data.subscribe((data) => {
      this.optionList.listaProdutos = data.listaProdutos;
      this.optionList.listaModulos = data.listaModulos;
      if (data.dadosAdiquirente) {
        this.setForm(data.dadosAdiquirente);
      }
    });
    this.refreshOptionsConfig();
  }

  setForm(adquirnete: Adquirente) {
    let id = this._route.snapshot.params.id;
    this.form.patchValue({
      nome: adquirnete.nome
    });

    for (let index = 0; index < adquirnete.modulosProdutos.length; index++) {

      for (let row = 0; row < adquirnete.modulosProdutos[index].length; row++) {
        const produto = adquirnete.modulosProdutos[index][row].produto;
        const modulo = adquirnete.modulosProdutos[index][row].modulo;
        if (adquirnete.modulosProdutos[index][row].modulo.id != undefined &&
          adquirnete.modulosProdutos[index][row].modulo.id != null) {
          this.listaDataSource.push({ idProduto: produto.id, produtoNome: produto.nome, idModulo: modulo.id, moduloNome: modulo.nome });
        }
      }
    }
  }

  protected crateForm(): { [key: string]: any; } {
    return {
      nome: [],
      produto: [],
      modulo: [],
    }
  }

  isSelectedProduto(): boolean {
    if (this.form.get('produto').value != undefined &&
      this.form.get('produto').value != null &&
      this.form.get('produto').value != '') {
      return false;
    }
    return true;
  }

  isSelectedProdutoModuo(): boolean {
    let i = 0;
    if (this.form.get('produto').value != undefined &&
      this.form.get('produto').value != null &&
      this.form.get('produto').value != '') {
      i++;
    }

    if (this.form.get('modulo').value != undefined &&
      this.form.get('modulo').value != null &&
      this.form.get('modulo').value != '') {
      i++;
    }

    if (i == 2) {
      return false;
    }

    return true;
  }

  addProdutoModulo(): void {
    const produto = this.form.get('produto').value;
    const modulo = this.form.get('modulo').value;

    let existe = this.listaDataSource.findIndex(item => (item.idProduto == produto.id && item.idModulo == modulo.id));
    if (existe < 0) {
      this.listaDataSource.push({
        idProduto: produto.id,
        produtoNome: produto.nome,
        idModulo: modulo.id,
        moduloNome: modulo.nome
      });
    } else {
      this._notification.error('Este produto e modulo já se encontra adicionado a lista.');
    }
  }

  deleteProduto(index: number): void {
    if (this.listaDataSource.length > 1) {
      this.listaDataSource.splice(index, 1);
    } else {
      this._notification.error('Você deve conter pelo menos um Produto e um Modulo junto a lista. Adicione outro produto e modulo para assim excluir este item.');
    }
  }

  salvar(): void {
    if (this.validateFields()) {
      this.executaSalvar();
    }
  }

  executaSalvar(): void {
    const id = this._route.snapshot.params.id;
    const nome = this.form.get('nome').value;

    if (/cadastro\/\:id\/editar/.test(this._route.snapshot.routeConfig.path)) {
      const CadastroBanco: Adquirente = {
        id: id,
        nomeAdquirente: nome,
        modulosProdutos: this.listaDataSource
      }

      this._service.save(CadastroBanco).subscribe(
        () => {
          this.sucess('Adquirente salvo com sucesso');
          this.limparForm();
        },
        (error) => this.error(error)
      );
    } else {
      const CadastroBanco: CadastroAdquirente = {
        id: id,
        nomeAdquirente: nome,
        modulosProdutos: this.listaDataSource
      }

      this._service.save(CadastroBanco).subscribe(
        () => {
          this.sucess('Adquirente salvo com sucesso');
          this.limparForm();
        },
        (error) => this.error(error)
      );
    }
  }

  limparForm() {
    this.listaDataSource = [];
    this.form.reset();
  }

  validateFields(): boolean {
    const nome = this.form.get('nome').value;

    if (nome == undefined) {
      this._notification.error('Nome do Adquirente e um campo obrigatório.');
      return false;
    }

    if (this.listaDataSource.length == 0) {
      this._notification.error('Você deve adicionar pelo menos um Produto e um Modulo junto a lista.');
      return false;
    }

    return true;
  }

  voltar(): void {
    this._location.back();
  }
}

export class AdquirenteCadastroCombos {
  static combos() {
    return [
      {
        listName: 'listaProdutos',
        filterName: 'filterProduto',
        fieldValue: 'nome',
      },
      {
        listName: 'listaModulos',
        filterName: 'filterModulo',
        fieldValue: 'nome',
      }
    ];
  }
}
