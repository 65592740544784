import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBase } from '@utils/base/form-base/form-base';
import { DownloadUtil } from '@utils/utils/class/download.util';
import { random } from 'lodash';
import { ChecklistRelacionamentoProdutoFinancerioRequest } from '../../shared/interfaces/checklist-relacionamentoRequest.interface';
import { Checklist } from '../../shared/interfaces/checklist.interface';
import { ChecklistModalService } from '../../shared/services/checklist-modal.service';
import { ChecklistRelacionamentoEmpresaModalService } from '../../shared/services/checklist-relacionamento-empresa-modal.service';
import { ChecklistRelacionamentoProdutoFinanceiroService } from '../../shared/services/checklist-relacionamento-produto-financerio.service';

@Component({
  selector: 'checklist-produto-financeiro',
  templateUrl: './checklist-produto-financeiro.component.html',
  styleUrls: ['./checklist-produto-financeiro.component.scss']
})
export class ChecklistProdutoFinanceiroComponent extends FormBase implements OnInit {

  @Input() checklist: Checklist;
  @Output() gravando = new EventEmitter();

  public listaProdutosRelacionamento: Array<{
    id?: number, codigo: string, descricao: string, empresas?: any, bancos?: any, contas?: any, origen?: string
  }> = [];

  produto: any;
  bancos: any;
  empresas: any;
  listaProdutoFinanceiroReports: any;
  checklist_relacionamento: any;
  isVisualizacao: boolean = false;
  @Input() validate: (valid: boolean) => void;
  firstTime: boolean = true;

  constructor(private _injector: Injector,
    private _checklistRelacionamentoEmpresaModalService: ChecklistRelacionamentoEmpresaModalService,
    private _checklistRelacionamentoProdutoFinanceiroService: ChecklistRelacionamentoProdutoFinanceiroService,
    private _checklistModalService: ChecklistModalService) {
    super(_injector, ChecklistProdutoFinanceiroCombos.combos());
  }

  ngOnInit() {
    this._route.data.subscribe((data) => {
      this.isVisualizacao = data.isVisualizacao;
      this.bancos = data.bancos;
      this.empresas = data.empresas;
      this.optionList.listaProdutos = data.listaProduto;
      this.setListaProdutoFinanceiro(data.listaProdutoFinanceiro);
      this.refreshOptionsConfig();
    });

  }

  ngOnChanges({ validate }) {
    if (this.firstTime) {
      this.firstTime = false;
      return;
    }
    const valid = true

    validate.currentValue(valid)
  }

  setListaProdutoFinanceiro(listaProdutoFinanceiro: any) {
    for (let i = 0; i < listaProdutoFinanceiro.length; i++) {
      const element = listaProdutoFinanceiro[i];
      const produtoFinanceiro = {
        id: element.id,
        codigo: element.produtoFinanceiro.codigo,
        descricao: element.produtoFinanceiro.descricao,
        empresas: element.empresas || [],
        bancos: element.bancos || [],
        contas: element.relacionamentos || [],
        origen: 'produto-financeiro'
      }
      this.listaProdutosRelacionamento.push(produtoFinanceiro);
    }
  }

  protected crateForm(): { [key: string]: any; } {
    return {
      produto: ['', Validators.required]
    }
  }

  changeProduto() {
    const produto = this.form.get('produto').value
    this.produto = produto;
  }

  limpaSubForm() {
    this.form.get('produto').reset();
  }

  adicionar() {
    const data = {
      codigo: this.produto.codigo,
      descricao: this.produto.descricao,
      empresas: [],
      bancos: [],
      contas: [],
      origen: 'produto-financeiro'
    }
    // valida se a produto duplicado
    for (let i = 0; i < this.listaProdutosRelacionamento.length; i++) {
      const element = this.listaProdutosRelacionamento[i];
      if (data.codigo == element.codigo) {
        this._notification.error('Produto já existente. Vocẽ não pode adicionar o mesmos produto mais de uma vez');
        return
      }
    }

    this.listaProdutosRelacionamento.push(data);
    const relacionamento: ChecklistRelacionamentoProdutoFinancerioRequest = {
      checklist: this.checklist.id,
      produtoFinanceiro: data.codigo,
      checklistRelacionamentosEmpresasIds: [],
      checklistRelacionamentosBancosIds: [],
      checklistRelacionamentosContasIds: []
    }
    // serviçoProdutoFianceiro
    this._checklistRelacionamentoProdutoFinanceiroService.add(relacionamento)
      .subscribe((data) => {
        this._notification.sucess(data.message);
        this.listaProdutosRelacionamento[this.listaProdutosRelacionamento.length - 1].id = data.data.id;
      }, (error) => {
        this._notification.error(error.error.mensagem);
      });
    this.limpaSubForm();
  }


  remover(listaProdutoItem: any, index: number) {
    this._confirmation
      .confirm({ titulo: `Deseja remover o produto ${listaProdutoItem.descricao}?` })
      .subscribe((result) => {
        if (result) {
          this._checklistRelacionamentoProdutoFinanceiroService.delete(this.checklist.id, listaProdutoItem.id)
            .subscribe((data) => {
              this._notification.sucess(data.message);
              this.listaProdutosRelacionamento.splice(index, 1);
            }, (error) => {
              this._notification.error(error.error.message);

            });
        }
      });
  }

  visualizar(relacionamento: any): void {
    this._checklistRelacionamentoEmpresaModalService.checklistRelacionamentoEmpresaDetalhe(
      {
        relacionamento,
        checklist: this.checklist,
        bancos: this.bancos,
        empresas: this.empresas,
      }
    );
  }

  relacionar(relacionamento: any): void {
    let rel = relacionamento;
    this._checklistRelacionamentoEmpresaModalService
      .checklistRelacionamentoEmpresaCadastro({
        relacionamento,
        checklist: this.checklist,
        bancos: this.bancos,
        empresas: this.empresas,
      })
      .subscribe((data) => {
        this.listaProdutosRelacionamento.forEach((produtoRelacionamento) => {
          if (produtoRelacionamento.codigo == data.produtoFinanceiro) {
            produtoRelacionamento.contas = data.checklistRelacionamentosIds;
            produtoRelacionamento.empresas = data.checklistEmpresasIds;
            produtoRelacionamento.bancos = data.checklistBancosIds;

            const relacionamento: ChecklistRelacionamentoProdutoFinancerioRequest = {
              id: produtoRelacionamento.id,
              checklist: this.checklist.id,
              produtoFinanceiro: data.produtoFinanceiro,
              checklistRelacionamentosEmpresasIds: data.checklistEmpresasIds,
              checklistRelacionamentosBancosIds: data.checklistBancosIds,
              checklistRelacionamentosContasIds: data.checklistRelacionamentosIds,
              relacionamentos: data.idRelacionamento
            }

            // serviçoProdutoFianceiro
            this._checklistRelacionamentoProdutoFinanceiroService.update(relacionamento)
              .subscribe((data) => {
                this._notification.sucess(data.message);
                this.gravando.emit(true);
              }, (error) => {
                this._notification.error(error.error.message);
              });
          }
        });
      });
  }

  download() {
    this._spinner.show();
    this._checklistRelacionamentoProdutoFinanceiroService.download()
      .subscribe(
        (result) => {
          DownloadUtil.base64(
            result.data.arquivo,
            result.data.name,
            result.data.type
          );
          this._spinner.hide();
        },
        (error) => {
          this._spinner.hide();
          this._notification.error(error.error.message);
        });
  }

  upload() {
    this._checklistModalService.upload(this.checklist.id);
  }

  report() {
    this._checklistRelacionamentoProdutoFinanceiroService.report(this.checklist.id)
      .subscribe(
        (result) => {
          this.listaProdutoFinanceiroReports = result.data;
          this._notification.sucess('Seu relatório está em processamento, acompanhe a tela de retirada de relatórios para donwload.');
        },
        (error) => this.error(error)
      );
  }

}

export class ChecklistProdutoFinanceiroCombos {
  static combos() {
    return [
      {
        listName: 'listaProdutos',
        filterName: 'filterProduto',
        fieldValue: 'descricao',
      },
    ];
  }
}