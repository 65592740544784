import { Component, ViewEncapsulation, Injector, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import textos from './email-confirmacao-cliente-textos';
import { AuthBaseController } from '../../shared/auth-base.controller';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'email-confirmacao-cliente',
    templateUrl: './email-confirmacao-cliente.component.html',
    styleUrls: ['./email-confirmacao-cliente.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class EmailConfirmacaoClienteComponent extends AuthBaseController implements OnInit {
    conteudo: any;
    email: string;

    /**
     * Constructor
     *
     * @param {Injector} _injector
     * @param route
     */
    constructor(protected _injector: Injector, private route: ActivatedRoute) {
        super(_injector);
        this._route.params.subscribe((params) => {
            const layout = params.layout;
            this.email = history.state.email;
            switch (layout) {
                case 'confirmacao-email':
                    this.conteudo = this.trataConteudo(textos.emailConfirmacao);
                    break;
                case 'recupera-senha':
                    this.conteudo = this.trataConteudo(
                        textos.emailRecuperacaoSenha
                    );
                    break;
                case 'redefinir-senha':
                    this.conteudo = this.trataConteudo(
                        textos.emailRedefinicaoSenha
                    );
                    break;
                default:
                    this._router.navigateByUrl('/auth/');
                    return;
                    break;
            }
        });
    }

    ngOnInit(): void {}

    private trataConteudo(obj): any {
        const conteudo = Object.assign({}, obj);
        conteudo.texto = conteudo.texto.replace('{#!EMAIL}', this.emailMask(this.email));
        return conteudo;
    }

    private emailMask(email: string): string {
        const [name, dominio] = email.split('@');
        const charsName = name.split('');
        let init = 1;
        let last = charsName.length - 3;

        if (charsName.length === 5) {
            init = 0;
            last = charsName.length - 2;
        }

        if (charsName.length <= 4) {
            init = 0;
            last = charsName.length - 1;
        }

        const emailMasked = charsName.reduce((acc, char, i) => {
            if (['.', '_', '-'].includes(char)) { return acc + char; }
            if (i > init && i < last) { return acc + '*'; }
            return acc + char;
        }, '');
        return emailMasked + '@' + dominio;
    }
}
