import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Injector,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { GmudRecomendacoes } from '../../shared/interfaces/gmud-recomendacoes.interface';
// import { GmudRecomendacaoCadastro } from '../../shared/interfaces/gmud-recomendacao-cadastro.interface';
import { GmudRecomendacoesService } from '../../shared/services/gmud-recomendacoes.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GmudConsideracao } from '../../shared/interfaces/gmud-consideracao.interface';
import { GmudConsideracaoService } from '../../shared/services/gmud-consideracao.service';
import { ComponentBase } from '@utils/base/component-base/component-base';
import { MAT_DIALOG_DATA } from '@angular/material';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { FormBase } from '@utils/base/form-base/form-base';
import { UsuarioLogado } from '@utils/interfaces/usuario/usuario-logado.interface';
import { AuthLocalStorageService } from '@utils/services/auth-local-storage.service';

@Component({
    selector: 'gmud-cadastro-consideracao',
    templateUrl: './gmud-cadastro-consideracao.component.html',
    styleUrls: ['./gmud-cadastro-consideracao.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GmudCadastroConsideracaoComponent extends ComponentBase implements OnInit {
    consideracao: string = '';
    usuarioLogado: UsuarioLogado;
    consideracoes: GmudConsideracao[] = []
    recomendacaoForm = new FormGroup({ descricao: new FormControl('', []) });
    consideracaoForm = new FormGroup({ consideracao: new FormControl('', [Validators.required]) });

    @Input() recomendacao: GmudRecomendacoes = {};
    @Output() modal = new EventEmitter();

    constructor(
        protected _injector: Injector,
        private _authLocalStorage: AuthLocalStorageService,
        protected _serviceRecomendacao: GmudRecomendacoesService,
        protected _serviceConsideracao: GmudConsideracaoService,
        protected _changeDetectorRefs: ChangeDetectorRef,
        private _serviceNotification: NotificationService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super(_injector);
        this.usuarioLogado = _authLocalStorage.getUsuario();
    }

    ngOnInit(): void {
        this._serviceRecomendacao.findRecomendacao(this.recomendacao.gmud_id).subscribe((response) => {
            this.recomendacao = response.data

            this.recomendacaoForm.controls.descricao.setValue(this.recomendacao.gmr_descricao);
            this.recomendacaoForm.controls.descricao.disable()
        },
            error => console.log({ error }))

        this._serviceConsideracao.findConsideracao(this.recomendacao.gmr_id).subscribe((response) => {
            this.consideracoes = response.data
        },
            error => console.log({ error }))
    }

    protected crateForm(): { [key: string]: any } {
        return {
            valor: ['', Validators.required],
            // dataBiller: ['', Validators.required],
        };
    }

    salvar() {
        if (this.consideracoes.length >= 5) {
            this._serviceNotification.warning("Não é permitido inserir novas Considerações . O limite é de 5 por Recomendação.");
            return
        }

        const payload: { usu_id: number, gmr_id: number, gmc_descricao: string } = {
            usu_id: this.usuarioLogado.id,
            gmr_id: this.data.gmr_id,
            gmc_descricao: this.consideracao
        }

        this._serviceConsideracao
            .gravar(payload)
            .subscribe(
                (result) => {
                    this._serviceNotification.sucess('Consideração salva com sucesso!');
                    this.modal.emit(true);
                },
                (error) => this.error(error))
    }

    voltar() {
        if (!this.consideracao) {
            this.modal.emit(true);
            return
        }

        this._confirmation
            .confirm({
                titulo:
                    'Tem certeza que deseja voltar?',
                mensagem:
                    'Você não finalizou o cadastro...',
            })
            .subscribe((result) => {
                if (result) {
                    this.modal.emit(true);
                }
            });

    }
}
