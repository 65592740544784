import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CotacaoDetalhePageComponent } from './pages/cotacao-detalhe-page/cotacao-detalhe-page.component';
import { CotacaoGridPageComponent } from './pages/cotacao-grid-page/cotacao-grid-page.component';
import { CotacaoGuard } from './cotacao.guard';
import { CotacaoDetalheResolver } from './shared/resolvers/cotacao-detalhe.resolver';
import { CotacaoStatusListaResolver } from './shared/resolvers/cotacao-status-lista.resolver';
import { ChecklistStatusListaResolver } from '../checklist/shared/resolvers/checklist-status-lista.resolver';
import { StatusFinanceiroListaResolver } from './shared/resolvers/status-financeiro-lista.resolver';
import { TipoCobrancaListaResolver } from './shared/resolvers/tipo-cobranca-lista.resolver';
import { UsuarioFinnetAtivoResolver } from '../usuario/resolvers/usuario-finnet-ativo.resolver';
import { CotacaoKickoffResolver } from './shared/resolvers/cotacao-kickoff.resolver';
import { CotacaoProjetoResolver } from './shared/resolvers/cotacao-projeto.resolver';
import { CotacaoContatoResolver } from './shared/resolvers/cotacao-contato.resolver';
import { CotacaoProdutoFinanceiroResolver } from './shared/resolvers/cotacao-produto-financeiro.resolver';
import { ChecklistProdutoFinanceiroListaProdutosResolver } from '../checklist/shared/resolvers/checklist-produto-financeiro-lista-produtos.resolver';
import { CargoListaResolver } from '../checklist/shared/resolvers/cargo-lista.resolver';

const routes: Routes = [
    {
        path: '',
        canActivate: [CotacaoGuard],
        canLoad: [CotacaoGuard],
        resolve: {
            statuss: CotacaoStatusListaResolver,
            statussChecklist: ChecklistStatusListaResolver,
            statussFinanceiro: StatusFinanceiroListaResolver,
            tipoCobrancas: TipoCobrancaListaResolver,
            usuarios: UsuarioFinnetAtivoResolver,
        },
        component: CotacaoGridPageComponent,
    },
    {
        path: ':id',
        canActivate: [CotacaoGuard],
        canLoad: [CotacaoGuard],
        resolve: {
            cotacao: CotacaoDetalheResolver,
            statuss: CotacaoStatusListaResolver,
            statussFinanceiro: StatusFinanceiroListaResolver,
            tipoCobrancas: TipoCobrancaListaResolver,
            usuarios: UsuarioFinnetAtivoResolver,
            kickoff: CotacaoKickoffResolver,
            projeto: CotacaoProjetoResolver,
            contato: CotacaoContatoResolver,
            cotacaoProdutoFinanceiro: CotacaoProdutoFinanceiroResolver,
            listaProdutoFinanceiro: ChecklistProdutoFinanceiroListaProdutosResolver,
            listaCargos: CargoListaResolver,
        },
        component: CotacaoDetalhePageComponent,
    },

    { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class CotacaoRoutingModule { }
