import { FormGroup, Validators, } from '@angular/forms';
import { Component, OnInit, ViewEncapsulation, Injector, OnDestroy, } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { AuthBaseController } from '../../../shared/auth-base.controller';
import { confirmaSenhaValidator } from '../../../shared/validators/confirma-senha.validator';
import {
    lengthBetweenValidator,
    missingNumericValidator, missingSpecialCharacterValidator,
} from '../../../shared/validators/senha-forte.validator';
import { AuthRedefinirSenhaCliente } from '../../../shared/interfaces/auth-redefinir-senha-cliente.interface';
import { environment } from 'environments/environment';
import { AuthLocalStorageService } from '../../../../../../../@utils/services/auth-local-storage.service';

@Component({
    selector: 'primeiro-acesso-cliente-center',
    templateUrl: './primeiro-acesso-cliente-center.component.html',
    styleUrls: ['./primeiro-acesso-cliente-center.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class PrimeiroAcessoClienteCenterComponent extends AuthBaseController implements OnInit, OnDestroy {
    cadastrarSenha: FormGroup;
    erro: string = null;
    primeiroAcesso: AuthRedefinirSenhaCliente;
    recaptcha: any[];
    recaptchaKey: string;

    private _unsubscribeAll: Subject<any>;

    constructor(protected _injector: Injector, private authLocalStorage: AuthLocalStorageService) {
        super(_injector);
        this._unsubscribeAll = new Subject();
        this.recaptchaKey = environment.recaptchaKey;
    }

    ngOnInit(): void {
        this._route.data.subscribe((data) => {
            this.primeiroAcesso = data.cadastrarSenha;
        });

        this.cadastrarSenha = this._formBuilder.group({
            senha: [
                '',
                Validators.compose([
                    Validators.required,
                    missingNumericValidator,
                    missingSpecialCharacterValidator,
                    lengthBetweenValidator(8, 12)
                ]),
            ],
            senhaConfirm: [
                '',
                Validators.compose([
                    Validators.required,
                    confirmaSenhaValidator,
                ]),
            ],
        });

        this.cadastrarSenha
            .get('senha')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                if (this.cadastrarSenha.get('senhaConfirm').value === '') {
                    return;
                }

                this.cadastrarSenha
                    .get('senhaConfirm')
                    .updateValueAndValidity();
            });

        this.cadastrarSenha.markAllAsTouched();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    enviar(): void {
        this.primeiroAcesso.senha = this.cadastrarSenha.get('senha').value;
        this.primeiroAcesso.recaptcha = this.recaptcha;
        this._spinner.show();
        this._authService.redefinirSenha(this.primeiroAcesso).subscribe(
            (res) => {
                console.log('res: ', res);

                this._spinner.hide();
                this._notification.sucess('A senha foi cadastrada com sucesso');
                this._router.navigateByUrl('/auth/login');
            },
            (error: any) => {
                console.log('error: ', error);

                this._spinner.hide();
                this._notification.error(error.error.message);
            }
        );
    }

    recaptchaResolved(captchaResponse: any[]): void {
        this.recaptcha = captchaResponse;
    }
}
