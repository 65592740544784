import {
    Component,
    EventEmitter,
    Injector,
    OnInit,
    Output,
} from '@angular/core';
import { FormFiltroBase } from '@utils/base/form-filtro-base/form-filtro-base';
import { DataUtil } from '@utils/utils/class/data.util';
import { FiltroGmud } from '../../shared/interfaces/filtro-gmud.interface';
// import { GmudAmbiente } from '../../shared/interfaces/gmud-ambiente.interface';
import { GmudTipo } from '../../shared/interfaces/gmud-tipo.interface';
import { GmudProduto } from '../../shared/interfaces/gmud-produto.interface';
import { GmudImpacto } from '../../shared/interfaces/gmud-impacto.interface';
import { GmudTipoSolucao } from '../../shared/interfaces/gmud-tipo-solucao.interface';
// import { GmudResponsavelDesenvolvimento } from '../../shared/interfaces/gmud-responsavel-desenvolvimento.interface';
// import { GmudResponsavelProducao } from '../../shared/interfaces/gmud-responsavel-producao.interface';
// import { GmudStatusGmud } from '../../shared/interfaces/gmud-status-gmud.interface';
import { GmudStatus } from '../../shared/interfaces/gmud-status.interface';
import { GmudService } from '../../shared/services/gmud.service';
import * as moment from 'moment';

@Component({
    selector: 'gmud-grid-filtro',
    templateUrl: './gmud-grid-filtro.component.html',
    styleUrls: ['./gmud-grid-filtro.component.scss'],
})
export class GmudGridFiltroComponent extends FormFiltroBase<FiltroGmud> implements OnInit {

    listaStatus: Array<GmudStatus> = [];
    listaProdutos: Array<GmudProduto> = [];
    listaTipoMudanca: Array<GmudTipo> = [];
    listaImpacto: Array<GmudImpacto> = [];
    listaTipoSolucao: Array<GmudTipoSolucao> = [];

    @Output() filtrar = new EventEmitter<FiltroGmud>();

    constructor(
        injector: Injector,
        protected _gmudService: GmudService
    ) {
        super('gmud', injector, GmudlistGridFiltroCombos.combos());
    }

    ngOnInit() {
        this._route.data.subscribe((data) => {
            this.optionList.listaStatus = data.status;
            this.optionList.listaTipoMudanca = data.tipoMudanca;
            this.optionList.listaImpacto = data.impacto;
            this.optionList.listaProdutos = data.produtos;
            this.optionList.listaTipoSolucao = data.tipoSolucao;

            this.refreshOptionsConfig();
        });
    }

    camposFiltro(): string[] {
        return [
            'id',
            'status',
            'produto',
            'tipo',
            'tipo_solucao',
            'impacto',
            'gmud_data_inicio',
            'gmud_data_fim',
        ];
    }

    protected crateForm(): { [key: string]: any } {
        return {
            status: [],
            id: [],
            produto: [],
            tipoMudanca: [],
            tipoSolucao: [],
            impacto: [],
            tipoData: 'execucao',
            dataInicio:  moment().subtract(7, 'days'),
            dataFim: moment().add(7, 'days'),
        };
    }

    filtrarExecEnter(event): void {
        if (event.keyCode === 13) {
            this.filtrarExec();
        }
    }

    filtrarExec(): void {
        const id = this.form.get('id').value;
        const status = this.form.get('status').value;
        const produto = this.form.get('produto').value;
        const tipo = this.form.get('tipoMudanca').value;
        const tipo_solucao = this.form.get('tipoSolucao').value;
        const impacto = this.form.get('impacto').value;
        const tipoData = this.form.get('tipoData').value;
        const created_at = this.form.get('dataInicio').value;
        const gmud_data = this.form.get('dataFim').value;

        const filtro: FiltroGmud = {
            id,
            status,
            produto,
            tipo,
            tipo_solucao,
            impacto,
            tipo_data: tipoData || 'criacao',
            gmud_data_inicio: created_at ? DataUtil.toDateBackend(created_at) : '',
            gmud_data_fim: gmud_data ? DataUtil.toDateBackend(gmud_data) : '',
        };

        this.filtrar.emit(filtro);
    }
}

export class GmudlistGridFiltroCombos {
    static combos() {
        return [
            {
                listName: 'listaStatus',
                filterName: 'filterStatus',
                fieldValue: 'gms_descricao',
            },
            {
                listName: 'listaTipoMudanca',
                filterName: 'filterTipoMudanca',
                fieldValue: 'gmt_descricao',
            },
            {
                listName: 'listaImpacto',
                filterName: 'filterImpacto',
                fieldValue: 'gmi_descricao',
            },
            {
                listName: 'listaTipoSolucao',
                filterName: 'filterTipoSolucao',
                fieldValue: 'gmts_descricao',
            },
            {
                listName: 'listaProdutos',
                filterName: 'filterProduto',
                fieldValue: 'gmp_descricao',
            }
        ];
    }
}