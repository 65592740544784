import { NgModule } from '@angular/core';

import { AuthRoutingModule } from './auth-routing.module';

import { AuthService } from './shared/services/auth.service';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '@utils/utils.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LoginClienteComponent } from './cliente/login-cliente/login-cliente.component';
import { LoginAdminComponent } from './admin/login-admin/login-admin.component';
import { EsqueceuSenhaClienteComponent } from './cliente/esqueceu-senha-cliente/esqueceu-senha-cliente.component';
import { EsqueceuSenhaClienteCenterComponent } from './cliente/esqueceu-senha-cliente/esqueceu-senha-cliente-center/esqueceu-senha-cliente-center.component';
import { EmailConfirmacaoClienteComponent } from './cliente/email-confirmacao-cliente/email-confirmacao-cliente.component';
import { RedefinirSenhaClienteComponent } from './cliente/redefinir-senha-cliente/redefinir-senha-cliente.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { LoginAdminCenterComponent } from './admin/login-admin/login-admin-center/login-admin-center.component';
import { RedefinirSenhaClienteCenterComponent } from './cliente/redefinir-senha-cliente/redefinir-senha-cliente-center/redefinir-senha-cliente-center.component';
import { RedefinirSenhaClienteValidaDadosResolver } from './shared/resolvers/redefinir-senha-cliente-valida-dados.resolver';
import { AuthLocalStorageService } from '@utils/services/auth-local-storage.service';
import { PrimeiroAcessoClienteComponent } from './cliente/primeiro-acesso-cliente/primeiro-acesso-cliente.component';
import {
    PrimeiroAcessoClienteCenterComponent
} from './cliente/primeiro-acesso-cliente/primeiro-acesso-cliente-center/primeiro-acesso-cliente-center.component';

@NgModule({
    declarations: [
        LoginAdminComponent,
        LoginAdminCenterComponent,
        LoginClienteComponent,
        EsqueceuSenhaClienteComponent,
        EsqueceuSenhaClienteCenterComponent,
        EmailConfirmacaoClienteComponent,
        RedefinirSenhaClienteComponent,
        RedefinirSenhaClienteCenterComponent,
        PrimeiroAcessoClienteComponent,
        PrimeiroAcessoClienteCenterComponent
    ],
    imports: [
        CommonModule,
        AuthRoutingModule,
        UtilsModule,

        // FORMULARIO
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        RecaptchaModule,
        RecaptchaFormsModule,
    ],
    providers: [
        AuthLocalStorageService,
        AuthService,
        RedefinirSenhaClienteValidaDadosResolver,
    ],
})
export class AuthModule { }
