import { Injectable } from '@angular/core';
import { BaseGuard, GuardConfigPermission } from '@utils/guard/base.guard';
import { Role } from 'app/fuse-config/role';

@Injectable()
export class AdquirenteGuard extends BaseGuard {
  protected configPermissions(): Array<GuardConfigPermission> {
    return [
      { path: '/adquirente', role: Role.ADQUIRENTE_LISTAR },
      { path: '/adquirente/cadastro', role: Role.ADQUIRENTE_CADASTRAR },
      { path: '/adquirente/[0-9]+/visualizar', role: Role.ADQUIRENTE_BUSCAR },
      { path: '/adquirente/[0-9]+/editar', role: Role.ADQUIRENTE_ALTERAR },
    ];
  }
}
