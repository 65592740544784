import { ChangeDetectorRef, Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Gmud } from '../../shared/interfaces/gmud.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GmudModalService } from '../../shared/services/gmud-modal.service';
import { GmudService } from '../../shared/services/gmud.service';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { FiltroGmud } from '../../shared/interfaces/filtro-gmud.interface';
import { FormBase } from '@utils/base/form-base/form-base';
import { OptionsConfig } from '@utils/base/form-base/options-config.interface';

@Component({
    selector: 'modal-relatorios',
    templateUrl: './modal-relatorios.component.html',
    styleUrls: ['./modal-relatorios.component.scss'],
})
export class ModalRelatoriosComponent implements OnInit {
    tituloModal: string = 'Relatório GMUD';

    myForm: FormGroup;

    constructor(
        private fb: FormBuilder,
        protected _gmudService: GmudService,
        public dialogRef: MatDialogRef<ModalRelatoriosComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Gmud
    ) {
    }

    ngOnInit() {
        this.myForm = this.fb.group({
            relatorio: ''
        })
    }

    configDisplayedColumns(): Array<any> {
        return [];
    }

    selecionado(gmud: Gmud) {
        this.dialogRef.close(gmud);
    }

    gerarRelatorio(): void {
        console.log('OPA', this.myForm.value.relatorio, this.data)
        // this._spinner.show();

        this._gmudService.gerarRelatorio(this.myForm.value.relatorio, this.data).subscribe(
            (result) => {
                console.log({ result })
                // this.sucess("Seu relatório está em processamento, acompanhe a tela de retirada de relatórios para download");
                console.log('Seu relatório está em processamento, acompanhe a tela de retirada de relatórios para download')
            },
            (error) => {
                // this.error(error)
                console.log({ error })
            }
        );
    }

    fechar(): void {
        this.dialogRef.close();
    }
}
