import { SelectionModel } from '@angular/cdk/collections';
import {
    ChangeDetectorRef,
    Component,
    Injector,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { DownloadUtil } from '@utils/utils/class/download.util';
import { environment } from 'environments/environment';
import { FiltroProjeto } from '../../shareds/interfaces/filtro-projeto.interface';
import { Projeto } from '../../shareds/interfaces/projeto.interface';
import { ProjetoModalService } from '../../shareds/services/projeto-modal.service';
import { ProjetoService } from '../../shareds/services/projeto.service';

@Component({
    selector: 'projeto-grid',
    templateUrl: './projeto-grid.component.html',
    styleUrls: ['./projeto-grid.component.scss'],
})
export class ProjetoGridComponent
    extends GridBase<Projeto, FiltroProjeto, ProjetoService>
    implements OnChanges, OnInit {
    selection = new SelectionModel<Projeto>(true, []);

    constructor(
        protected _service: ProjetoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        private _modalProjeto: ProjetoModalService
    ) {
        super(_service, _injector, _changeDetectorRefs, 'ProjetoGrid');
    }

    ngOnInit(): void {
        this.initGrid(this.filtro || {});
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.atualizarGrid(this.filtro || {}, () => this.selection.clear());
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'select' },
            { chave: 'seleciona' },
            { chave: 'projetoStatus', label: 'Status Financeiro' },
            { chave: 'statusReport', label: 'Status Report' },
            { chave: 'projetoProcessoStatus', label: 'Status Projeto' },
            { chave: 'cotacao', label: 'Cotação' },
            { chave: 'projeto', label: 'Projeto' },
            { chave: 'checklist', label: 'Checklist' },
            { chave: 'empresa', label: 'Empresa' },
            { chave: 'inscricao', label: 'Inscrição' },
            { chave: 'produto', label: 'Produto' },
            { chave: 'modulo', label: 'Modulo' },
            { chave: 'time', label: 'Time' },
            { chave: 'usuario', label: 'Responsável' },
            { chave: 'projetoFaturado', label: 'Projeto faturado' },
            { chave: 'dataCadastro', label: 'Data criação' },
            { chave: 'dataFaturado', label: 'Data de faturamento' },
            { chave: 'dataPlanejadaInicio', label: 'Data Planejada Inicial' },
            { chave: 'dataPlanejadaFim', label: 'Data Planejada Fim' },
            { chave: 'dataRealInicio', label: 'Data Real Inicial' },
            { chave: 'dataRealFim', label: 'Data Real Fim' },
        ];

    }

    showModal() {
        const projetosSelecionadas = this.selection.selected.map((v) => v.id);
        this._modalProjeto.alteracaoEmLote({ projetosSelecionadas }).subscribe((result) => {
            this.atualizarGrid(this.filtro);
        });
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.paginacao.pageSize;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.listaDataSource.data.forEach((row) =>
                this.selection.select(row)
            );
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Projeto): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1
            }`;
    }

    visualizar(projeto: Projeto) {
        this._router.navigateByUrl(`projeto/visualizar/${projeto.id}`);
    }

    editar(projeto: Projeto) {
        this._router.navigateByUrl(`projeto/cadastro/${projeto.id}`);
    }

    historico(projeto: Projeto) {
        this._modalProjeto.historico(projeto);
    }

    download(projeto: Projeto) {
        this._modalProjeto.download(projeto);
    }

    gerarRelatorio(): void {
        this._spinner.show();
        this._service.gerarRelatorio(this.filtro).subscribe(
            (result) => {
                // DownloadUtil.base64(
                //     result.data.arquivo,
                //     result.data.name,
                //     result.data.type
                // );
                this.sucess("Seu relatório está em processamento, acompanhe a tela de retirada de relatórios para download");
            },
            (error) => this.error(error)
        );
    }

    visualizarCotacao(idCotacao) {
        const link = `${environment.host}/cotacao/${idCotacao}`;
        this._router.navigate([]).then((result) => {
            window.open(link, '_blank');
        });
    }

    visualizarChecklist(idChecklist) {
        const link = `${environment.host}/checklist/${idChecklist}/visualizar`;
        this._router.navigate([]).then((result) => {
            window.open(link, '_blank');
        });
    }
}
