import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { FormBase } from '@utils/base/form-base/form-base';
import { ConfirmationService } from '@utils/utils/confirmation/confirmation.service';
import { ContratoTipoReajuste } from '../../shared/interfaces/contrato-tipo-reajuste.interface';
import { ContratoTipoReajusteService } from '../../shared/services/contrato-tipo-reajuste.service';
import { ContratoReajuste } from '../../shared/interfaces/contrato-reajuste.interface';
import * as moment from 'moment';
import { Validators } from '@angular/forms';
import { ContratoReajusteService } from '../../shared/services/contrato-reajuste.service';
import { DataUtil } from '@utils/utils/class/data.util';

@Component({
    selector: 'contrato-atualizar-reajuste',
    templateUrl: './contrato-atualizar-reajuste.component.html',
    styleUrls: ['./contrato-atualizar-reajuste.component.scss'],
})
export class ContratoAtualizarReajusteComponent
    extends FormBase
    implements OnInit {

    isView: boolean = false;
    listaTipoReajustes: Array<ContratoTipoReajuste> = [];

    @Output() atualizado = new EventEmitter();

    private _idsContratoReajuste: Array<number> = []

    get idsContratoReajuste() {
        return this._idsContratoReajuste;
    }

    @Input() set idsContratoReajuste(idsContratoReajuste: Array<number>) {
        const idsContrato = idsContratoReajuste;
        this._idsContratoReajuste = idsContratoReajuste;
        if (idsContrato && idsContrato.length > 0) {
            this.isView = true;
        }
    }

    constructor(
        protected _injector: Injector,
        private _contratoAtualizarReajusteService: ContratoTipoReajusteService,
        private _contratoReajusteService: ContratoReajusteService
    ) {

        super(_injector);

    }

    ngOnInit() {
        this._contratoAtualizarReajusteService.find().subscribe(result => {
            this.listaTipoReajustes = result.data
        })
    }

    protected crateForm(): { [key: string]: any } {
        return {
            tipoReajuste: ['', Validators.required],
            valor: ['', Validators.required],
            dataBiller: ['', Validators.required],
        };
    }

    editar() {
        const dataBiller = this.form.get('dataBiller').value
        const porcentagemReajuste = this.form.get('valor').value
        const contratoReajuste: ContratoReajuste = {
            tabelaReajusteId: this.form.get('tipoReajuste').value,
            porcentagemReajuste: porcentagemReajuste,
            dataBiller: dataBiller ? DataUtil.toDateBackend(dataBiller) : '',
            contratoItemId: this._idsContratoReajuste

        };
        this._confirmation
            .confirm({
                titulo:
                    'Tem certeza que deseja realizar o reajuste?',
                mensagem:
                    'Esta alteração impacta nos Projetos relacionados.',
            })
            .subscribe((result) => {
                if (result) {
                    this.editarExec(contratoReajuste);
                }
            });
    }

    private editarExec(contratoReajuste) {
        this._spinner.show();
        this._contratoReajusteService.update(contratoReajuste).subscribe(
            () => {
                this.sucess('Reajuste realizado com sucesso!');
                this.atualizado.emit('true');
            },
            (error) => this.error(error)
        );
    }
}
