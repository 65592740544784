import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { GmudDocumentoUpload } from '../interfaces/gmud-documento-upload.interface';

@Injectable({
    providedIn: 'root',
})
export class GmudDocumentoUploadService extends ServiceBase<GmudDocumentoUpload> {
    // private path: string = `${environment.server}/gmud/manualdocumento`;
    private path: string = `${environment.server}/gmud/gmud-documento-upload`;

    constructor(private _http: HttpClient) {
        super();
    }

    upload(idGmud, file: File): Observable<any> {
        const url = `${this.path}`;
        const formData = new FormData();
        formData.set('documento', file, file.name);
        formData.set('idGmud', idGmud);

        return this._http.post(url, formData, {
            observe: 'events',
            reportProgress: true,
        });
    }
}
