import { HttpEventType } from '@angular/common/http';
import {
    Component,
    ElementRef,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { SpinnerService } from '@utils/utils/spinner/spinner.service';
import { EventEmitter } from 'events';
import { Spinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { DocumentImportService } from '../../shareds/services/document-import.service';
import { DocumentModalService } from '../../shareds/services/document-modal.service';
import * as XLSX from 'xlsx';


@Component({
    selector: 'documento-exporte',
    templateUrl: './documento-exporte.component.html',
    styleUrls: ['./documento-exporte.component.scss'],
})
export class DocumentoExporteComponent implements OnInit {

    constructor(
        private _notification: NotificationService,
        private _documentImportService: DocumentImportService,
        private _spinner: SpinnerService
    ) { }
    @Input() data: any;
    @Output() uploadFinalizado = new EventEmitter();
    @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
    file: any;
    errors: any = [];
    uploading = false;
    uploaded = false;

    protected readonly Object = Object;

    ngOnInit() { }

    /**
     * gerenciador de descarte de arquivo
     */
    onFileDropped($event): void {
        this.prepareFile($event);
    }

    /**
     * lidar com arquivo de navegação
     */
    fileBrowseHandler(files): void {
        this.prepareFile(files);
    }

    /**
     * Excluir arquivo da lista de arquivos
     * @param index (File index)
     */
    deleteFile(): void {
        if (this.file.progress > 0 && this.file.progress < 100) {
            return;
        }
        this.file = null;
        this.errors = [];
    }

    /**
     * Converter lista de arquivos em lista de matriz normal
     * @param files (Files List)
     */
    prepareFile(files: Array<any>): void {
        this.file = files[0] || {};
        this.file.progress = 0;

        this.fileDropEl.nativeElement.value = '';
    }

    /**
     * format bytes
     * @param bytes (File size in bytes)
     * @param decimals (Decimals point)
     */
    formatBytes(bytes, decimals = 2): string {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        );
    }

    upload(): void {
        this.uploading = true;
        this._documentImportService
            .upload(this.file, this.data.origem, this.data)
            .subscribe(
                (event: any) => {
                    if (event.type === HttpEventType.Response) {
                        this.uploading = false;
                        this.uploaded = true;
                        this._notification.sucess('Upload realizado com sucesso!');
                        this.uploadFinalizado.emit('true');
                        this.deleteFile();
                    } else if (event.type === HttpEventType.UploadProgress) {
                        this.file.progress = Math.round((event.loaded * 100) / event.total);
                    }
                    this.errors = [];
                },
                (error) => {
                    this.errors = error.error.errors;
                    this.uploading = false;
                    this.uploaded = false;
                }
            );
    }

    exportToXlsx(): void {
        const data = this.errors.map((errItem) => {
            return Object.keys(errItem).map(key => {
                return [`Linha ${errItem[key].linha} ${errItem[key].coluna} - ${errItem[key].message}`];
            });
        });

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.flat());
        const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        XLSX.writeFile(workbook, 'errors.xlsx');

        this._notification.sucess('Relatorio exportado com sucesso!');
    }
}
