import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'

export const isFutureHour: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent || !control) {
        return null
    }

    const inputHoraMudanca = control.parent.get('horaMudanca')
    const inputHoraTermino = control.parent.get('horaTermino')

    if (inputHoraMudanca.value === '' || inputHoraMudanca.value === '') {
        return null
    }

    if (!(+inputHoraMudanca.value > +inputHoraTermino.value)) return null

    return {
        isFutureHour: (+inputHoraMudanca.value > +inputHoraTermino.value) ? true : null
    }
}
