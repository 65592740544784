import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';

import { ModalHistoricoGmudComponent } from '../../modal/modal-historico-gmud/modal-historico-gmud.component';
import { ModalHistoricoDetalheGmudComponent } from '../../modal/modal-historico-detalhe-gmud/modal-historico-detalhe-gmud.component';

import { ModalRecomendacaoGmudComponent } from '../../modal/modal-recomendacao-gmud/modal-recomendacao-gmud.component';
import { ModalRecomendacaoDetalheGmudComponent } from '../../modal/modal-recomendacao-detalhe-gmud/modal-recomendacao-detalhe-gmud.component';
import { ModalCadastroRecomendacaoComponent } from '../../modal/modal-cadastro-recomendacao/modal-cadastro-recomendacao.component';

import { Gmud } from '../interfaces/gmud.interface';
import { GmudHistorico } from '../interfaces/gmud-historico.interface';
import { GmudRecomendacoes } from '../interfaces/gmud-recomendacoes.interface';
import { ModalCadastroConsideracaoGmudComponent } from '../../modal/modal-cadastro-consideracao-gmud/modal-cadastro-consideracao-gmud.component';
import { ModalRelatoriosComponent } from '../../modal/modal-relatorios/modal-relatorios.component';

@Injectable({
    providedIn: 'root',
})
export class GmudModalService {
    constructor(public dialog: MatDialog) { }

    relatorios(data: any) {
        const dialogRef = this.dialog.open(ModalRelatoriosComponent, {
            width: '50%',
            // height: '500px',
            data,
        });

        return dialogRef.afterClosed();
    }

    historico(data: Gmud) {
        const dialogRef = this.dialog.open(ModalHistoricoGmudComponent, {
            width: '90%',
            data,
        });

        return dialogRef.afterClosed();
    }

    historicoDetalhe(data: GmudHistorico) {
        const dialogRef = this.dialog.open(
            ModalHistoricoDetalheGmudComponent,
            {
                width: '90%',
                data,
            }
        );

        return dialogRef.afterClosed();
    }

    recomendacao(data: Gmud) {
        const dialogRef = this.dialog.open(ModalRecomendacaoGmudComponent, {
            width: '90%',
            data,
        });

        return dialogRef.afterClosed();
    }

    recomendacaoCadastro({ id, refetch }: { id: number, refetch: any }) {
        const dialogRef = this.dialog.open(
            ModalCadastroRecomendacaoComponent,
            {
                width: '90%',
                data: { id, refetch }
            }
        );

        return dialogRef.afterClosed();
    }

    consideracaoCadastro(data: GmudRecomendacoes) {
        const dialogRef = this.dialog.open(
            ModalCadastroConsideracaoGmudComponent,
            {
                width: '90%',
                data,
            }
        );

        return dialogRef.afterClosed();
    }

    recomendacaoDetalhe(data: GmudRecomendacoes) {
        const dialogRef = this.dialog.open(
            ModalRecomendacaoDetalheGmudComponent,
            {
                width: '90%',
                data,
            }
        );

        return dialogRef.afterClosed();
    }
}
