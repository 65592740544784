import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentBase } from '@utils/base/component-base/component-base';
import { ModelBase } from '@utils/base/model-base/model-base';
import { ObjectUtil } from '@utils/utils/class/object.util';
import { SpinnerService } from '@utils/utils/spinner/spinner.service';
import { isEmpty } from 'lodash';
import { ChecklistConectividade } from '../../shared/interfaces/checklist-conectividade.interface';
import { Checklist } from '../../shared/interfaces/checklist.interface';
import { ChecklistConectividadeService } from '../../shared/services/checklist-conectividade.service';

@Component({
    selector: 'checklist-conectividade',
    templateUrl: './checklist-conectividade.component.html',
    styleUrls: ['./checklist-conectividade.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ChecklistConectividadeComponent extends ComponentBase implements OnInit {
    @Input() checklist: Checklist;
    @Output() gravando = new EventEmitter();
    conectividades: Array<ChecklistConectividade> = [];
    conectividadeSelecionada: ChecklistConectividade;
    conectividadeForm: ModelBase;
    isVisualizacao: boolean = false;
    outros: string;
    @Input() validate: (valid: boolean) => void;
    firstTime: boolean = true;
    conectivadeFormPadrao: ModelBase;

    constructor(
        private _checklistConectividadeService: ChecklistConectividadeService,
        private _injector: Injector
    ) {
        super(_injector);
        this.conectividadeSelecionada = {};
        this.outros = '';
    }

    ngOnInit(): void {
        this._route.data.subscribe((data) => {
            data.conectividades.forEach((c: ChecklistConectividade) => {
                if (c.selected) {
                    this.conectividadeSelecionada = c;
                    if (c.isOutros) {
                        this.outros = c.descricao;
                    }
                    this.conectividadeForm = this.conectividadeSelecionada.json ? JSON.parse(this.conectividadeSelecionada.json) : undefined;
                    this.conectivadeFormPadrao = this.conectividadeSelecionada.json ? JSON.parse(this.conectividadeSelecionada.json) : undefined;
                }
            });
            this.conectividades = this.setOrderList(data.conectividades);
            this.isVisualizacao = data.isVisualizacao;
        });
    }

    ngOnChanges({ validate }) {
        if (this.firstTime) {
            this.firstTime = false;
            return;
        }

        const valid = JSON.stringify(this.conectivadeFormPadrao) === JSON.stringify(this.conectividadeForm);

        validate.currentValue(valid)
    }

    setOrderList(conectividades: any): ChecklistConectividade[] {
        let arrConectividades = [];
        for (let i = 0; i < conectividades.length; i++) {
            const element = conectividades[i];

            switch (element.chave) {
                case 'FAST_WEB':
                    arrConectividades[0] = element;
                    break;
                case 'WEB_SERVICE_EDI':
                    arrConectividades[1] = element;
                    break;
                case 'SFTP':
                    arrConectividades[2] = element;
                    break;
                case 'CONNECT_DIRECT':
                    arrConectividades[3] = element;
                    break;
                case 'API_OPEN_BANKING':
                    arrConectividades[4] = element;
                    break;
                case 'B2BI':
                    arrConectividades[5] = element;
                    break;
                case 'FINNET_FAST_CLIENT':
                    arrConectividades[6] = element;
                    break;
                case 'WEB_DAV':
                    arrConectividades[7] = element;
                    break;
                case 'OUTRO':
                    arrConectividades[8] = element;
                    break;
            }

        }

        return arrConectividades;
    }

    selecionaConectividade(conectividade: ChecklistConectividade): void {
        if (!conectividade.isOutros) {
            this.outros = '';
        }
        this.conectividadeSelecionada = Object.assign({}, conectividade);
        this.conectividadeForm = this.conectividadeSelecionada.json;
    }

    validaConectividadeForm(): boolean {
        let status = true
        if (!this.conectividadeForm && !this.conectividadeSelecionada.isOutros) {
            this._notification.error('Preencha os campos da conectividade');
            status = false;
        }

        if (this.conectividadeSelecionada.chave == 'FINNET_FAST_CLIENT') {
            if (isEmpty(this.conectividadeForm['caixaPostal'])) {
                this._notification.error('Caixa Postal é um campo obrigatório');
                status = false;
            }
            if (isEmpty(this.conectividadeForm['caixaPostalSenha'])) {
                this._notification.error('Senha é um campo obrigatório');
                status = false;
            }
        }

        if (this.conectividadeSelecionada.chave == 'CONNECT_DIRECT') {
            if (isEmpty(this.conectividadeForm['enderecoIp'])) {
                this._notification.error('Endereço IP é um campo obrigatório');
                status = false;
            }
            if (isEmpty(this.conectividadeForm['nodeNo'])) {
                this._notification.error('Node (Nó) é um campo obrigatório');
                status = false;
            }
            if (isEmpty(this.conectividadeForm['usuario'])) {
                this._notification.error('Usuário é um campo obrigatório');
                status = false;
            }
            if (isEmpty(this.conectividadeForm['porta'])) {
                this._notification.error('Porta é um campo obrigatório');
                status = false;
            }

            if (isEmpty(this.conectividadeForm['possuiSecurityPlus'])) {
                this._notification.error('Security Plus é um campo obrigatório. Selecione um opção');
                status = false;
            }
        }

        if (this.conectividadeSelecionada.chave == 'SFTP') {
            if (this.conectividadeForm['servidorFinnetSftp'] == 'SERVIDOR_FINNET') {
                if (isEmpty(this.conectividadeForm['caixaPostal'])) {
                    this._notification.error('Caixa postal é um campo obrigatório');
                    status = false;
                }
                if (isEmpty(this.conectividadeForm['caixaPostalSenha'])) {
                    this._notification.error('Senha é um campo obrigatório');
                    status = false;
                }
            } else {
                if (isEmpty(this.conectividadeForm['sftpHost'])) {
                    this._notification.error('Host é um campo obrigatório');
                    status = false;
                }
                if (isEmpty(this.conectividadeForm['sftpPorta'])) {
                    this._notification.error('Porta é um campo obrigatório');
                    status = false;
                }
                if (isEmpty(this.conectividadeForm['sftpUsuario'])) {
                    this._notification.error('Usuário é um campo obrigatório');
                    status = false;
                }
                if (isEmpty(this.conectividadeForm['sftpSenha'])) {
                    this._notification.error('Senha é um campo obrigatório');
                    status = false;
                }
                if (isEmpty(this.conectividadeForm['sftpDiretorioRetorno'])) {
                    this._notification.error('Diretório de Retorno é um campo obrigatório');
                    status = false;
                }
                if (isEmpty(this.conectividadeForm['sftpDiretorioRemessa'])) {
                    this._notification.error('Diretório de Remessa é um campo obrigatório');
                    status = false;
                }
                if (isEmpty(this.conectividadeForm['sftpNomenclaturaRetorno'])) {
                    this._notification.error('Nomenclatura de Retorno é um campo obrigatório');
                    status = false;
                }
            }
        }

        if (this.conectividadeSelecionada.chave == 'FAST_WEB') {
            if (this.conectividadeForm['possuiCaixaPostal'] === 'SIM') {
                if (isEmpty(this.conectividadeForm['possuiCaixaPostalQual'])) {
                    this._notification.error('Qual caixa postal é um campo obrigatório');
                    status = false;
                }
            }
            else if (this.conectividadeForm['possuiCaixaPostal'] === 'NAO') {
                if (isEmpty(this.conectividadeForm['produtoBancario'])) {
                    this._notification.error('Nomeclarutra da Remessa é um campo obrigatório');
                    status = false;
                }
                if (isEmpty(this.conectividadeForm['razaoSocial'])) {
                    this._notification.error('Razão Social é um campo obrigatório');
                    status = false;
                }
                if (isEmpty(this.conectividadeForm['cnpj'])) {
                    this._notification.error('CNPJ é um campo obrigatório');
                    status = false;
                }
                if (isEmpty(this.conectividadeForm['diretorioRemessa'])) {
                    this._notification.error('Diretório da Remessa é um campo obrigatório');
                    status = false;
                }
                if (isEmpty(this.conectividadeForm['nomenclaturaRemessa'])) {
                    this._notification.error('Nomenclatura da Remessa é um campo obrigatório');
                    status = false;
                }
                if (isEmpty(this.conectividadeForm['diretorioRetorno'])) {
                    this._notification.error('Diretório do Retorno é um campo obrigatório');
                    status = false;
                }
                if (isEmpty(this.conectividadeForm['nomenclaturaRetorno'])) {
                    this._notification.error('Nomenclatura do Retorno é um campo obrigatório');
                    status = false;
                }
            }
        }

        if (this.conectividadeSelecionada.chave == 'WEB_SERVICE_EDI') {
            if (this.conectividadeForm['possuiCaixaPostalQual'] == 'SIM') {
                if (isEmpty(this.conectividadeForm['possuiCaixaPostalQual'])) {
                    this._notification.error('Caixa Postal é um campo obrigatório');
                    status = false;
                }
            }
        }

        if (!this.conectividadeForm && this.conectividadeSelecionada.isOutros && this.outros == '') {
            this._notification.error('Preencha os campos da conectividade');
            status = false;
        }

        if (this.conectividadeSelecionada.chave == 'API_OPEN_BANKING') {
            if (this.conectividadeForm['possuiConexaoApi'] == 'NAO') {
                if (isEmpty(this.conectividadeForm['urlCallback'])) {
                    this._notification.error('URL Callback é um campo obrigatório');
                    status = false;
                }
            }
        }

        if (this.conectividadeSelecionada.chave == 'WEB_DAV') {
            if (isEmpty(this.conectividadeForm['caixaPostal'])) {
                this._notification.error('Caixa Postal é um campo obrigatório');
                status = false;
            }
            if (isEmpty(this.conectividadeForm['caixaPostalSenha'])) {
                this._notification.error('Senha é um campo obrigatório');
                status = false;
            }
        }

        if (this.conectividadeSelecionada.chave == 'B2BI') {
            if (isEmpty(this.conectividadeForm['protocolo'])) {
                this._notification.error('Protocolo é um campo obrigatório');
                status = false;
            }
            if (isEmpty(this.conectividadeForm['idProtocolo'])) {
                this._notification.error('ID (Protocolo) é um campo obrigatório');
                status = false;
            }
            if (isEmpty(this.conectividadeForm['enderecoIp'])) {
                this._notification.error('Endereço IP é um campo obrigatório');
                status = false;
            }
            if (isEmpty(this.conectividadeForm['porta'])) {
                this._notification.error('Porta é um campo obrigatório');
                status = false;
            }
            if (isEmpty(this.conectividadeForm['senha'])) {
                this._notification.error('Senha é um campo obrigatório');
                status = false;
            }
        }


        return status;
    }

    gravar(): void {

        if (!this.validaConectividadeForm()) {
            return;
        }
        let tipoConectividade = '';
        switch (this.conectividadeSelecionada.chave) {
            case 'FINNET_FAST_CLIENT': tipoConectividade = 'FINNET FAST CLIENT RVS'; break;
            case 'CONNECT_DIRECT': tipoConectividade = 'CONNECT DIRECT'; break;
            case 'SFTP': tipoConectividade = 'SFTP'; break;
            case 'FAST_WEB': tipoConectividade = 'FAST WEB'; break;
            case 'API_OPEN_BANKING': tipoConectividade = 'API OPEN BANKING'; break;
            case 'WEB_DAV': tipoConectividade = 'WEB DAV'; break;
            case 'B2BI': tipoConectividade = 'B2BI'; break;
            case 'WEB_SERVICE_EDI': tipoConectividade = 'WEBSERVICE EDI'; break;
            case 'OUTRO': tipoConectividade = 'OUTROS'; break;
        }


        this._spinner.show();
        this._checklistConectividadeService
            .save(this.checklist.id, {
                id: this.conectividadeSelecionada.id,
                outros: this.outros,
                tipoConectividade,
                json: this.conectividadeForm ? JSON.stringify(this.conectividadeForm) : undefined
            })
            .subscribe(
                () => {
                    this.gravando.emit(true);
                    this.conectivadeFormPadrao = this.conectividadeForm;
                    this.sucess('Conectividade atualizada com sucesso');
                },
                (error) => this.error(error));
    }

    getConectividade(conectividadeForm: ModelBase) {
        if (!ObjectUtil.compare(conectividadeForm, this.conectividadeForm)) {
            this.conectividadeForm = Object.assign({}, conectividadeForm);
        }
    }

    mostraForm(conectividade: ChecklistConectividade, chave: string): boolean {
        let isViewForm = false;
        if (conectividade.chave == this.conectividadeSelecionada.chave && conectividade.chave === chave) {
            switch (conectividade.chave) {
                case 'FAST_WEB': isViewForm = true; break;
                case 'WEB_SERVICE_EDI': isViewForm = true; break;
                case 'API_OPEN_BANKING': isViewForm = true; break;
                case 'SFTP': isViewForm = true; break;
                case 'FINNET_FAST_CLIENT': isViewForm = true; break;
                case 'WEB_DAV': isViewForm = true; break;
                case 'CONNECT_DIRECT': isViewForm = true; break;
                case 'B2BI': isViewForm = true; break;
            }
        }

        return isViewForm;
    }

    mostaVisualizacaoConectividade(conectividade) {
        return this.isVisualizacao && conectividade.id === this.conectividadeSelecionada.id
    }

}

