import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, Injector, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { environment } from 'environments/environment';
import { Contrato } from '../../shared/interfaces/contrato.interface';
import { FiltroContrato } from '../../shared/interfaces/filtro-contrato.interface';
import { ContratoModalService } from '../../shared/services/contrato-modal.service';
import { ContratoService } from '../../shared/services/contrato.service';

@Component({
    selector: 'contrato-grid',
    templateUrl: './contrato-grid.component.html',
    styleUrls: ['./contrato-grid.component.scss']
})
export class ContratoGridComponent extends GridBase<Contrato, FiltroContrato, ContratoService> implements OnChanges, OnInit {

    selection = new SelectionModel<Contrato>(true, []);

    constructor(
        protected _service: ContratoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        private _modalContrato: ContratoModalService,
    ) {
        super(_service, _injector, _changeDetectorRefs, 'ContratoGrid')
    }

    ngOnInit(): void {
        this.filtro = this.filtro ? this.filtro : {};
        this.filtro.sortField = this.filtro.sortField ? this.filtro.sortField : 'dataCriacao';
        this.filtro.sortOrder = this.filtro.sortOrder ? this.filtro.sortOrder : 'desc';
        this.initGrid(this.filtro)
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.atualizarGrid(this.filtro || {}, () => this.selection.clear());
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'select' },
            { chave: 'seleciona' },
            { chave: 'status', label: 'Status do contrato' },
            { chave: 'situacao', label: 'Situação' },
            { chave: 'grupo', label: 'Grupo' },
            { chave: 'empresa', label: 'Nome da empresa' },
            { chave: 'inscricao', label: 'Inscrição' },
            { chave: 'numeroContrato', label: 'Contrato' },
            { chave: 'dataCriacao', label: 'Data de criação' },
            { chave: 'dataVigencia', label: 'Data da vigência' },
            { chave: 'numeroCotacao', label: 'Numero de cotação' },
        ]
    }

    visualizar(contrato: Contrato) {
        this._router.navigateByUrl(`contrato/${contrato.id}`)
    }

    editar() {
        const contratosSelecionadas = this.selection.selected.map((v) => v.id);
        this._modalContrato
            .cotacaoEditar(contratosSelecionadas)
            .subscribe((result) => {
                if (result) {
                    this.selection.clear()
                    this.atualizarGrid(this.filtro);
                }
            });
    }

    historico(contrato: Contrato) {
        this._modalContrato.historico(contrato);
    }

    documentos(contrato: Contrato) {
        this._modalContrato.documento(contrato);
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows =
            this.paginacao.pageSize <= this.paginacao.length
                ? this.paginacao.pageSize
                : this.paginacao.length;
        return numSelected === numRows && numSelected != 0 && numRows != 0;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.listaDataSource.data.forEach((row) =>
                this.selection.select(row)
            );
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Contrato): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }

    visualizarCotacao(idCotacao) {
        const link = `${environment.host}/cotacao/${idCotacao}`;
        this._router.navigate([]).then((result) => {
            window.open(link, '_blank');
        });
    }

}
