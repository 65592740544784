import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { ComponentBase } from '@utils/base/component-base/component-base';
import { Checklist } from 'app/main/checklist/shared/interfaces/checklist.interface';
import { ChecklistNavegationStep } from '../../shared/interfaces/checklist-navegation-step.interface';
import { ChecklistProdutoPortal } from '../../shared/interfaces/checklist-produto-portal.interface';
import { ChecklistNavegacaoService } from '../../shared/services/checklist-navegacao.service';
import { ChecklistService } from '../../shared/services/checklist.service';
import { ChecklistModalService } from '../../shared/services/checklist-modal.service';

@Component({
    selector: 'checklist-navigate-buttons',
    templateUrl: './checklist-navigate-buttons.component.html',
    styleUrls: ['./checklist-navigate-buttons.component.scss'],
})
export class ChecklistNavigateButtonsComponent
    extends ComponentBase
    implements OnInit {
    private _recarregar: boolean;
    private _isVisualizacao: boolean = false;
    private listSteps = [];
    private checklist: any;
    private steps: any[] = [];
    private produtosBUs: any = [];
    private isVisualizaProduto: any = true;

    @Output() navigate = new EventEmitter<[any, any]>();

    private stepsConfig = {
        contato: 'stepContato',
        'contato-empresa': 'stepContato',
        conectividade: 'stepConectividade',
        'dados-empresa': 'stepEmpresa',
        // adquirente: 'stepAdquirente',
        relacionamento: 'stepRelacionamento',
        'contato-banco': 'stepContatoBanco',
        // 'produtoFinanceiro': 'stepProdutoFinanceiro',
        // 'produto-financeiro': 'stepProdutoFinanceiro',
        configuracao: 'stepConfiguracao',
        finalizacao: 'finalizadoSteps',
        finalizacaoStep: 'finalizadoSteps',
    };

    constructor(
        private _checklistService: ChecklistService,
        private _checklistNavegacao: ChecklistNavegacaoService,
        private _injector: Injector,
        private _checklistModalService: ChecklistModalService,
    ) {
        super(_injector);
    }

    ngOnInit(): void {
        this._route.data.subscribe((data) => {
            this.checklist = data.checklist;
            this.produtosBUs = data.produtoPortal;
            this._isVisualizacao = data.isVisualizacao;
            this.buildSteps();
        });
    }

    get recarregar(): any {
        return this._recarregar;
    }

    @Input() set recarregar(value: any) {
        this._recarregar = value;
        this.recarregarMenu();
    }

    private recarregarMenu() {
        this._spinner.show();
        const idChecklist = this._route.snapshot.params.id;
        this._checklistService.findById(idChecklist).subscribe(
            (result) => {
                this._spinner.hide();
                this.checklist = result.data;
                this.buildSteps();
            },
            (error) => this.error(error)
        );
    }

    getCurrentStepIndex() {
        const current = (this._route.url as any)._value.slice(-1)[0].path;
        return this.listSteps.findIndex(
            ({ chave }) => {
                return current === chave ||
                    (current === 'contato' && chave === 'contato-empresa') ||
                    (current === 'produto-financeiro' && chave === 'produtoFinanceiro')
            }
        )
    }

    hasNext() {
        const index = this.getCurrentStepIndex();
        const next = this.listSteps[index + 1];
        return !!next && next.isUrlEnable;
    }

    hasPrev() {
        return true;
    }

    avancar() {
        const index = this.getCurrentStepIndex();
        const next = this.listSteps[index + 1];
        if (!!next && next.isUrlEnable) {
            const nav = () => this.seleciona(next);
            this.navigate.emit(
                [nav, () => this.openWarning(nav)]
            );
        }
    }

    voltar() {
        const index = this.getCurrentStepIndex();

        if (index === 0) {
            const nav = () => this._router.navigateByUrl('/checklist');
            this.navigate.emit([nav, () => this.openWarning(nav)]);
            return;
        }

        const prev = this.listSteps[index - 1];
        const nav = () => this.seleciona({ ...prev, isUrlEnable: true });

        this.navigate.emit([nav, () => this.openWarning(nav)]);
    }

    private openWarning(force) {
        this._checklistModalService.confirmChecklistEditExit(force)
    }

    private buildSteps() {

        this.listSteps = [];

        const stepsClass = {
            'contato-empresa': 'contato-empresa-disabled',
            conectividade: 'conectividade-disabled',
            'dados-empresa': 'dados-empresa-disabled',
            // adquirente: 'adquirente-disabled',
            relacionamento: 'relacionamento-disabled',
            'produto-financeiro': 'produtoFinanceiro-disabled',
            configuracao: 'configuracao-disabled',
            finalizacao: 'finalizacao-disabled',
            'finalizacao-step': 'finalizacao-step-disabled',
        };

        if (this.checklist.finalizadoSteps && this.produtosBUs) {
            this.isVisualizaProduto = true;
        }

        if (this._isVisualizacao) {
            this._checklistNavegacao
                .getListaStepsVisualizacao(
                    this.checklist.id,
                    this.checklist.criarConfiguracao
                )
                .forEach((step) => {
                    step.id = `${step.chave}`;
                    step.isUrlEnable = true;
                    if (
                        !['finalizacao', 'finalizacao-step'].includes(
                            step.chave
                        ) &&
                        !(
                            step.chave === 'produtoFinanceiro' &&
                            !this.checklist.stepProdutoFinanceiro
                        )
                    ) {
                        this.listSteps.push(step);
                    }
                });
        } else {
            let count = 0
            this._checklistNavegacao
                .getListaSteps(
                    this.checklist.id,
                    this.checklist.criarConfiguracao,
                    this.checklist
                )
                .forEach((step, index) => {
                    if (
                        (step.chave === 'finalizacao-step' &&
                            this.produtosBUs &&
                            this.produtosBUs.length === 0) || step.chave === 'adquirente' ||
                        (['produtoFinanceiro', 'produto-financeiro'].includes(step.chave) && !this.checklist.validaProdutoFinanceiro)
                    ) {
                        return;
                    }


                    const checked = this.checklist[
                        this.stepsConfig[step.chave]
                    ];

                    const chave = (this.listSteps.find((_, i) => count - 1 === i) || {}).chave;

                    const config = chave && this.stepsConfig[chave];

                    const isUrlEnable = checked || (config && this.checklist[config
                    ]);

                    this.listSteps.push({ ...step, isUrlEnable });

                    count++;
                });
        }
    }

    private getStep(chave: string) {
        return this._checklistNavegacao
            .getListaSteps(
                this.checklist.id,
                this.checklist.criarConfiguracao,
                this.checklist
            )
            .find((val) => val.chave === chave);
    }

    private getStepByOrdem(ordem: number) {
        return this._checklistNavegacao
            .getListaSteps(
                this.checklist.id,
                this.checklist.criarConfiguracao,
                this.checklist
            )
            .find((val) => val.ordem === ordem);
    }

    private getProximoStep(step: ChecklistNavegationStep): string {
        const totalSteps = this._checklistNavegacao.getListaSteps(
            this.checklist.id,
            this.checklist.criarConfiguracao,
            this.checklist
        ).length;
        let proximoStep: any = {};

        for (let index = step.ordem + 1; index < totalSteps; index++) {
            proximoStep = this.getStepByOrdem(index);

            if (this.isStepAtivo(proximoStep.chave)) {
                break;
            }
        }
        return proximoStep.chave || null;
    }

    private isStepAtivo(chave: string): boolean {
        switch (chave) {
            case 'contato-empresa':
                return true;
            case 'conectividade':
                return true;
            case 'dados-empresa':
                return true;
            case 'relacionamento':
                return true;
            // case 'adquirente':
            //     return true;
            case 'produto-financeiro':
            case 'produtoFinanceiro':
                return true;
            case 'configuracao':
                return true;
            case 'finalizacao':
                return true;
            case 'finalizacao-step':
                return true;
        }
        return false;
    }

    seleciona(step: ChecklistNavegationStep): void {
        if (step.isUrlEnable) {
            this._router.navigateByUrl(step.url);
        }
    }
}
