import { Component, Injector, OnInit } from '@angular/core';
import { FormBase } from '@utils/base/form-base/form-base';
import { Location } from '@angular/common';
import { ProdutoModuloCadastroBanco } from '../../shareds/interfaces/produto-modulo-cadastro-banco.interface';
import { BancoService } from '../../shareds/services/banco.service';
import { CadastroBanco } from '../../shareds/interfaces/cadastro-banco.interface';

@Component({
  selector: 'banco-cadastro',
  templateUrl: './banco-cadastro.component.html',
  styleUrls: ['./banco-cadastro.component.scss']
})
export class BancoCadastroComponent extends FormBase implements OnInit {

  isView: boolean = false;
  listaDataSource: Array<ProdutoModuloCadastroBanco> = [];

  constructor(
    private _location: Location,
    private _injector: Injector,
    private _service: BancoService
  ) {
    super(_injector, BancoCadastroCombos.combos());
  }

  ngOnInit() {
    if (/cadastro\/\:id\/visualizar/.test(this._route.snapshot.routeConfig.path)) {
      this.isView = true;
      this.form.clearValidators();
    }
    this._route.data.subscribe((data) => {
      this.optionList.listaProdutos = data.listaProdutos;
      this.optionList.listaModulos = data.listaModulos;
      if (data.dadosBanco) {
        this.setForm(data.dadosBanco);
      }
    });
    this.refreshOptionsConfig();
  }

  private setForm(dadosBanco: any) {
    let id = this._route.snapshot.params.id;
    this.form.patchValue({
      codigo: dadosBanco.codigo,
      nome: dadosBanco.nome,
      apelido: dadosBanco.apelido,
      sla: dadosBanco.sla
    });

    for (let index = 0; index < dadosBanco.bancoProdutosModulos.produto.length; index++) {
      const produto = dadosBanco.bancoProdutosModulos.produto[index];
      if (dadosBanco.bancoProdutosModulos.modulo[index] != undefined) {
        const modulo = dadosBanco.bancoProdutosModulos.modulo[index];
        this.listaDataSource.push({ idProduto: produto.id, produtoNome: produto.nome, idModulo: modulo.id, moduloNome: modulo.nome });
      }
    }

  }

  protected crateForm(): { [key: string]: any; } {
    return {
      codigo: [],
      nome: [],
      apelido: [],
      sla: [],
      produto: [],
      modulo: [],
    };
  }

  isSelectedProduto(): boolean {
    if (this.form.get('produto').value != undefined &&
      this.form.get('produto').value != null &&
      this.form.get('produto').value != '') {
      return false;
    }
    return true;
  }

  isSelectedProdutoModuo(): boolean {
    let i = 0;
    if (this.form.get('produto').value != undefined &&
      this.form.get('produto').value != null &&
      this.form.get('produto').value != '') {
      i++;
    }

    if (this.form.get('modulo').value != undefined &&
      this.form.get('modulo').value != null &&
      this.form.get('modulo').value != '') {
      i++;
    }

    if (i == 2) {
      return false;
    }

    return true;
  }


  addProdutoModulo(): void {
    const produto = this.form.get('produto').value;
    const modulo = this.form.get('modulo').value;

    let existe = this.listaDataSource.findIndex(item => (item.idProduto == produto.id && item.idModulo == modulo.id));
    if (existe < 0) {
      this.listaDataSource.push({
        idProduto: produto.id,
        produtoNome: produto.nome,
        idModulo: modulo.id,
        moduloNome: modulo.nome
      });
    } else {
      this._notification.error('Este produto e modulo já se encontra adicionado a lista.');
    }
  }

  deleteProduto(index: number): void {
    if (this.listaDataSource.length > 1) {
      this.listaDataSource.splice(index, 1);
    } else {
      this._notification.error('Você deve conter pelo menos um Produto e um Modulo junto a lista. Adicione outro produto e modulo para assim excluir este item.');
    }

  }

  salvar(): void {
    if (this.validateFields()) {
      this.executaSalvar();
    }
  }

  executaSalvar(): void {
    const id = this._route.snapshot.params.id;
    const codigo = this.form.get('codigo').value;
    const nome = this.form.get('nome').value;
    const sla = this.form.get('sla').value;

    const CadastroBanco: CadastroBanco = {
      id: id,
      codigo: codigo,
      nome: nome,
      sla: sla,
      produto: this.listaDataSource
    }
    this._service.save(CadastroBanco).subscribe(
      () => {
        this.sucess('Banco salvo com sucesso');
        this.limparForm();
      },
      (error) => this.error(error)
    );
  }

  limparForm() {
    this.listaDataSource = [];
    this.form.reset();
  }

  validateFields(): boolean {
    const codigo = this.form.get('codigo').value;
    const nome = this.form.get('nome').value;
    const sla = this.form.get('sla').value;

    if (codigo == undefined) {
      this._notification.error('Número do Banco e um campo obrigatório.');
      return false;
    } else {
      if (isNaN(codigo)) {
        this._notification.error('Número do Banco deve ser preechido somente com números.');
        return false;
      }
    }

    if (nome == undefined) {
      this._notification.error('Nome do Banco e um campo obrigatório.');
      return false;
    }

    if (sla == undefined) {
      this._notification.error('SLA e um campo obrigatório.');
      return false;
    } else {
      if (isNaN(sla)) {
        this._notification.error('SLA deve ser preechido somente com números.');
        return false;
      }
    }

    if (this.listaDataSource.length == 0) {
      this._notification.error('Você deve adicionar pelo menos um Produto e um Modulo junto a lista.');
      return false;
    }

    return true;
  }

  voltar(): void {
    this._location.back();
  }

}

export class BancoCadastroCombos {
  static combos() {
    return [
      {
        listName: 'listaProdutos',
        filterName: 'filterProduto',
        fieldValue: 'nome',
      },
      {
        listName: 'listaModulos',
        filterName: 'filterModulo',
        fieldValue: 'nome',
      }
    ];
  }
}