import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
// import { Gmud } from '../../shared/interfaces/gmud.interface';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GmudRecomendacoesService } from '../../shared/services/gmud-recomendacoes.service';
import { GmudRecomendacaoCadastro } from '../../shared/interfaces/gmud-recomendacao-cadastro.interface';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { ComponentBase } from '@utils/base/component-base/component-base';
import { UsuarioLogado } from '@utils/interfaces/usuario/usuario-logado.interface';
import { AuthLocalStorageService } from '@utils/services/auth-local-storage.service';

@Component({
    selector: 'modal-cadastro-recomendacao',
    templateUrl: './modal-cadastro-recomendacao.component.html',
    styleUrls: ['./modal-cadastro-recomendacao.component.scss'],
})
export class ModalCadastroRecomendacaoComponent extends ComponentBase implements OnInit {
    // @Output() close = new EventEmitter<any>();

    descricao: string = '';
    usuarioLogado: UsuarioLogado;

    constructor(
        private _injector: Injector,
        protected _service: GmudRecomendacoesService,
        private _authLocalStorage: AuthLocalStorageService,
        public dialogRef: MatDialogRef<ModalCadastroRecomendacaoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super(_injector);
        this.usuarioLogado = _authLocalStorage.getUsuario();
    }

    ngOnInit() { }

    fechar(): void {
        this.dialogRef.close();
        // this.close.emit(true)
    }

    gravar(): void {
        this._spinner.show();

        const payload: { usu_id: number, gmud_id: number, gmr_descricao: string } = {
            usu_id: this.usuarioLogado.id,
            gmud_id: this.data.id,
            gmr_descricao: this.descricao
        }

        this._service
            .gravar(payload)
            .subscribe(
                (result) => {
                    this.sucess('Recomendação salva com sucesso!');
                    // this.fechar()
                    this.descricao = ''
                    this.data.refetch.emit()
                },
                (error) => console.log({ error }));
    }
}






