import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Gmud } from '../../shared/interfaces/gmud.interface';
import { ComponentBase } from '@utils/base/component-base/component-base';

@Component({
    selector: 'gmud-cadastro-page',
    templateUrl: './gmud-cadastro-page.component.html',
    styleUrls: ['./gmud-cadastro-page.component.scss']
})
export class GmudCadastroPageComponent implements OnInit {

    gmud: Gmud

    constructor(private _router: Router) {
        // super(_injector)
    }

    ngOnInit() { }

    fechar() {
        this._router.navigateByUrl('gmud');
    }
}
