import { Component, OnInit } from '@angular/core';
import { FiltroAdquirente } from '../../shareds/interfaces/filtro-adquirente.interfaces';

@Component({
  selector: 'app-adquirente-grid-page',
  templateUrl: './adquirente-grid-page.component.html',
  styleUrls: ['./adquirente-grid-page.component.scss']
})
export class AdquirenteGridPageComponent implements OnInit {

  filtro: FiltroAdquirente;

  constructor() { }

  ngOnInit() {
  }

  filtrar(filtro: FiltroAdquirente) {
    this.filtro = Object.assign({}, filtro);
  }

}
