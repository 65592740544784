import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginAdminComponent } from './admin/login-admin/login-admin.component';
import { EmailConfirmacaoClienteComponent } from './cliente/email-confirmacao-cliente/email-confirmacao-cliente.component';
import { EsqueceuSenhaClienteComponent } from './cliente/esqueceu-senha-cliente/esqueceu-senha-cliente.component';
import { LoginClienteComponent } from './cliente/login-cliente/login-cliente.component';
import { RedefinirSenhaClienteComponent } from './cliente/redefinir-senha-cliente/redefinir-senha-cliente.component';
import { RedefinirSenhaClienteValidaDadosResolver } from './shared/resolvers/redefinir-senha-cliente-valida-dados.resolver';
import { PrimeiroAcessoClienteComponent } from './cliente/primeiro-acesso-cliente/primeiro-acesso-cliente.component';

const routes: Routes = [
    { path: 'admin/login', component: LoginAdminComponent },
    { path: 'login', component: LoginClienteComponent },
    { path: 'esqueceu-senha', component: EsqueceuSenhaClienteComponent },
    {
        path: 'email-confirmacao/:layout',
        component: EmailConfirmacaoClienteComponent,
    },
    {
        path: 'redefinir-senha',
        resolve: {
            redefinirSenha: RedefinirSenhaClienteValidaDadosResolver,
        },
        component: RedefinirSenhaClienteComponent,
    },
    {
        path: 'primeiro-acesso',
        resolve: {
            cadastrarSenha: RedefinirSenhaClienteValidaDadosResolver,
        },
        component: PrimeiroAcessoClienteComponent,
    },
    { path: '', redirectTo: '/auth/login', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule {}
