import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FormBase } from '@utils/base/form-base/form-base';
import { ChecklistContatoEmpresa } from '../../shared/interfaces/checklist-contato-empresa.interface';
import { Checklist } from '../../shared/interfaces/checklist.interface';
import { ChecklistContatoEmpresaService } from '../../shared/services/checklist-contato-empresa.service';
import { AuthLocalStorageService } from '@utils/services/auth-local-storage.service';
import { UsuarioLogado } from '@utils/interfaces/usuario/usuario-logado.interface';
import { ChecklistModalService } from '../../shared/services/checklist-modal.service';
import { Cargo } from '../../shared/interfaces/cargo.interface';
import { mergeMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'checklist-contato-empresa',
    templateUrl: './checklist-contato-empresa.component.html',
    styleUrls: ['./checklist-contato-empresa.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ChecklistContatoEmpresaComponent
    extends FormBase
    implements OnInit {

    @Input() checklist: Checklist;

    @Input() validate: (valid: boolean) => void;

    @Output() gravando = new EventEmitter();

    isVisualizacao: boolean = false;

    isEdit = false;
    indexEdit = {};
    firstTime: boolean = true;

    CELLPHONE = '(00) 0 0000-0000';
    LANDLINE = '(00) 0000-0000';

    phoneMask = this.LANDLINE;
    phoneNumber = '';
    previusLength = 0;
    usuarioCliente = false;
    contatos: Array<ChecklistContatoEmpresa> = [];
    usuarioLogado: UsuarioLogado;
    listaCargos: Array<Cargo> = [];
    isEditing = false;
    lists: {
        cargos: Cargo[]
    } = {
            cargos: []
        };

    constructor(
        private _injector: Injector,
        private _authLocalStorage: AuthLocalStorageService,
        private _checklistContatoEmpresaService: ChecklistContatoEmpresaService,
        private _modalChecklist: ChecklistModalService,
    ) {
        super(_injector);
        this.usuarioLogado = _authLocalStorage.getUsuario();
    }

    ngOnInit(): void {
        this._route.data.subscribe((data) => {
            this.contatos = data.contatos.contatos;
            this.isVisualizacao = data.isVisualizacao;
            this.listaCargos = data.cargo


            const filter = this.form.get('filterCargos');
            this.lists['cargos'] = this.listaCargos;
            filter.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    const { value } = filter;
                    if (!value) {
                        this.lists['cargos'] = [...this.listaCargos];
                        return;
                    }
                    const search = value.toLowerCase();
                    this.lists['cargos'] =
                        this.listaCargos.filter(result => result.descricao.toLowerCase().indexOf(search) > -1)
                });

        });

        if (this.usuarioLogado.perfis[0].nome === 'Cliente') {
            this.usuarioCliente = true;
        }
    }

    ngOnChanges({ validate }) {
        if (this.firstTime) {
            this.firstTime = false;
            return;
        }

        const valid = !Object.values(this.form.controls).some((control) => control.dirty) && !this.contatos.some((contato) => !contato.id)

        validate.currentValue(valid)
    }

    checklistEmAndamento(checklist: Checklist): boolean {
        return checklist.checklistStatus.id !== 4 && checklist.checklistStatus.id !== 5;
    }

    sendEmailsChecklist(checklist: Checklist, email: string): void {
        const data = this._checklistContatoEmpresaService.findByChecklist(checklist.id);
        data.pipe(
            mergeMap(res => this._modalChecklist.confirmSendEmails(
                res.data.contatos.filter(contato => contato.email === email),
                checklist.id
            ))
        ).subscribe(result => {
            if (result) {
                const emails = result.data.emails.join(', ');
                this.sucess(`${result.message}: \n ${emails}`);
            }
        }, error => {
            console.error('Erro ao buscar dados da API:', error);
        }
        );
    }

    protected crateForm(): { [key: string]: any } {
        return {
            nome: ['', Validators.required],
            telefone: ['', [Validators.required, Validators.pattern(/^\d{9}$|^\d{11}$/)]],
            email: ['', [Validators.required, Validators.email,
            this.uniqueValidator(), Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
            ]],
            cargo: ['', Validators.required],
            principal: [],
            index: [],
            filterCargos: []
        };
    }


    uniqueValidator(): ValidatorFn | null {

        const validation = (
            control: AbstractControl
        ): ValidationErrors | null => {
            const lengthValue = this.isEdit ? 1 : 0;
            if (!this.form) return null;
            if (
                this.contatos.filter(
                    (contato: ChecklistContatoEmpresa) =>
                        contato.email === control.value

                ).length > 0
            ) {
                return { notUnique: true };
            } else {
                return null;
            }
        };
        return validation.bind(this);
    }

    limparForm(): void {
        this.isEdit = false;
        this.isEditing = false;
        this.form.get('telefone').setValue(' ');
        this.form.reset();
        this.form.controls.email.enable()
    }

    adicionar(): void {
        const contato: ChecklistContatoEmpresa = this.form.value;
        if (this.contatos.length === 0 && !contato.principal) {
            contato.principal = true;
        }
        this.contatos.push(contato);
        this.limparForm();
        this.gravar()
    }

    atualizar(): void {
        this.form.controls.email.enable()
        this.isEditing = false;
        const index = this.form.get('index').value;
        this.contatos[index] = this.form.value;
        this.limparForm();
        this.gravar()
    }

    remover(contato: ChecklistContatoEmpresa, index: number): void {
        this._confirmation
            .confirm({ titulo: `Deseja remover o contato?` })
            .subscribe((result) => {
                if (result) {
                    this.contatos.splice(index, 1);
                }
                this.gravar()
            });
    }


    editar(contato: ChecklistContatoEmpresa, index: number): void {
        this.isEditing = true;
        this.isEdit = true;
        if (contato.telefone && contato.telefone.length === 11) {
            this.phoneMask = this.CELLPHONE;
        }
        const cargo = this.listaCargos.find((cargo) => cargo.descricao === contato.cargo) || {}
        this.form.patchValue({ ...contato, cargo: cargo.descricao });
        this.form.controls.email.disable()
        this.form.get('index').setValue(index);
    }

    atualizarPrincipal(index: number): void {
        this.contatos.forEach((c) => (c.principal = false));
        this.contatos[index].principal = true;
    }

    gravar(): void {
        if (!this.validaContatos()) {
            this._notification.error('Selecione um contato como principal');
            return;
        }

        this._spinner.show();
        this._checklistContatoEmpresaService
            .save(this.checklist.id, this.contatos)
            .subscribe(
                ({ data }) => {
                    this.contatos = (data as any).contatos;
                    this.gravando.emit(true);
                    this.sucess('Contatos salvos com sucesso!');
                    this.limparForm();
                },
                (error) => this.error(error));
    }

    validaContatos(): boolean {
        let isValid = false;

        this.contatos.forEach((contato) => {
            if (!contato.principal) {
                contato.principal = false;
            } else {
                isValid = true;
            }
        });
        return isValid;
    }

    onPhoneChanged() {
        this.phoneMask = this.LANDLINE;
        if (
            this.phoneNumber &&
            this.phoneNumber.length >= 10 &&
            this.phoneMask >= this.LANDLINE &&
            this.previusLength >= 10
        ) {
            this.phoneMask = this.CELLPHONE;
        }

        this.previusLength = this.phoneNumber ? this.phoneNumber.length : 0;
    }
}
