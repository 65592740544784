import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, Injector, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { Adquirente } from '../../shareds/interfaces/adquirente.interfaces';
import { FiltroAdquirente } from '../../shareds/interfaces/filtro-adquirente.interfaces';
import { AdquirenteService } from '../../shareds/services/adquirente.service';

@Component({
  selector: 'adquirente-grid',
  templateUrl: './adquirente-grid.component.html',
  styleUrls: ['./adquirente-grid.component.scss']
})
export class AdquirenteGridComponent extends GridBase<Adquirente, FiltroAdquirente, AdquirenteService> implements OnInit, OnChanges {
  selection = new SelectionModel<Adquirente>(true, []);

  constructor(
    protected _service: AdquirenteService,
    protected _injector: Injector,
    protected _changeDetectorRefs: ChangeDetectorRef,
  ) {
    super(_service, _injector, _changeDetectorRefs, 'AdquirenteGridComponent');
  }

  ngOnInit() {
    this.initGrid(this.filtro || {});
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.atualizarGrid(this.filtro || {});
  }

  configDisplayedColumns(): GridCollumn[] {
    return [
      { chave: 'select' },
      { chave: 'seleciona' },
      { chave: 'nome', label: 'Nome' },
    ];
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows =
      this.paginacao.pageSize <= this.paginacao.length
        ? this.paginacao.pageSize
        : this.paginacao.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.listaDataSource.data.forEach((row) =>
        this.selection.select(row)
      );
  }

  checkboxLabel(row?: Adquirente): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1
      }`;
  }

  criar() {
    this._router.navigateByUrl(`adquirente/cadastro`);
  }

  editar(adquirente: Adquirente) {
    this._router.navigateByUrl(`adquirente/cadastro/${adquirente.id}/editar`);
  }

  visualizar(adquirente: Adquirente) {
    this._router.navigateByUrl(`adquirente/cadastro/${adquirente.id}/visualizar`);
  }

  excluir(adquirente: Adquirente) {
    this._confirmation
      .confirm({
        titulo: 'Deseja excluir o Adquirente?',
        mensagem:
          'Ao remover o Adquirente o mesmo não ficará mais disponível',
      })
      .subscribe((result) => {
        if (result) {
          this._service.delete(adquirente.id).subscribe(
            () => {
              this.atualizarGrid();
              this.sucess('Adquirente removido com sucesso!');
            },
            (error) => this.error(error)
          );
        }
      });

  }

}
