import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { FormFiltroBase } from '@utils/base/form-filtro-base/form-filtro-base';
import { FiltroAdquirente } from '../../shareds/interfaces/filtro-adquirente.interfaces';

@Component({
  selector: 'adquirente-grid-filtro',
  templateUrl: './adquirente-grid-filtro.component.html',
  styleUrls: ['./adquirente-grid-filtro.component.scss']
})
export class AdquirenteGridFiltroComponent extends FormFiltroBase<FiltroAdquirente> implements OnInit {

  @Output() filtrar: any = new EventEmitter<FiltroAdquirente>();

  constructor(injector: Injector) {
    super('AdquirenteGrid', injector);
  }

  camposFiltro(): string[] {
    return ['nome'];
  }
  protected crateForm(): { [key: string]: any; } {
    return {
      nome: [],
    };
  }

  ngOnInit() {
    this._route.data.subscribe((data) => {
      this.refreshOptionsConfig();
    });
  }

  limparFiltro(): void {
    this.limpar();
  }

  filtrarExecEnter(event): void {
    if (event.keyCode === 13) {
      this.filtrarExec();
    }
  }

  filtrarExec(): void {
    const adquirenteNome = this.form.get('nome').value;

    const filtro: FiltroAdquirente = {
      nome: adquirenteNome,
    }

    this.filtrar.emit(filtro);
  }

}
