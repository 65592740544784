import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FiltroCotacao } from '../interfaces/filtro-cotacao.interface';
import { Cotacao } from '../interfaces/cotacao.interface';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { CotacaoStatus } from '../interfaces/cotacao-status.interface';
import { Contrato } from 'app/main/contrato/shared/interfaces/contrato.interface';
import { CotacaoContato } from '../interfaces/cotacao-contato.interface';
import { CotacaoKickoff } from '../interfaces/cotacao-kickoff.interface';
import { CotacaoProjeto } from '../interfaces/cotacao-projeto.interface';

@Injectable({
    providedIn: 'root',
})
export class CotacaoService extends ServiceGridBase<Cotacao, FiltroCotacao> {
    private path = `${environment.server}/cotacao`;
    private pathUsuario = `${environment.server}/usuario`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro: FiltroCotacao): Observable<Response<Array<Cotacao>>> {
        const params: string = this.toGetParams(filtro);
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<Cotacao>>>(url);
    }

    findStatus(): Observable<Response<Array<CotacaoStatus>>> {
        const url = `${this.path}/status`;
        return this._http.get<Response<Array<CotacaoStatus>>>(url);
    }

    findById(id: number | string): Observable<Response<Cotacao>> {
        const url = `${this.path}/${id}/completo`;
        return this._http.get<Response<Cotacao>>(url);
    }

    findProdutoFinanceiroCotacao(id: number | string): Observable<Response<Cotacao>> {
        const url = `${this.path}/${id}/produtoFinanceiro`;
        return this._http.get<Response<Cotacao>>(url);
    }

    update(cotacao: Cotacao): Observable<Response<Cotacao>> {
        const url = `${this.path}/${cotacao.id}`;
        return this._http.put<Response<Cotacao>>(url, cotacao);
    }

    gerarRelatorio(filtro: FiltroCotacao): Observable<Response<any>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${environment.server}/relatorio/cotacao${params}`;
        return this._http.get<Response<any>>(url);
    }

    sendAccess(id: number | string) {
        const url = `${this.pathUsuario}/reenviar-link-primeiro-acesso`;
        console.log(id);
        var reenvio = {
            idCotacao: id,
        };
        return this._http.post<Response<any>>(url, reenvio);
    }

    criarContrato(contrato: Contrato) {
        const params: string = contrato ? this.toGetParams(contrato) : '';
        const url = `${environment.server}/relatorio/cotacao${params}`;
        return this._http.post<Response<any>>(url, contrato);
    }

    isContratos(idsCotacoes: Array<number>): Observable<Response<any>> {
        const idsCotacoesStr = idsCotacoes.join(',');
        const url = `${environment.server}/cotacaovalidacontrato?cotacoes=${idsCotacoesStr}`;
        return this._http.get<Response<any>>(url);
    }

    getContatos(id: number | string): Observable<Response<[CotacaoContato]>> {
        const url = `${this.path}/${id}/contatos`;
        return this._http.get<Response<[CotacaoContato]>>(url);
    }
    getKickoff(id: number | string): Observable<Response<CotacaoKickoff>> {
        const url = `${this.path}/${id}/kickoff`;
        return this._http.get<Response<CotacaoKickoff>>(url);
    }

    getProjetos(id: number | string): Observable<Response<[CotacaoProjeto]>> {
        const url = `${this.path}/${id}/projetos`;
        return this._http.get<Response<[CotacaoProjeto]>>(url);
    }

    salvarKickOff(
        id: number,
        kickoff: CotacaoKickoff
    ): Observable<Response<void>> {
        const url = `${this.path}/${id}/kickoff`;
        return this._http.put<Response<void>>(url, kickoff);
    }

    criarContato(id: number, contatos: any[]): Observable<Response<void>> {
        const url = `${this.path}/${id}/contatos`;
        return this._http.post<Response<void>>(url, contatos);
    }
    atualizarContato(id: number, contatos: any[]): Observable<Response<void>> {
        const url = `${this.path}/${id}/contatos`;
        return this._http.put<Response<void>>(url, contatos);
    }
    removerContato(id: number): Observable<Response<void>> {
        const url = `${this.path}/contatos/${id}`;
        return this._http.delete<Response<void>>(url);
    }
}
