import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const senhaForteValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent || !control) {
        return null;
    }

    const senha: string = control.parent.get('senha').value;

    if (
        /[0-9a-zA-Z!@#$%&*]{8,12}/.test(senha) &&
        /(?=.*[a-z])/.test(senha) &&
        /(?=.*[A-Z])/.test(senha) &&
        /(?=.*[!@#$%&*])/.test(senha) &&
        /(?=.*\d)/.test(senha)
    ) {
        return null;
    }

    return {
        senhaForte: {
            parsedDomain: true,
        },
    };
};

export const missingNumericValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const pattern: RegExp = /\d/;
    if (pattern.test(control.value)) { return null; }
    return { missingNumericCharacter: true };
};

export const missingSpecialCharacterValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const pattern: RegExp = /[!@#$%&*]/;
    if (pattern.test(control.value)) { return null; }
    return { missingSpecialCharacter: true };
};

export const lengthBetweenValidator = (min: number, max: number): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value.length >= min && control.value.length <= max) { return null; }
        return { lengthBetween: true };
    };
};
