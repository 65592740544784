import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@utils/utils.module';
import { GmudRoutingModule } from './gmud-routing.module';
import { GmudGuard } from './gmud.guard';
import { GmudGridComponent } from './components/gmud-grid/gmud-grid.component';
import { GmudGridPageComponent } from './pages/gmud-grid-page/gmud-grid-page.component';
import { GmudGridFiltroComponent } from './components/gmud-grid-filtro/gmud-grid-filtro.component';
import { GmudResolver } from './shared/resolvers/gmud.resolver';
import { GmudService } from './shared/services/gmud.service';
import { ModalHistoricoDetalheGmudComponent } from './modal/modal-historico-detalhe-gmud/modal-historico-detalhe-gmud.component';
import { ModalHistoricoGmudComponent } from './modal/modal-historico-gmud/modal-historico-gmud.component';
import { GmudHistoricoComponent } from './components/gmud-historico/gmud-historico.component';

import { GmudRecomendacoesComponent } from './components/gmud-recomendacoes/gmud-recomendacoes.component';
import { GmudRecomendacaoDetalheComponent } from './components/gmud-recomendacao-detalhe/gmud-recomendacao-detalhe.component';
import { GmudCadastroConsideracaoComponent } from './components/gmud-cadastro-consideracao/gmud-cadastro-consideracao.component';
import { ModalRecomendacaoGmudComponent } from './modal/modal-recomendacao-gmud/modal-recomendacao-gmud.component';
import { ModalRecomendacaoDetalheGmudComponent } from './modal/modal-recomendacao-detalhe-gmud/modal-recomendacao-detalhe-gmud.component';
import { ModalCadastroRecomendacaoComponent } from './modal/modal-cadastro-recomendacao/modal-cadastro-recomendacao.component';
import { ModalCadastroConsideracaoGmudComponent } from './modal/modal-cadastro-consideracao-gmud/modal-cadastro-consideracao-gmud.component';

// import { ModalRecomendacoesGmudComponent } from './modal/modal-recomendacoes-gmud/modal-recomendacoes-gmud.component';

import { GmudHistoricoDetalheComponent } from './components/gmud-historico-detalhe/gmud-historico-detalhe.component';
import { GmudHistoricoService } from './shared/services/gmud-historico.service';
// import { GmudImpStatusService } from './shared/services/gmud-imp-status.service';
import { GmudModalService } from './shared/services/gmud-modal.service';
import { GmudProdutoService } from './shared/services/gmud-produto.service';
import { GmudStatusService } from './shared/services/gmud-status.service';
// import { GmudAmbienteListaResolver } from './shared/resolvers/gmud-ambiente-lista.resolver';
// import { GmudImpStatusListaResolver } from './shared/resolvers/gmud-imp-status-lista.resolver';
import { GmudProdutoListaResolver } from './shared/resolvers/gmud-produto-lista.resolver';

import { GmudStatusListaResolver } from './shared/resolvers/gmud-status-lista.resolver';
import { GmudTipoMudancaListaResolver } from './shared/resolvers/gmud-tipo-mudanca-lista.resolver';

import { GmudCadastroComponent } from './components/gmud-cadastro/gmud-cadastro.component';
import { GmudAbaImpComponent } from './components/gmud-aba-imp/gmud-aba-imp.component';
import { GmudAbaGmudComponent } from './components/gmud-aba-gmud/gmud-aba-gmud.component';
import { GmudAbaDocumentacaoComponent } from './components/gmud-aba-documentacao/gmud-aba-documentacao.component';
import { GmudAbaAprovacaoComponent } from './components/gmud-aba-aprovacao/gmud-aba-aprovacao.component';
import { GmudCadastroPageComponent } from './pages/gmud-cadastro-page/gmud-cadastro-page.component';
import { GmudDocumentoUploadService } from './shared/services/gmud-documento-upload.service';
import { GmudDocumentoDownloadService } from './shared/services/gmud-documento-download.service';
import { GmudConfigurarAprovacaoComponent } from './components/gmud-configurar-aprovacao/gmud-configurar-aprovacao.component';
import { GmudConfiguracaoAprovacaoService } from './shared/services/gmud-configuracao-aprovacao.service';
import { GmudConfiguracaoAprovacaoPageComponent } from './pages/gmud-configuracao-aprovacao-page/gmud-configuracao-aprovacao-page.component';
import { GmudImpService } from './shared/services/gmud-imp.service';
import { GmudFluxoService } from './shared/services/gmud-fluxo.service';
import { GmudFluxoResolver } from './shared/resolvers/gmud-fluxo.resolver';
import { GmudDetalheResolver } from './shared/resolvers/gmud-detalhe.resolver';
import { GmudHistoricoAprovacaoService } from './shared/services/gmud-historico-aprovacao.service';
import { GmudHistoricoAprovacaoResolver } from './shared/resolvers/gmud-historico-aprovacao.resolver';
import { GmudTipoListaResolver } from './shared/resolvers/gmud-tipo-lista.resolver';
import { GmudJustificativaListaResolver } from './shared/resolvers/gmud-justificativa-lista.resolver';
import { GmudUrgenciaListaResolver } from './shared/resolvers/gmud-urgencia-lista.resolver';
import { GmudImpactoListaResolver } from './shared/resolvers/gmud-impacto-lista.resolver';
import { GmudExitoListaResolver } from './shared/resolvers/gmud-exito-lista.resolver';
import { GmudSolucaoListaResolver } from './shared/resolvers/gmud-solucao-lista.resolver';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { GmudHistoricoResolver } from './shared/resolvers/gmud-historico.resolver';
import { MatMenuModule } from '@angular/material/menu';
import { ModalRelatoriosComponent } from './modal/modal-relatorios/modal-relatorios.component';

@NgModule({
    declarations: [

        // PAGES
        GmudGridPageComponent,
        GmudCadastroPageComponent,

        //COMPONENTS
        GmudGridComponent,
        GmudGridFiltroComponent,

        GmudRecomendacoesComponent,
        GmudRecomendacaoDetalheComponent,
        GmudCadastroConsideracaoComponent,

        GmudHistoricoComponent,
        GmudHistoricoDetalheComponent,

        GmudConfigurarAprovacaoComponent,

        //MODAIS
        ModalHistoricoDetalheGmudComponent,
        ModalHistoricoGmudComponent,
        ModalRecomendacaoGmudComponent,
        ModalRecomendacaoDetalheGmudComponent,
        ModalCadastroRecomendacaoComponent,
        ModalRelatoriosComponent,
        ModalCadastroConsideracaoGmudComponent,
        // ModalRecomendacoesGmudComponent,
        GmudCadastroComponent,
        GmudAbaImpComponent,
        GmudAbaGmudComponent,
        GmudAbaDocumentacaoComponent,
        GmudAbaAprovacaoComponent,
        GmudConfiguracaoAprovacaoPageComponent,

    ],
    imports: [
        CommonModule,
        UtilsModule,
        GmudRoutingModule,
        NgxMatSelectSearchModule,

        // TABLE
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTooltipModule,

        MatMenuModule,

        // FORMULARIO
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatRadioModule,
        NgxMatSelectSearchModule,

    ],
    providers: [
        // GURD
        GmudGuard,

        // RESOLVER
        GmudResolver,
        // GmudAmbienteListaResolver,
        // GmudImpStatusListaResolver,
        GmudTipoMudancaListaResolver,
        GmudHistoricoResolver,
        GmudProdutoListaResolver,
        GmudStatusListaResolver,
        GmudTipoListaResolver,
        GmudFluxoResolver,
        GmudDetalheResolver,
        GmudHistoricoAprovacaoResolver,
        GmudTipoListaResolver,
        GmudJustificativaListaResolver,
        GmudUrgenciaListaResolver,
        GmudImpactoListaResolver,
        GmudExitoListaResolver,
        GmudSolucaoListaResolver,

        // SERVICE
        GmudService,
        GmudHistoricoService,
        // GmudImpStatusService,
        GmudProdutoService,
        GmudStatusService,
        GmudDocumentoUploadService,
        GmudDocumentoDownloadService,
        GmudConfiguracaoAprovacaoService,
        GmudImpService,
        GmudFluxoService,
        GmudHistoricoAprovacaoService,

        // MODAL
        GmudModalService,
    ],
    entryComponents: [
        ModalHistoricoDetalheGmudComponent,
        ModalHistoricoGmudComponent,

        ModalRecomendacaoGmudComponent,
        ModalRecomendacaoDetalheGmudComponent,
        ModalCadastroRecomendacaoComponent,
        ModalRelatoriosComponent,
        ModalCadastroConsideracaoGmudComponent
        // ModalRecomendacoesGmudComponent,
    ],
})
export class GmudModule { }
