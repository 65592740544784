import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { FiltroChecklistAdquirente } from '../interfaces/filtro-checklist-adquirente.interface';
import { ChecklistAdquirente } from '../interfaces/checklist-adquirente.interface';

@Injectable({
    providedIn: 'root',
})
export class ChecklistAdquirenteService extends ServiceBase<ChecklistAdquirente> {
    private path = `${environment.server}/checklist`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(
        filtro?: FiltroChecklistAdquirente
    ): Observable<Response<Array<ChecklistAdquirente>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}/${params}`;
        return this._http.get<Response<Array<ChecklistAdquirente>>>(url);
    }

    findByChecklist(
        idChecklist: number,
        filtro?: FiltroChecklistAdquirente
    ): Observable<Response<Array<ChecklistAdquirente>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}/${idChecklist}/adquirentes/${params}`;
        return this._http.get<Response<Array<ChecklistAdquirente>>>(url);
    }

    save(
        idChecklist: number,
        relacionamentos: Array<ChecklistAdquirente>
    ): Observable<Response<Array<ChecklistAdquirente>>> {
        const url = `${this.path}/${idChecklist}/adquirentes`;
        const adquirentes = relacionamentos.map(r => {
            return {
                idAdquirente: r.idAdquirente,
                idEmpresa: r.idEmpresa
            }
        })
        return this._http.put<Response<Array<ChecklistAdquirente>>>(url, {
            adquirentes
        });
    }
}
