import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { CotacaoItemCotacaoService } from '../../shared/services/cotacao-item-cotacao.service';
import { environment } from 'environments/environment';
import { CotacaoService } from '../../shared/services/cotacao.service';
import { DataUtil } from '@utils/utils/class/data.util';
import { CotacaoProjeto } from '../../shared/interfaces/cotacao-projeto.interface';
import { CotacaoContato } from '../../shared/interfaces/cotacao-contato.interface';
import { CotacaoKickoff } from '../../shared/interfaces/cotacao-kickoff.interface';
import { ComponentBase } from '@utils/base/component-base/component-base';
import { Cargo } from 'app/main/checklist/shared/interfaces/cargo.interface';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';

@Component({
    selector: 'cotacao-projeto',
    templateUrl: './cotacao-projeto.component.html',
    styleUrls: ['./cotacao-projeto.component.scss'],
})
export class CotacaoProjetoComponent extends ComponentBase implements OnInit {
    form: FormGroup;
    projetos: Array<CotacaoProjeto> = [];
    contatos: Array<CotacaoContato> = [];
    contatosParaDelecao: Array<number> = [];
    notifica = false;
    kickoff: CotacaoKickoff;

    CELLPHONE = '(00) 0 0000-0000';
    LANDLINE = '(00) 0000-0000';

    phoneMask = this.LANDLINE;
    phoneNumber = '';
    previusLength = 0;

    isEditKickoff: boolean = false;

    _onDestroy = new Subject<void>();
    listaCargos: Array<Cargo> = [];
    lists: {
        cargos: Cargo[]
    } = {
            cargos: []
        };

    public optionList: any = {};
    public filteredList: Array<ReplaySubject<Array<Object>>> = [];


    constructor(
        protected _injector: Injector,
        protected _service: CotacaoItemCotacaoService,
        protected _changeDetectorRefs: ChangeDetectorRef,
        protected _formBuilder: FormBuilder,
        private _cotacaoService: CotacaoService,
    ) {
        super(_injector);
    }

    setNotifica({ source }) {
        this.notifica = source._checked;
    }

    kickRealizadoAlterado({ source }) {
        if ('0' === source._value || source === '0') {
            this.form.get('kickOffComentario').disable();
            this.form.get('kickOffComentario').setValue('');
            this.form.get('kickOffComentario').setValidators([]);
            this.form.get('kickOffComentario').updateValueAndValidity();
            this.form.get('kickOffData').disable();
            this.form.get('kickOffData').setValue('');
            this.form.get('kickOffData').setValidators([]);
            this.form.get('kickOffData').updateValueAndValidity();
            return;
        }

        this.form.get('kickOffComentario').enable();
        this.form.get('kickOffComentario').setValidators([Validators.required]);
        this.form.get('kickOffComentario').updateValueAndValidity();
        this.form.get('kickOffData').enable();
        this.form.get('kickOffData').setValidators([Validators.required]);
        this.form.get('kickOffData').updateValueAndValidity();
    }

    adicionarContato() {
        (this.form.get('contatos') as FormArray).push(
            this._formBuilder.group(this.createFormContato())
        );
    }

    atualizarContato(index: number, attribute: string, event) {
        this.contatos[index][attribute] = event.target.value;
    }

    removerContato(index: number) {
        const contato = (this.form.get('contatos') as FormArray).at(index);

        if (contato.get('id').value) {
            this.contatosParaDelecao.push(contato.get('id').value);
        }

        (this.form.get('contatos') as FormArray).removeAt(index);
    }

    async ngOnInit() {




        this._route.data.subscribe(async (data) => {
            this.projetos = data.projeto;
            // this.contatos = data.contato;
            await new Promise<void>((resolve, reject) =>
                this._cotacaoService
                    .getContatos(data.cotacao.id)
                    .subscribe(({ data }) => {
                        this.contatos = data;
                        resolve();
                    }, reject)
            )
            this.kickoff = data.kickoff;
            this.kickoff = data.kickoff;
            this.listaCargos = data.listaCargos;
            this.form = this._formBuilder.group(
                this.createForm(this.contatos)
            );
            this.setForm(this.kickoff, this.contatos);
            this.kickRealizadoAlterado({
                source: this.kickoff.kickOffRealizado ? '1' : '0',
            });

            const filter = this.form.get('filterCargos');
            this.lists['cargos'] = this.listaCargos;

            filter.valueChanges
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    const { value } = filter;
                    if (!value) {
                        this.lists['cargos'] = [...this.listaCargos];
                        return;
                    }
                    const search = value.toLowerCase();
                    this.lists['cargos'] =
                        this.listaCargos.filter(result => result.descricao.toLowerCase().indexOf(search) > -1)
                });

        });

    }


    getChecked(i: number): boolean {
        if (this.contatos[i] && this.contatos[i].principal == true) {
            return this.contatos[i].principal
        }
        return false
    }

    atualizarContatoPrincipal(i: number): void {
        this.contatos.forEach((c) => (c.principal = false));
        this.contatos[i].principal = true;
    }

    selectedOption(o1: any, o2: any) {
        return o1.id == o2.id;
    }

    selectedOptionDescricao(o1: any, o2: any) {
        return o1 == o2;
    }

    selectedOptionBase(o1: any, o2: any) {
        return o1 == o2;
    }

    protected createForm(contatos: CotacaoContato[]): { [key: string]: any } {
        return {
            contatos: this._formBuilder.array(
                contatos.map(() =>
                    this._formBuilder.group(this.createFormContato(true))
                )
            ),
            kickOffData: [],
            kickOffRealizado: [0, Validators.required],
            kickOffComentario: [],
            filterCargos: [],
        };
    }

    protected createFormContato(update = false) {
        return {
            nome: ['', Validators.required],
            id: (update && ['', Validators.required]) || [],
            telefone: ['', Validators.required],
            principal: 0,
            email: [
                '',
                [Validators.required, Validators.email, this.uniqueValidator()],
            ],
            cargo: ['', Validators.required],
            filterCargos: [],
        };
    }

    uniqueValidator(): ValidatorFn | null {
        const validation = (
            control: AbstractControl
        ): ValidationErrors | null => {
            if (!this.form) return null;

            if (
                (this.form.get('contatos') as FormArray).controls.filter(
                    (group: FormGroup) =>
                        group.controls.email !== control &&
                        group.value.email === control.value
                ).length > 0
            ) {
                return { notUnique: true };
            } else {
                return null;
            }
        };
        return validation.bind(this);
    }

    setForm(kickoff: CotacaoKickoff, contatos: CotacaoContato[]) {
        this.form.patchValue({
            kickOffData: DataUtil.toDateBackend(kickoff.kickOffData),
            kickOffRealizado: kickoff.kickOffRealizado ? '1' : '0',
            kickOffComentario: kickoff.kickOffComentario,
        });
        this.form.get('contatos').patchValue(contatos);
    }

    salvar() {
        this._spinner.show();

        const contatos = {
            atualizar: [],
            criar: [],
        };

        (this.form.get('contatos') as FormArray).controls.forEach((group) => {
            const cPrincipal = this.contatos.find((c) => (c.id === group.get('id').value));
            const contato = {
                cargo: group.get('cargo').value,
                email: group.get('email').value,
                nome: group.get('nome').value,
                principal: cPrincipal ? cPrincipal.principal : 0,
                telefone: group.get('telefone').value,
                notifica: false,
            };

            if (!group.get('id').value) {
                contatos.criar.push(contato);
                return;
            }

            contatos.atualizar.push({
                ...contato,
                id: group.get('id').value,
                notifica: this.notifica,
            });
        });

        this._route.params.subscribe(async (params) => {
            try {
                const promises = [
                    new Promise((resolve, reject) =>
                        this._cotacaoService
                            .salvarKickOff(params['id'], {
                                kickOffData: DataUtil.toDateBackend(
                                    this.form.get('kickOffData').value
                                ),
                                kickOffRealizado:
                                    '1' ===
                                    this.form.get('kickOffRealizado').value,
                                kickOffComentario: this.form.get(
                                    'kickOffComentario'
                                ).value,
                            })
                            .subscribe(resolve, reject)
                    ),
                ];
                if (this.contatosParaDelecao.length >= 1) {
                    await new Promise((resolve, reject) =>
                        this.contatosParaDelecao.map((id) => {
                            this._cotacaoService
                                .removerContato(id)
                                .subscribe(resolve, reject);
                        })
                    );
                }
                if (contatos.atualizar.length) {
                    promises.push(
                        new Promise((resolve, reject) =>
                            this._cotacaoService
                                .atualizarContato(
                                    params['id'],
                                    contatos.atualizar
                                )
                                .subscribe(resolve, reject)
                        )
                    );
                }

                await Promise.all(promises);

                if (contatos.criar.length) {
                    await new Promise((resolve, reject) =>
                        this._cotacaoService
                            .criarContato(params['id'], contatos.criar)
                            .subscribe(resolve, reject)
                    );
                }

                await Promise.all([
                    new Promise<void>((resolve, reject) =>
                        this._route.params.subscribe(({ id }) =>
                            this._cotacaoService
                                .getContatos(id)
                                .subscribe(({ data }) => {
                                    this.contatos = data;
                                    this.form.get('contatos').patchValue(data);
                                    resolve();
                                }, reject)
                        )
                    ),
                ]);
                this.sucess('Salvo com sucesso!');
                this._spinner.hide();
            } catch (error) {
                this.error(error);
            }
        });
    }

    onPhoneChanged() {
        this.phoneMask = this.LANDLINE;
        if (
            this.phoneNumber &&
            this.phoneNumber.length >= 10 &&
            this.phoneMask >= this.LANDLINE &&
            this.previusLength >= 10
        ) {
            this.phoneMask = this.CELLPHONE;
        }

        this.previusLength = this.phoneNumber ? this.phoneNumber.length : 0;
    }

    reloadCurrentRoute() {
        let currentUrl = this._router.url;
        this._router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
                this._router.navigate([currentUrl]);
            });
    }

    visualizarChecklist(idCheclist) {
        const link = `${environment.host}/checklist/${idCheclist}/visualizar/contato`;
        this._router.navigate([]).then((result) => {
            window.open(link, '_blank');
        });
    }

    visualizarProjeto(idProjeto) {
        const link = `${environment.host}/projeto/visualizar/${idProjeto}`;
        this._router.navigate([]).then((result) => {
            window.open(link, '_blank');
        });
    }


}