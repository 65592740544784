import { Component, Injector, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { FormBase } from '@utils/base/form-base/form-base';
import { UsuarioLogado } from '@utils/interfaces/usuario/usuario-logado.interface';
import { AuthLocalStorageService } from '@utils/services/auth-local-storage.service';
import { Checklist } from 'app/main/checklist/shared/interfaces/checklist.interface';
import { UsuarioService } from 'app/main/usuario/shared/services/usuario.service';

@Component({
    selector: 'checklist-inicio',
    templateUrl: './checklist-inicio.component.html',
    styleUrls: ['./checklist-inicio.component.scss'],
})
export class ChecklistInicioComponent implements OnInit {
    protected _fuseConfigService: FuseConfigService;
    constructor(
        private _router: Router,
        private _authLocalStorage: AuthLocalStorageService,
        private _injector: Injector,
        private _usuarioService: UsuarioService,

    ) {
        this._fuseConfigService = this._injector.get(FuseConfigService);
        this.usuarioLogado = _authLocalStorage.getUsuario();

        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
    }

    @Input() checklist: Checklist;
    listSteps = [
        {
            ordem: 0,
            isProduto: false,
            chave: 'contato-empresa',
            titulo: 'Contato Empresa',
            url: '',
            proximoStep: 'conectividade',
            id: 'contato-empresa-disabled',
            isUrlEnable: false,
        },
        {
            ordem: 1,
            isProduto: false,
            chave: 'conectividade',
            titulo: 'Conectividade',
            url: '',
            proximoStep: 'dados-empresa',
            id: 'conectividade-disabled',
            isUrlEnable: false,
        },
        {
            ordem: 2,
            isProduto: false,
            chave: 'dados-empresa',
            titulo: 'Dados da Empresa',
            url: '',
            proximoStep: 'relacionamento',
            id: 'dados-empresa-disabled',
            isUrlEnable: false,
        },
        {
            ordem: 4,
            isProduto: false,
            chave: 'relacionamento',
            titulo: 'Relacionamento',
            url: '',
            proximoStep: 'configuracao',
            id: 'relacionamento-disabled',
            isUrlEnable: false,
        },
        // {
        //     ordem: 5,
        //     isProduto: false,
        //     chave: 'produtoFinanceiro-disabled',
        //     titulo: 'Produto Financeiro',
        //     url: '',
        //     proximoStep: 'configuracao',
        //     id: 'produtoFinanceiro-disabled',
        //     isUrlEnable: false,
        // },
        {
            ordem: 6,
            isProduto: false,
            chave: 'configuracao',
            titulo: 'Configuração',
            url: '',
            proximoStep: 'finalizacao-step',
            id: 'configuracao-disabled',
            isUrlEnable: false,
        },
        {
            ordem: 7,
            isProduto: false,
            chave: 'finalizacao',
            titulo: 'Finalização',
            url: '',
            proximoStep: null,
            id: 'finalizacao-disabled',
            isUrlEnable: false,
        },
    ];
    usuarioLogado: UsuarioLogado;
    ngOnInit(): void {
        // console.log(this.usuarioLogado);
        // this._route.data.subscribe((data) => {
        //     if (
        //         data &&
        //         data.cotacoesCliente &&
        //         data.cotacoesCliente.totalCotacao > 0
        //     ) {
        //         // this._router.navigateByUrl('/checklist');
        //     }
        // });
    }


    iniciar(): void {
        this._usuarioService.acessouCheklist(this.usuarioLogado.id).subscribe(
            (result) => {
                this._router.navigate(['checklist', this.checklist.id, 'contato']);
            },
            (error) => {

                console.log(error)
            }
        );

        this._router.navigate(['checklist', this.checklist.id, 'contato']);
    }

    // Configure the layout
}
