import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { ChecklistRelacionamentoProdutoFinanceiroService } from '../../shared/services/checklist-relacionamento-produto-financerio.service';

@Component({
  selector: 'checklist-produto-financeiro-upload',
  templateUrl: './checklist-produto-financeiro-upload.component.html',
  styleUrls: ['./checklist-produto-financeiro-upload.component.scss']
})
export class ChecklistProdutoFinanceiroUploadComponent {
  @Input() id: number;
  @Output() uploadFinalizado = new EventEmitter<boolean>();
  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  file: any;

  constructor(
    private _notification: NotificationService,
    private _importacaoProdutoFinanceiroService: ChecklistRelacionamentoProdutoFinanceiroService
  ) { }

  /**
   * gerenciador de descarte de arquivo
   */
  onFileDropped($event): void {
    this.prepareFile($event);
  }

  /**
   * lidar com arquivo de navegação
   */
  fileBrowseHandler(files): void {
    this.prepareFile(files);
  }

  /**
   * Excluir arquivo da lista de arquivos
   * @param index (File index)
   */
  deleteFile(): void {
    if (this.file.progress > 0 && this.file.progress < 100) {
      return;
    }
    this.file = null;
  }

  upload(): void {
    this._importacaoProdutoFinanceiroService.upload(this.id, this.file).subscribe(
      (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.Response) {
          this._notification.sucess(
            'Upload realizado com sucesso!',
            'Acompanhe o status da importação pela grid'
          );
          this.uploadFinalizado.emit(true);
        } else if (event.type === HttpEventType.UploadProgress) {
          this.file.progress = Math.round(
            (event.loaded * 100) / event.total
          );
        }
      },
      (error) => {
        this._notification.error('Erro no upload do arquivo!');
      }
    );
  }

  /**
   * Converter lista de arquivos em lista de matriz normal
   * @param files (Files List)
   */
  prepareFile(files: Array<any>): void {
    console.log(files);
    this.file = files[0] || {};
    this.file.progress = 0;

    this.fileDropEl.nativeElement.value = '';
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals = 2): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return (
      parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    );
  }

}
