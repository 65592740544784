import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { environment } from 'environments/environment';
import { AuthBaseController } from '../../shared/auth-base.controller';
import { v4 as uuid } from 'uuid';
import { AuthLocalStorageService } from '../../../../../../@utils/services/auth-local-storage.service';

@Component({
    selector: 'login-cliente',
    templateUrl: './login-cliente.component.html',
    styleUrls: ['./login-cliente.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class LoginClienteComponent
    extends AuthBaseController
    implements OnInit {
    loginForm: FormGroup;
    erro: string = null;
    recaptcha: any[];
    recaptchaKey: string;
    csrfToken: string;
    private attempts = 0;

    constructor(protected _injector: Injector, private authLocalStorage: AuthLocalStorageService) {
        super(_injector);
        this.recaptchaKey = environment.recaptchaKey;
    }

    ngOnInit(): void {
        this.csrfToken = uuid();
        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required]],
            senha: ['', Validators.required],
            recaptcha: [],
        });
    }

    logar(): void {
        if (this.recaptchaKey) {
            this.attempts++;
            this._spinner.show();
            this._authService.loginCliente({
                email: this.loginForm.get('email').value,
                senha: this.loginForm.get('senha').value,
                recaptcha: this.recaptcha,
                attempts: this.attempts
            }).subscribe(
                (authResponse) => {
                    this._spinner.hide();
                    this.authLocalStorage.deleteSession();
                    this.authLocalStorage.setToken(authResponse.data.token);
                    this.authLocalStorage.setUsuarioCliente(authResponse.data.dadosUsuario);
                    this._authService.direcionaAcessoLoginCliente();
                    },
                (error: any) => {
                    this._spinner.hide();
                    this._notification.error(error.error.message);
                }
            );
        }
    }

    recaptchaResolved(captchaResponse: any[]): void {
        this.recaptcha = captchaResponse;
    }
}
