import {
    Component,
    EventEmitter,
    Injector,
    OnInit,
    Output,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBase } from '@utils/base/form-base/form-base';
import { DataUtil } from '@utils/utils/class/data.util';
import { Banco } from 'app/main/banco/shareds/interfaces/banco.interface';
import { Time } from 'app/main/time/shareds/interfaces/time.interface';
import { TimeModalService } from 'app/main/time/shareds/services/time-modal.service';
import { Usuario } from 'app/main/usuario/shared/interfaces/usuario.interface';
import { UsuarioModalService } from 'app/main/usuario/shared/services/usuario-modal.service';
import * as moment from 'moment';
import { OsCategoria } from '../../shareds/interfaces/os-categoria.interface';
import { OsEmpresa } from '../../shareds/interfaces/os-empresa.interface';
import { OsStatus } from '../../shareds/interfaces/os-status.interface';
import { Os } from '../../shareds/interfaces/os.interface';
import { OsEmpresaModalService } from '../../shareds/services/os-empresa-modal.service';
import { OsService } from '../../shareds/services/os.service';

@Component({
    selector: 'os-detalhe',
    templateUrl: './os-detalhe.component.html',
    styleUrls: ['./os-detalhe.component.scss'],
})
export class OsDetalheComponent extends FormBase implements OnInit {
    @Output() salvando = new EventEmitter<Os>();

    isbloqueiaStatus: boolean = false;
    os: Os = {};
    statuss: Array<OsStatus> = [];
    categorias: Array<OsCategoria> = [];
    time: Time = {};
    responsavel: Usuario;
    empresa: OsEmpresa;
    empresaFaturamento: OsEmpresa;

    CELLPHONE = '(00) 0 0000-0000';
    LANDLINE = '(00) 0000-0000';

    phoneMask = this.LANDLINE;
    phoneNumber = '';
    previusLength = 0;

    constructor(
        private _injector: Injector,
        private _modalUsuario: UsuarioModalService,
        private _modalTime: TimeModalService,
        private _modalOsEmpresa: OsEmpresaModalService,
        private _osService: OsService
    ) {
        super(_injector, OsDetalheCombo.combos());

        this._route.data.subscribe((data) => {
            this.statuss = data.statuss;
            this.categorias = data.categorias;
            this.optionList.listaBancos = data.bancos;
            this.refreshOptionsConfig();
            if (data.os) {
                this.os = data.os;
                this.os.idBanco = this.os.banco ? this.os.banco.id : null;
                this.os.idOsStatus = this.os.status ? this.os.status.id : null;
                this.os.idOsCategoria = this.os.categoria
                    ? this.os.categoria.id
                    : null;
                this.os.idTime = this.os.time ? this.os.time.id : null;
                this.os.idResponsavel = this.os.responsavel
                    ? this.os.responsavel.id
                    : null;
                this.os.idEmpresa = this.os.empresa ? this.os.empresa.id : null;
                this.os.idEmpresaFatura = this.os.empresaFatura
                    ? this.os.empresaFatura.id
                    : null;

                this.time = this.os.time || {};
                this.responsavel = this.os.responsavel || {};
                this.empresa = this.os.empresa || {};
                this.empresaFaturamento = this.os.empresaFatura || {};

                this.setForm(this.os);
            } else {
                this.isbloqueiaStatus = true;
                this.form.patchValue({
                    status: 1,
                });
            }
        });
    }

    ngOnInit() { }

    protected crateForm(): { [key: string]: any } {
        return {
            banco: ['', Validators.required],
            status: ['', Validators.required],
            categoria: ['', Validators.required],
            faturaControleBanco: [],
            contatoNome: [''],
            contatoEmail: [''],
            contatoTelefone: [],
            dataPlanejada: ['', Validators.required],
            observacao: [],
        };
    }

    private setForm(os: Os): void {
        this.form.patchValue({
            banco: this.optionList.listaBancos.find((b) => os.idBanco === b.id),
            status: this.statuss.find((st) => os.idOsStatus === st.id),
            categoria: this.categorias.find((c) => os.idOsCategoria === c.id),
            faturaControleBanco: os.controleBanco,
            contatoNome: os.contatoNome,
            contatoEmail: os.contatoEmail,
            contatoTelefone: os.contatoTelefone,
            dataPlanejada: os.dataPlanejada,
            observacao: os.observacao,
        });
        this.form.get('dataPlanejada').markAsTouched();
    }

    pesquisaUsuario() {
        this._modalUsuario.usuarioPesquisa({}).subscribe((usuario) => {
            if (usuario) {
                this.os.idResponsavel = usuario.id;
                this.responsavel = usuario;
            }
        });
    }

    pesquisaTime() {
        this._modalTime.timePesquisa({}).subscribe((time) => {
            if (time) {
                this.os.idTime = time.id;
                this.time = time;
            }
        });
    }

    pesquisaEmpresaOs() {
        this._modalOsEmpresa.empresaGrid({}).subscribe((empresa) => {
            if (empresa) {
                this.empresa = empresa;
                this.os.idEmpresa = empresa.id;
            }
        });
    }

    pesquisaEmpresaFaturamento() {
        this._modalOsEmpresa.empresaGrid({}).subscribe((empresa) => {
            if (empresa) {
                this.empresaFaturamento = empresa;
                this.os.idEmpresaFatura = empresa.id;
            }
        });
    }

    salvar() {
        const banco = this.form.get('banco').value;
        const status = this.form.get('status').value;
        const categoria = this.form.get('categoria').value;
        const dataPlanejada = this.form.get('dataPlanejada').value;
        const contatoNome = this.form.get('contatoNome').value;
        const contatoEmail = this.form.get('contatoEmail').value;
        const contatoTelefone = this.form.get('contatoTelefone').value;
        const faturaControleBanco = this.form.get('faturaControleBanco').value;
        const observacao = this.form.get('observacao').value;

        if (!this.os.idEmpresa) {
            this._notification.error("Selecione uma empresa");
            return;
        }

        if (!this.os.idEmpresaFatura) {
            this._notification.error("Selecione uma  empresa faturamento");
            return;
        }

        this.os = Object.assign({}, this.os, {
            idBanco: banco ? banco.id : undefined,
            idOsStatus: status ? status.id : undefined,
            idOsCategoria: categoria ? categoria.id : undefined,
            faturaControleBanco: faturaControleBanco ? faturaControleBanco : undefined,
            contatoNome: contatoNome ? contatoNome : undefined,
            contatoEmail: contatoEmail ? contatoEmail : undefined,
            contatoTelefone: contatoTelefone ? contatoTelefone : undefined,
            dataPlanejada: dataPlanejada ? DataUtil.toDateBackend(dataPlanejada) : undefined,
            observacao: observacao ? observacao : undefined,
        });

        this._spinner.show();
        this._osService.save(this.os).subscribe(
            (osResponse) => {
                if (this.os.id > 0) {
                    this._notification.sucess('Os atualizada com sucesso!');
                } else {
                    this._notification.sucess('Os cadastrada com sucesso!');
                }
                this._spinner.hide();
                this.os = Object.assign({}, this.os, osResponse.data);
                if (osResponse.data) {
                    this._spinner.show();
                    this._osService
                        .findById(osResponse.data.id)
                        .subscribe((osData) => {
                            const os = osData.data;
                            this._spinner.hide();

                            this._router.navigate(['os', os.id, 'editar']);
                        });
                }
            },
            (error) => {
                this._spinner.hide();
                var showErrosDefault = true;
                if (
                    error.error.errors.contatoEmail &&
                    error.error.errors.contatoEmail[0]
                ) {
                    this._notification.error(
                        'E-mail para contato está inválido.'
                    );
                    showErrosDefault = false;
                }
                if (
                    error.error.errors.contatoTelefone &&
                    error.error.errors.contatoTelefone[0]
                ) {
                    this._notification.error(
                        'Telefone para contato está inválido.'
                    );
                    showErrosDefault = false;
                }
                if (showErrosDefault) {
                    this.error(error);
                }
            }
        );
    }

    onPhoneChanged() {
        this.phoneMask = this.LANDLINE;
        if (
            this.phoneNumber &&
            this.phoneNumber.length >= 10 &&
            this.phoneMask >= this.LANDLINE &&
            this.previusLength >= 10
        ) {
            this.phoneMask = this.CELLPHONE;
        }

        this.previusLength = this.phoneNumber ? this.phoneNumber.length : 0;
    }

    selectedOptionId(o1: any, o2: any) {
        return o1.id == o2;
    }
}

export class OsDetalheCombo {
    static combos() {
        return [
            {
                listName: 'listaBancos',
                filterName: 'filterBancos',
                fieldValue: 'descricaoCompleta',
            },
        ];
    }
}
