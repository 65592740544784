import {
    AfterViewInit,
    Component,
    EventEmitter,
    Injector,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { MatOption, MatSelect } from "@angular/material"
import { FormFiltroBase } from '@utils/base/form-filtro-base/form-filtro-base';
import { ChecklistStatus } from 'app/main/checklist/shared/interfaces/checklist-status.interface';
import { FiltroCotacao } from '../../shared/interfaces/filtro-cotacao.interface';
import { CotacaoStatus } from '../../shared/interfaces/cotacao-status.interface';
import { StatusFinanceiro } from '../../shared/interfaces/status-financeiro.interface';
import { TipoCobranca } from '../../shared/interfaces/tipo-cobranca.interface';
import { Usuario } from 'app/main/usuario/shared/interfaces/usuario.interface';
import { FormControl } from '@angular/forms';
import { DataUtil } from '@utils/utils/class/data.util';
import { Produto } from 'app/main/produto/shareds/interfaces/produto.interface';

@Component({
    selector: 'cotacao-grid-filtro',
    templateUrl: './cotacao-grid-filtro.component.html',
    styleUrls: ['./cotacao-grid-filtro.component.scss'],
})
export class CotacaoGridFiltroComponent
    extends FormFiltroBase<FiltroCotacao>
    implements OnInit {

    @Output() filtrar = new EventEmitter<FiltroCotacao>();

    public filterStatusFinanceiro: FormControl = new FormControl();

    listaStatus: Array<CotacaoStatus> = [];
    listaStatusFinanceiro: Array<StatusFinanceiro> = [];
    listaCotacaoStatus: Array<CotacaoStatus> = [];
    listaTipoCobrancas: Array<TipoCobranca> = [];
    listaStatusChecklist: Array<ChecklistStatus> = [];
    listaProdutos: Array<Produto> = [];
    usuarios: Array<Usuario> = [];
    allSelected = false;
    @ViewChild('usuarioSel', { static: true }) skillSel: MatSelect;


    constructor(injector: Injector) {
        super('CotacaoGridFiltro', injector, CotacaoGridFiltroCombos.combos());
    }


    ngOnInit() {
        this._route.data.subscribe((data) => {
            this.optionList.usuarios = data.usuarios;
            this.optionList.statuss = data.statuss;
            this.optionList.statusFinanceiro = data.statussFinanceiro;
            this.optionList.tipoCobrancas = data.tipoCobrancas;
            this.optionList.statusChecklist = data.statussChecklist;
            this.optionList.produtos = data.listaProdutos;

            this.form.patchValue({ tipoData: 'data-aceite' })

            this.refreshOptionsConfig();
        });

    }

    camposFiltro(): string[] {
        return [
            'status',
            'empresa',
            'inscricao',
            'nomeCotacao',
            'numeroCotacao',
            'dataInicio',
            'dataFim',
            'valorTotal',
            'statusFinanceiro',
            'tipoCobranca',
            'statusChecklist',
            'usuario',
            'produtos',
            // 'semContrato',
            'kickOffRealizado',
        ];
    }

    protected crateForm(): { [key: string]: any } {
        return {
            status: [],
            empresa: [],
            inscricao: [],
            nomeCotacao: [],
            numeroCotacao: [],
            dataInicio: [],
            dataFim: [],
            valorTotal: [],
            statusFinanceiro: [],
            statusCotacao: [],
            tipoCobranca: [],
            statusChecklist: [],
            usuario: [],
            // semContrato: [],
            produtos: [],
            tipoData: [],
            kickOffRealizado: [],
        };
    }

    limparFiltro(): void {
        this.limpar();
        this.form.patchValue({ tipoData: 'data-aceite' });
    }

    filtrarExecEnter(event): void {
        if (event.keyCode === 13) {
            this.filtrarExec();
        }
    }

    filtrarExec(): void {
        const empresa = this.form.get('empresa').value;
        const inscricao = this.form.get('inscricao').value;
        const nomeCotacao = this.form.get('nomeCotacao').value;
        const numeroCotacao = this.form.get('numeroCotacao').value;
        const valorTotal = this.form.get('valorTotal').value;
        const status = this.form.get('status').value;
        const statusFinanceiro = this.form.get('statusFinanceiro').value;
        const tipoCobranca = this.form.get('tipoCobranca').value;
        const statusChecklist = this.form.get('statusChecklist').value;
        const produtos = this.form.get('produtos').value;
        const tipoData = this.form.get('tipoData').value;
        const dataInicio = this.form.get('dataInicio').value;
        const dataFim = this.form.get('dataFim').value;
        const kickOffRealizado = this.form.get('kickOffRealizado').value;
        // let semContrato = this.form.get('semContrato').value;
        // semContrato = semContrato ? '1' : '0';

        //Responsável
        let usuario = this.form.get('usuario').value;
        usuario = usuario ? usuario : [];
        let idUsuario = [];
        if (usuario) idUsuario = [].concat(usuario.map((u) => u.id));

        const dataInicioStr = dataInicio ? DataUtil.toDateBackend(dataInicio) : '';
        const dataFimStr = dataFim ? DataUtil.toDateBackend(dataFim) : '';

        let idProdutos = null;
        if (produtos) {
            idProdutos = [].concat(produtos.map((p) => p.id));
            idProdutos = idProdutos.join(',').toString();
        }

        const filtro: FiltroCotacao = {
            empresaNome: empresa,
            empresaInscricao: inscricao,
            cotacaoNome: nomeCotacao,
            cotacaoNumero: numeroCotacao,
            valorTotal: valorTotal,
            idCotacaoStatusFinanceiro: statusFinanceiro
                ? statusFinanceiro.id
                : null,
            idCotacaoStatus: status ? status.id : null,
            idCotacaoTipoCobranca: tipoCobranca ? tipoCobranca.id : null,
            idChecklistStatus: statusChecklist ? statusChecklist.id : null,
            idUsuario: idUsuario,
            // semContrato: semContrato,
            idProduto: idProdutos,
            kickOffRealizado: kickOffRealizado,
        };

        if (tipoData === 'data-criacao') {
            if (dataInicioStr) filtro.dataCriacaoInicial = dataInicioStr;
            if (dataFimStr) filtro.dataCriacaoFinal = dataFimStr;
        }
        if (tipoData === 'data-aceite') {
            if (dataInicioStr) filtro.dataAceiteInicial = dataInicioStr;
            if (dataFimStr) filtro.dataAceiteFinal = dataFimStr;
        }
        if (tipoData === 'data-kickoff') {
            if (dataInicioStr) filtro.kickOffDataInicial = dataInicioStr;
            if (dataFimStr) filtro.kickOffDataFinal = dataFimStr;
        }
        if (tipoData === 'data-pagto-setup') {
            if (dataInicioStr) filtro.pagamentoSetupDataInicial = dataInicioStr;
            if (dataFimStr) filtro.pagamentoSetupDataFinal = dataFimStr;
        }

        this.filtrar.emit(filtro);
    }

    selecionarTudo(): void {
        this.allSelected = !this.allSelected;  // to control select-unselect

        if (this.allSelected) {
            this.skillSel.options.forEach(
                (item: MatOption) => {
                    if (item.value != 'is_null') {
                        item.select()
                    }
                }
            );
        } else {
            this.skillSel.options.forEach(
                (item: MatOption) => {
                    item.deselect()
                });
        }
    }
}

export class CotacaoGridFiltroCombos {
    static combos() {
        return [
            {
                listName: 'statuss',
                filterName: 'filterStatus',
                fieldValue: 'descricao',
            },
            {
                listName: 'statusFinanceiro',
                filterName: 'filterStatusFinanceiro',
                fieldValue: 'descricao',
            },
            {
                listName: 'tipoCobrancas',
                filterName: 'filterTipoConbranca',
                fieldValue: 'descricao'
            },
            {
                listName: 'statusChecklist',
                filterName: 'filterStatusChecklist',
                fieldValue: 'descricao'
            },
            {
                listName: 'usuarios',
                filterName: 'filterUsuario',
                fieldValue: 'nome'
            },
            // {
            //     listName: 'produtos',
            //     filterName: 'filterProduto',
            //     fieldValue: 'nome'
            // }
        ]
    }
}
