import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalEditarOsComponent } from '../../modal/modal-editar-os/modal-editar-os.component';
import { ModalVincularProjetoComponent } from '../../modal/modal-vincular-projeto/modal-vincular-projeto.component';
import { OsEditar } from '../interfaces/os-editar.interface';

@Injectable({
    providedIn: 'root',
})
export class OsModalService {
    constructor(public dialog: MatDialog) {}

    osEditar(data: OsEditar) {
        const dialogRef = this.dialog.open(ModalEditarOsComponent, {
            width: '50%',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    vincularProjetos(data: any) {
        const dialogRef = this.dialog.open(ModalVincularProjetoComponent, {
            width: '95%',
            height: '95%',
            data: data,
        });

        return dialogRef.afterClosed();
    }
}
