import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Adquirente } from "../interfaces/adquirente.interfaces";
import { AdquirenteService } from "../services/adquirente.service";

@Injectable()
export class AdquirenteListaResolver implements Resolve<Adquirente> {
    constructor(private _adquirenteService: AdquirenteService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._adquirenteService.find().pipe(map((result) => result.data));
    }
}
