import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ChecklistContatoEmpresaService } from '../../shared/services/checklist-contato-empresa.service';
import { ComponentBase } from '../../../../../@utils/base/component-base/component-base';

@Component({
    selector: 'modal-confirm-checklist-edit-exit',
    templateUrl: './modal-confirm-checklist-edit-exit.component.html',
    styleUrls: ['./modal-confirm-checklist-edit-exit.component.scss'],
})
export class ModalConfirmChecklistEditExitComponent extends ComponentBase implements OnInit {
    tituloModal = 'Confirmar contatos para envio de checklist';

    constructor(
        private _injector: Injector,
        public dialogRef: MatDialogRef<ModalConfirmChecklistEditExitComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(MAT_DIALOG_DATA) public idChecklist: number,
        private _checklistContatoEmpresaService: ChecklistContatoEmpresaService
    ) {
        super(_injector);
    }
    ngOnInit(): void {
    }

    confirm(result?): void {
        if (result) { this.data(); }
        this.dialogRef.close(result);
    }
}
