import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { GmudRecomendacoes } from '../../shared/interfaces/gmud-recomendacoes.interface';
import { GmudRecomendacoesService } from '../../shared/services/gmud-recomendacoes.service';
import { FormControl, FormGroup } from '@angular/forms';
import { GmudConsideracao } from '../../shared/interfaces/gmud-consideracao.interface';
import { GmudConsideracaoService } from '../../shared/services/gmud-consideracao.service';

@Component({
    selector: 'gmud-recomendacao-detalhe',
    templateUrl: './gmud-recomendacao-detalhe.component.html',
    styleUrls: ['./gmud-recomendacao-detalhe.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GmudRecomendacaoDetalheComponent implements OnChanges, OnInit {
    // recomendacao: GmudRecomendacoes = {}
    // recomendacao: any[] = {}
    consideracoes: GmudConsideracao[] = []
    recomendacaoForm = new FormGroup({ descricao: new FormControl('', []) });

    @Input() recomendacao: GmudRecomendacoes = {};
    @Output() modal = new EventEmitter();

    constructor(
        protected _serviceRecomendacao: GmudRecomendacoesService,
        protected _serviceConsideracao: GmudConsideracaoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        // console.log('1', this.recomendacao)
        this._serviceRecomendacao.findRecomendacao(this.recomendacao.gmr_id).subscribe((response) => {
            // console.log('response', response.data)
            this.recomendacao = response.data

            this.recomendacaoForm.controls.descricao.setValue(this.recomendacao.gmr_descricao);
            this.recomendacaoForm.controls.descricao.disable()

            // console.log('2', this.recomendacao)
            this._serviceConsideracao.findConsideracao(this.recomendacao.gmr_id).subscribe((response) => {
                this.consideracoes = response.data
            }, error => console.log({ error }))
        }, error => console.log({ error }))
    }

    ngOnChanges(): void { }

    voltar() {
        this.modal.emit(true);
    }
}
