import { Component, Injector, OnInit } from '@angular/core';
import { ChacklistPageBase } from '../../shared/base/checklist-page-base';

@Component({
    selector: 'app-checklist-conectividade-page',
    templateUrl: './checklist-conectividade-page.component.html',
    styleUrls: ['./checklist-conectividade-page.component.scss'],
})
export class ChecklistConectividadePageComponent
    extends ChacklistPageBase
    implements OnInit {
    validate: Function;
    carregarMenu: boolean;
    constructor(protected _injector: Injector) {
        super(_injector);
    }

    ngOnInit(): void { }

    atualizarMenu(result) {
        this.carregarMenu = Object.assign({});
    }

    refreshValidation(event) {
        this.validate = (valid) => {
            if (valid) {
                event[0]();
                return;
            }
            event[1]();
        }
    }
}
