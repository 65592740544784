import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    ActivatedRoute,
    Router,
} from '@angular/router';
import { ChecklistService } from './shared/services/checklist.service';

@Injectable()
export class ChecklistStepGuard implements CanActivate {
    stepsConfig = {
        contato: 'stepContato',
        conectividade: 'stepConectividade',
        'dados-empresa': 'stepEmpresa',
        relacionamento: 'stepRelacionamento',
        'contato-banco': 'stepContatoBanco',
        // produtoFinanceiro: 'stepProdutoFinanceiro',
        configuracao: 'stepConfiguracao',
        finalizacao: '',
    };

    stepsAnterior = {
        contato: null,
        conectividade: 'contato',
        'dados-empresa': 'conectividade',
        relacionamento: 'dados-empresa',
        'contato-banco': 'relacionamento',
        //produtoFinanceiro: 'contato-banco',
        configuracao: 'contato-banco',
        finalizacao: 'configuracao',
    };

    constructor(
        private _checklistService: ChecklistService,
        private _router: Router
    ) { }

    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        const path = route.routeConfig.path;
        const pathArray = path.split('/');
        const chave = pathArray[pathArray.length - 1];
        let retorno = true;
        const idChecklist = route.params.id;

        const checklistData = await this._checklistService
            .findById(idChecklist)
            .toPromise();
        const checklist = checklistData.data;

        if (!checklist.criarConfiguracao && chave === 'finalizacao') {
            this.stepsAnterior.finalizacao = 'relacionamento';
        }


        if (chave === 'finalizacao' &&
            (checklist.finalizadoSteps &&
                checklist.finalizadoProdutos)
        ) {
            retorno = true;
        }
        else if (
            chave === 'finalizacao' ||
            (this.stepsConfig[chave] && this.stepsAnterior[chave])
        ) {
            const stepChave = this.stepsConfig[chave];
            if (!this.stepsConfig[this.stepsAnterior[chave]]) {
                retorno = true;
            }
            const stepAnteriorChave = this.stepsConfig[
                this.stepsAnterior[chave]
            ];

            if (!checklist[stepAnteriorChave]) {
                this._router.navigate([
                    'checklist',
                    idChecklist,
                    this.stepsAnterior[chave],
                ]);
            }
        }

        return retorno;
    }
}
