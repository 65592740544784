import { Component, OnInit } from '@angular/core';
import { FiltroGmud } from '../../shared/interfaces/filtro-gmud.interface';
import * as moment from 'moment';

@Component({
    selector: 'gmud-grid-page',
    templateUrl: './gmud-grid-page.component.html',
    styleUrls: ['./gmud-grid-page.component.scss'],
})
export class GmudGridPageComponent implements OnInit {
    filtro: FiltroGmud;

    constructor() {}

    ngOnInit() {
        this.filtro = {
            tipo_data: 'execucao',
            sortField: 'gmud_data',
            gmud_data_inicio: moment().subtract(7, 'days').format('YYYY-MM-DD'),
            gmud_data_fim: moment().add(7, 'days').format('YYYY-MM-DD')
        };
    }

    filtrar(filtro: FiltroGmud) {
        this.filtro = Object.assign({}, filtro);
    }
}
