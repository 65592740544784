import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChecklistAdquirente } from '../interfaces/checklist-adquirente.interface';
import { FiltroChecklistAdquirente } from '../interfaces/filtro-checklist-adquirente.interface';
import { ChecklistAdquirenteService } from '../services/checklist-adquirente.service';

@Injectable()
export class ChecklistAdquirentesListaResolver
    implements Resolve<ChecklistAdquirente> {
    constructor(
        private _checklistAdquirentesService: ChecklistAdquirenteService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const id = route.params['id'];
        const filtro: FiltroChecklistAdquirente = {
            idChecklist: id
        };
        return this._checklistAdquirentesService
            .findByChecklist(id, filtro)
            .pipe(map((result) => result.data));
    }
}
