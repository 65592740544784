import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-bi-kpi-negocios',
    templateUrl: './bi-kpi-negocios.component.html',
    styleUrls: ['./bi-kpi-negocios.component.scss'],
})
export class BiKpiNegociosComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
