import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Cargo } from '../interfaces/cargo.interface';
import { CargoService } from '../services/cargo.service';

@Injectable()
export class CargoListaResolver
    implements Resolve<Cargo> {
    constructor(
        private _CargoService: CargoService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._CargoService
            .find()
            .pipe(map((result) => result.data));
    }
}
