import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { FiltroProjetoAdquirente } from '../../shareds/interfaces/filtro-projeto-adquirente.interface';
import { ProjetoAdquirente } from '../../shareds/interfaces/projeto-adquirente.interface';
import { ProjetoAdquirenteService } from '../../shareds/services/projeto-adquirente.service';

@Component({
  selector: 'projeto-adquirente-grid',
  templateUrl: './projeto-adquirente-grid.component.html',
  styleUrls: ['./projeto-adquirente-grid.component.scss']
})
export class ProjetoAdquirenteGridComponent extends GridBase<
ProjetoAdquirente,
FiltroProjetoAdquirente,
ProjetoAdquirenteService
> implements OnInit {

  @Input() adquirentes: any;
  @Input() filtro: any;

  constructor(
    protected _injector: Injector,
    protected _route: ActivatedRoute,
    protected _service: ProjetoAdquirenteService,
    protected _changeDetectorRefs: ChangeDetectorRef
  ) {
    super(_service, _injector, _changeDetectorRefs);
  }

  ngOnInit() {

  }

  configDisplayedColumns(): Array<GridCollumn> {
    return [
      { chave: 'adquirente', label: 'Adquirente' },
    ];
  }
}
