import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '@utils/utils.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
    MatListModule,
    MatGridListModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatButtonModule,
    MatProgressSpinnerModule,
} from '@angular/material';

import { ProjetoRoutingModule } from './projeto-routing.module';

import { ProjetoGridComponent } from './components/projeto-grid/projeto-grid.component';
import { ProjetoGridFiltroComponent } from './components/projeto-grid-filtro/projeto-grid-filtro.component';

import { ProjetoCadastroPageComponent } from './pages/projeto-cadastro-page/projeto-cadastro-page.component';
import { ProjetoDetalhePageComponent } from './pages/projeto-detalhe-page/projeto-detalhe-page.component';
import { ProjetoGridPageComponent } from './pages/projeto-grid-page/projeto-grid-page.component';

import { ProjetoService } from './shareds/services/projeto.service';
import { ProjetoDetalheResolver } from './shareds/resolvers/projeto-detalhe.resolver';

import { ProjetoGuard } from './projeto.guard';
import { ProjetoCadastroComponent } from './components/projeto-cadastro/projeto-cadastro.component';
import { ProjetoStatusListaResolver } from './shareds/resolvers/projeto-status-lista.resolver';
import { ProjetoAberturaService } from './shareds/services/projeto-abertura.service';
import { ProjetoAberturaGridComponent } from './components/projeto-abertura-grid/projeto-abertura-grid.component';
import { ProjetoAberturaGridFiltroComponent } from './components/projeto-abertura-grid-filtro/projeto-abertura-grid-filtro.component';
import { ProdutoModule } from '../produto/produto.module';
import { ModuloModule } from '../modulo/modulo.module';
import { UsuarioModule } from '../usuario/usuario.module';
import { TimeModule } from '../time/time.module';
import { ProjetoModalService } from './shareds/services/projeto-modal.service';
import { ModalPesquisaProjetoComponent } from './modal/modal-pesquisa-projeto/modal-pesquisa-projeto.component';
import { PesquisaProjetoComponent } from './components/pesquisa-projeto/pesquisa-projeto.component';
import { ProjetoPesuisaService } from './shareds/services/projeto-pesquisa.service';
import { OsModule } from '../os/os.module';
import { ModalHistoricoProjetoComponent } from './modal/modal-historico-projeto/modal-historico-projeto.component';
import { ModalHistoricoDetalheProjetoComponent } from './modal/modal-historico-detalhe-projeto/modal-historico-detalhe-projeto.component';
import { ProjetoHistoricoComponent } from './components/projeto-historico/projeto-historico.component';
import { ProjetoHistoricoDetalheComponent } from './components/projeto-historico-detalhe/projeto-historico-detalhe.component';
import { ProjetoHistoricoService } from './shareds/services/projeto-historico.service';
import { ProjetoAberturaCadastroPageComponent } from './pages/projeto-abertura-cadastro-page/projeto-abertura-cadastro-page.component';
import { ProjetoAberturaCadastroComponent } from './components/projeto-abertura-cadastro/projeto-abertura-cadastro.component';
import { ConfiguradorModule } from '../configurador/configurador.module';
import { ProjetoAberuraDetalheResolver } from './shareds/resolvers/projeto-abertura-detalhe.resolver';
import { ProjetoAberturaHistoricoComponent } from './components/projeto-abertura-historico/projeto-abertura-historico.component';
import { ModalHistoricoProjetoAberturaComponent } from './modal/modal-historico-projeto-abertura/modal-historico-projeto.component';
import { ModalHistoricoDetalheProjetoAberturaComponent } from './modal/modal-historico-detalhe-projeto-abertura/modal-historico-detalhe-projeto-abertura.component';
import { ProjetoAberturaHistoricoService } from './shareds/services/projeto-abertura-historico.service';
import { ProjetoAberturaHistoricoDetalheComponent } from './components/projeto-abertura-historico-detalhe/projeto-abertura-historico-detalhe.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CustoTrafegoListaResolver } from './shareds/resolvers/custo-trafego-lista-resolver';
import { ModalCaixaPostalProjetoAberturaComponent } from './modal/modal-caixa-postal-projeto-abertura/modal-caixa-postal-projeto-abertura.component';
import { ProjetoAberturaCaixaPostalGridFiltroComponent } from './components/projeto-abertura-caixa-postal-grid-filtro/projeto-abertura-caixa-postal-grid-filtro.component';
import { ProjetoAberturaCaixaPostalGridComponent } from './components/projeto-abertura-caixa-postal-grid/projeto-abertura-caixa-postal-grid.component';
import { ProjetoCaixaPostalService } from './shareds/services/projeto-caixa-postal.service';
import { ProjetoAberturaConfiguracaoService } from './shareds/services/projeto-abertura-configuracao.service';
import { ModalAlteracaoLoteProjetoComponent } from './modal/modal-alteracao-lote-projeto/modal-alteracao-lote-projeto.component';
import { ProjetoAlteracaoLoteComponent } from './components/projeto-alteracao-lote/projeto-alteracao-lote.component';
import { ModalDownloadProjetoComponent } from './modal/modal-download-projeto/modal-download-projeto.component';
import { ProjetoDownloadComponent } from './components/projeto-download/projeto-download.component';
import { ProjetoAdquirenteGridComponent } from './components/projeto-adquirente-grid/projeto-adquirente-grid.component';
import { StatusReportService } from './shareds/services/status-report.service';
import { StatusReportListResolver } from './shareds/resolvers/status-report-lista.resolver';
import { ProjetoObservacaoHistoricoComponent } from './components/projeto-observacao-historico/projeto-observacao-historico.component';
import { ProjetoObservacaoHistoricoService } from './shareds/services/projeto-observacao-historico.service';
import { ProjetoProcessoStatusService } from './shareds/services/projeto-processo-status.service';
import { ProjetoProcessoStatusListaResolver } from './shareds/resolvers/projeto-processo-status-lista.resolver';
import { ModalHistoricoStatusProjetoAberturaComponent } from './modal/modal-historico-status-projeto-abertura/modal-historico-status-projeto-abertura.component';
import { ProjetoAberturaStatusHistoricoComponent } from './components/projeto-abertura-status-historico/projeto-abertura-status-historico.component';
import { ProjetoAberturaStatusService } from './shareds/services/projeto-abertura-status.service';

@NgModule({
    declarations: [
        ProjetoCadastroComponent,
        ProjetoGridComponent,
        ProjetoGridFiltroComponent,
        ProjetoCadastroPageComponent,
        ProjetoGridPageComponent,
        ProjetoDetalhePageComponent,
        ProjetoAberturaGridComponent,
        ProjetoAberturaGridFiltroComponent,
        ProjetoHistoricoComponent,
        ProjetoHistoricoDetalheComponent,
        PesquisaProjetoComponent,
        ModalPesquisaProjetoComponent,
        ModalHistoricoProjetoComponent,
        ModalHistoricoProjetoComponent,
        ModalHistoricoDetalheProjetoComponent,
        ProjetoAberturaCadastroPageComponent,
        ProjetoAberturaCadastroComponent,
        ProjetoAberturaHistoricoComponent,
        ProjetoAberturaHistoricoDetalheComponent,
        ModalHistoricoProjetoAberturaComponent,
        ModalHistoricoDetalheProjetoAberturaComponent,
        ModalCaixaPostalProjetoAberturaComponent,
        ProjetoAberturaCaixaPostalGridFiltroComponent,
        ProjetoAberturaCaixaPostalGridComponent,
        ModalAlteracaoLoteProjetoComponent,
        ProjetoAlteracaoLoteComponent,
        ModalDownloadProjetoComponent,
        ProjetoDownloadComponent,
        ProjetoAdquirenteGridComponent,
        ProjetoObservacaoHistoricoComponent,
        ModalHistoricoStatusProjetoAberturaComponent,
        ProjetoAberturaStatusHistoricoComponent,
    ],

    imports: [
        CommonModule,
        ProjetoRoutingModule,
        UtilsModule,
        NgxMatSelectSearchModule,

        //TABLE
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatListModule,
        MatGridListModule,

        //FORMULARIO
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatRadioModule,
        MatButtonModule,
        MatProgressSpinnerModule,

        //MODULOS
        ProdutoModule,
        ModuloModule,
        UsuarioModule,
        OsModule,
        TimeModule,
        ConfiguradorModule,
    ],

    providers: [
        ProjetoService,
        ProjetoDetalheResolver,
        ProjetoGuard,
        ProjetoStatusListaResolver,
        ProjetoAberturaService,
        ProjetoModalService,
        ProjetoPesuisaService,
        ProjetoHistoricoService,
        ProjetoAberuraDetalheResolver,
        ProjetoAberturaHistoricoService,
        CustoTrafegoListaResolver,
        ProjetoCaixaPostalService,
        ProjetoAberturaConfiguracaoService,
        StatusReportService,
        StatusReportListResolver,
        ProjetoObservacaoHistoricoService,
        ProjetoProcessoStatusService,
        ProjetoAberturaStatusService,
        ProjetoProcessoStatusListaResolver,
    ],

    entryComponents: [
        ModalPesquisaProjetoComponent,
        ModalHistoricoProjetoComponent,
        ModalHistoricoDetalheProjetoComponent,
        ModalHistoricoProjetoAberturaComponent,
        ModalHistoricoDetalheProjetoAberturaComponent,
        ModalCaixaPostalProjetoAberturaComponent,
        ModalAlteracaoLoteProjetoComponent,
        ModalDownloadProjetoComponent,
        ModalHistoricoStatusProjetoAberturaComponent
    ],

    exports: [MatGridListModule]
})
export class ProjetoModule { }
