import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { FiltroProjetoAdquirente } from '../interfaces/filtro-projeto-adquirente.interface';
import { ProjetoAdquirente } from '../interfaces/projeto-adquirente.interface';

@Injectable({
  providedIn: 'root'
})
export class ProjetoAdquirenteService extends ServiceGridBase<
ProjetoAdquirente,
FiltroProjetoAdquirente
> {

  private path: string = `${environment.server}/adquirentes`;

  constructor(private _http: HttpClient) {
    super();
  }

  find(filtro: FiltroProjetoAdquirente): Observable<Response<ProjetoAdquirente[]>> {
    const params: string = filtro ? this.toGetParams(filtro) : '';
    const url = `${this.path}/busca${params}`;
    return this._http.get<Response<Array<ProjetoAdquirente>>>(url);
  }
}
