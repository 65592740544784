import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalEditarCotacaoComponent } from '../../modal/modal-editar-cotacao/modal-editar-cotacao.component';
import { ModalHistoricoDetalheCotacaoComponent } from '../../modal/modal-historico-detalhe-cotacao/modal-historico-detalhe-cotacao.component';
import { ModalHistoricoCotacaoComponent } from '../../modal/modal-historico-cotacao/modal-historico-cotacao.component';
import { ModalPesquisaCotacaoComponent } from '../../modal/modal-pesquisa-cotacao/modal-pesquisa-cotacao.component';
import { CotacaoEditar } from '../interfaces/cotacao-editar.interface';
import { CotacaoPesquisa } from '../interfaces/cotacao-pesquisa.interface';
import { Cotacao } from '../interfaces/cotacao.interface';
import { CotacaoHistorico } from '../interfaces/cotacao-historico.interface';
import { ModalContratoVincularComponent } from '../../modal/modal-contrato-vincular/modal-contrato-vincular.component';
import { ModalCotacaoBancoContratadoComponent } from '../../modal/modal-cotacao-banco-contratado/modal-cotacao-banco-contratado.component';
import { CotacaoItemCotacao } from '../interfaces/cotacao-item-cotacao.interface';

@Injectable({
    providedIn: 'root',
})
export class CotacaoModalService {
    constructor(public dialog: MatDialog) { }

    cotacaoEditar(data: CotacaoEditar) {
        const dialogRef = this.dialog.open(ModalEditarCotacaoComponent, {
            width: '50%',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    cotacaoPesquisa(data: CotacaoPesquisa) {
        const dialogRef = this.dialog.open(ModalPesquisaCotacaoComponent, {
            width: '90%',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    historico(data: Cotacao) {
        const dialogRef = this.dialog.open(ModalHistoricoCotacaoComponent, {
            width: '90%',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    historicoDetalhe(data: CotacaoHistorico) {
        const dialogRef = this.dialog.open(
            ModalHistoricoDetalheCotacaoComponent,
            {
                width: '90%',
                data: data,
            }
        );

        return dialogRef.afterClosed();
    }

    contratoVincular(data: Cotacao) {
        const dialogRef = this.dialog.open(ModalContratoVincularComponent, {
            width: '90%',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    empresasContratadas(data: CotacaoItemCotacao) {
        const dialogRef = this.dialog.open(ModalCotacaoBancoContratadoComponent, {
            width: '90%',
            data: data,
        });

        return dialogRef.afterClosed();
    }
}
