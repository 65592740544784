import {
    Component,
    EventEmitter,
    Injector,
    OnInit,
    Output,
} from '@angular/core';
import { FormFiltroBase } from '@utils/base/form-filtro-base/form-filtro-base';
import { ContratoTipoContrato } from '../../shared/interfaces/contrato-tipo-contrato.interface';
import { ContratoSituacao } from '../../shared/interfaces/contrato-situacao.interface';
import { Contrato } from '../../shared/interfaces/contrato.interface';
import { FiltroContratoItem } from '../../shared/interfaces/filtro-contrato-item.interface';
import { Produto } from 'app/main/produto/shareds/interfaces/produto.interface';
import * as moment from 'moment';
import { DataUtil } from '@utils/utils/class/data.util';

@Component({
    selector: 'contrato-detalhe-item-grid-filtro',
    templateUrl: './contrato-detalhe-item-grid-filtro.component.html',
    styleUrls: ['./contrato-detalhe-item-grid-filtro.component.scss'],
})
export class ContratoDetalheItemGridFiltroComponent extends FormFiltroBase<FiltroContratoItem> implements OnInit {

    @Output() filtrar = new EventEmitter<FiltroContratoItem>();

    listaDetalheTipoContrato: Array<ContratoTipoContrato> = [
        { valor: 'MENSALIDADE', descricao: 'Mensalidade' },
        { valor: 'FRANQUIA', descricao: 'Franquia' },
        { valor: 'FAIXA', descricao: 'Faixa' }
    ]
    listaDetalheProduto: Array<Produto> = []
    listaSituacao: Array<ContratoSituacao> = []
    contrato: Contrato = {}

    constructor(injector: Injector) {
        super('ContratoDetalheItemGrid', injector);
    }

    ngOnInit() {
        this._route.data.subscribe((data) => {
            this.listaDetalheProduto = data.contratoProdutosLista
            this.listaSituacao = data.situacoes,
                this.contrato = data.contrato
        });
    }

    camposFiltro(): string[] {
        return []
    }

    protected crateForm(): { [key: string]: any } {
        return {
            situacao: [],
            tipoContrato: [],
            produto: [],
            tipoData: ['dataCriacao'],
            dataInicial: [],
            dataFinal: [],
            dataFinalVigenciaIndeterminado: [],
        };
    }

    filtrarExecEnter(event): void {
        if (event.keyCode === 13) {
            this.filtrarExec();
        }
    }

    limpar(): void {
        this.form.reset();
        this.form.get('tipoData').setValue('dataCriacao')
        this.filtro = Object.assign({}, { idContrato: this.contrato.id });
        this.filtrar.emit(this.filtro);
    }

    filtrarExec(): void {
        const situacao = this.form.get('situacao').value;
        const tipoContrato = this.form.get('tipoContrato').value;
        const produto = this.form.get('produto').value;
        const tipoData = this.form.get('tipoData').value;
        const dataFinalVigenciaIndeterminado = this.form.get('dataFinalVigenciaIndeterminado').value;
        let dataCriacaoInicial, dataCriacaoFinal, dataVigenciaInicial, dataVigenciaFinal, dataBillerInicial, dataBillerFinal;
        if (tipoData === 'dataCriacao') {
            dataCriacaoInicial = this.form.get('dataInicial').value;
            dataCriacaoFinal = this.form.get('dataFinal').value;
        }
        if (tipoData === 'dataBiller') {
            dataBillerInicial = this.form.get('dataInicial').value;
            dataBillerFinal = this.form.get('dataFinal').value;
        }

        const filtro: FiltroContratoItem = {
            idContrato: this.contrato.id,
            idSituacao: situacao && situacao.id ? situacao.id : null,
            tipoContrato: tipoContrato,
            idProduto: produto && produto.id ? produto.id : null,
            dataCriacaoInicial: dataCriacaoInicial ? DataUtil.toDateBackend(dataCriacaoInicial) : "",
            dataCriacaoFinal: dataCriacaoFinal ? DataUtil.toDateBackend(dataCriacaoFinal) : "",
            dataVigenciaFinal: dataVigenciaFinal ? DataUtil.toDateBackend(dataVigenciaFinal) : "",
            dataBillerInicial: dataBillerInicial ? DataUtil.toDateBackend(dataBillerInicial) : "",
            dataBillerFinal: dataBillerFinal ? DataUtil.toDateBackend(dataBillerFinal) : "",
            dataVigenciaFinalIndeterminado: dataFinalVigenciaIndeterminado ? '1' : undefined
        };

        this.filtrar.emit(filtro);
    }
}
