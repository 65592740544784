import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Checklist } from 'app/main/checklist/shared/interfaces/checklist.interface';
import { ModalEmpresaCadastroComponent } from '../../modal/modal-empresa-cadastro/modal-empresa-cadastro.component';
import { ModalEmpresaDetalheComponent } from '../../modal/modal-empresa-detalhe/modal-empresa-detalhe.component';
import { ModalEmpresaGridComponent } from '../../modal/modal-empresa-grid/modal-empresa-grid.component';
import { Empresa } from '../interfaces/empresa.interface';

@Injectable({
    providedIn: 'root',
})
export class EmpresaModalService {
    constructor(public dialog: MatDialog) {}

    empresaGrid(data: Checklist) {
        const dialogRef = this.dialog.open(ModalEmpresaGridComponent, {
            width: '90%',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    empresaCadastro(data: Empresa) {
        const dialogRef = this.dialog.open(ModalEmpresaCadastroComponent, {
            width: '95%',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    empresaDetalhe(data: Empresa) {
        const dialogRef = this.dialog.open(ModalEmpresaDetalheComponent, {
            width: '95%',
            data: data,
        });

        return dialogRef.afterClosed();
    }
}
