import { Injectable } from '@angular/core';
import { BaseGuard, GuardConfigPermission } from '@utils/guard/base.guard';
import { Role } from 'app/fuse-config/role';

@Injectable()
export class GmudGuard extends BaseGuard {
    protected configPermissions(): Array<GuardConfigPermission> {
        return [
            { path: '/gmud', role: Role.GMUD_LISTAR },
            { path: '/gmud/[0-9]+', role: Role.GMUD_EXIBIR },
            { path: '/gmud/cadastro', role: Role.GMUD_CADASTRAR },
            { path: '/gmud/cadastro/[0-9]+', role: Role.GMUD_EDITAR },
            { path: '/gmud/edicao/[0-9]+', role: Role.GMUD_EDITAR },
            { path: '/gmud/visualizacao/[0-9]+', role: Role.GMUD_EDITAR },
            // { path: '/gmud/configurar-aprovacao', role: Role.GMUD_CONFIGURAR_APROVACAO },
        ];
    }
}
