import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { Cargo } from '../interfaces/cargo.interface';

@Injectable({
    providedIn: 'root',
})
export class CargoService extends ServiceBase<Cargo> {
    private path = `${environment.server}/checklist/cargo`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(
    ): Observable<Response<Array<Cargo>>> {
        const url = `${this.path}`;
        return this._http.get<Response<Array<Cargo>>>(url);
    }

}
