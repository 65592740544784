import { Component, Injector, OnInit } from '@angular/core';
import { ChacklistPageBase } from '../../shared/base/checklist-page-base';

@Component({
    selector: 'checklist-adquirente-page',
    templateUrl: './checklist-adquirente-page.component.html',
    styleUrls: ['./checklist-adquirente-page.component.scss'],
})
export class ChecklistAdquirentePageComponent
    extends ChacklistPageBase
    implements OnInit {
    carregarMenu: boolean;
    chave: string = 'checklist-adquirente';
    constructor(protected _injector: Injector) {
        super(_injector);
    }

    ngOnInit(): void { }

    atualizarMenu(result) {
        this.carregarMenu = Object.assign({});
    }
}
