import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CotacaoKickoff } from '../interfaces/cotacao-kickoff.interface';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Response } from '@utils/interfaces/http/response.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { MatDialog } from '@angular/material';
import { Cotacao } from '../interfaces/cotacao.interface';
import { ModalKickoffComponent } from '../../modal/modal-kickoff/modal-kickoff.component';

@Injectable({
    providedIn: 'root',
})
export class CotacaoKickoffService extends ServiceBase<CotacaoKickoff> {
    private path = `${environment.server}/kickoff`;

    constructor(private _http: HttpClient, public dialog: MatDialog) {
        super();
    }

    find(): Observable<Response<Array<CotacaoKickoff>>> {
        const url = `${this.path}`;
        return this._http.get<Response<Array<CotacaoKickoff>>>(url);
    }

    modal(data: Cotacao) {
        const dialogRef = this.dialog.open(ModalKickoffComponent, {
            width: '90%',
            data: data,
        });

        return dialogRef.afterClosed();
    }
}
