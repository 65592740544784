import { Component, OnInit } from '@angular/core';
import { FiltroContrato } from '../../shared/interfaces/filtro-contrato.interface';

@Component({
    selector: 'contrato-detalhe-page',
    templateUrl: './contrato-detalhe-page.component.html',
    styleUrls: ['./contrato-detalhe-page.component.scss'],
})
export class ContratoDetalhePageComponent implements OnInit {
    filtro: FiltroContrato;
    atualizarGrid: boolean;
    atualizarAditivoGrid: boolean;
    filtroAditivo: any;

    constructor() { }

    ngOnInit() { }

    filtrar(filtro: FiltroContrato) {
        this.filtro = Object.assign({}, filtro);
    }

    atualizaGrid(isAtualizar: boolean) {
        this.atualizarGrid = Object.assign({});
    }

    atualizarGridAditivo(isAtualizar: boolean) {
        this.atualizarAditivoGrid = Object.assign({});
    }

}
