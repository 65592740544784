import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-bi-portal-boletos-gerencial-team',
    templateUrl: './bi-portal-boletos-gerencial.component.html',
    styleUrls: ['./bi-portal-boletos-gerencial.component.scss'],
})
export class BiPortalBoletosGerencialComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
