import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBase } from '@utils/base/form-base/form-base';
import { Adquirente } from 'app/main/adquirente/shareds/interfaces/adquirente.interfaces';
import { Empresa } from 'app/main/empresa/shareds/interfaces/empresa.interface';
import { EmpresaModalService } from 'app/main/empresa/shareds/services/empresa-modal.service';
import { EmpresaService } from 'app/main/empresa/shareds/services/empresa.service';
import { ChecklistAdquirente } from '../../shared/interfaces/checklist-adquirente.interface';
import { Checklist } from '../../shared/interfaces/checklist.interface';
import { ChecklistAdquirenteService } from '../../shared/services/checklist-adquirente.service';

@Component({
    selector: 'checklist-adquirente',
    templateUrl: './checklist-adquirente.component.html',
    styleUrls: ['./checklist-adquirente.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ChecklistAdquirenteComponent extends FormBase implements OnInit {
    @Input() checklist: Checklist;
    @Output() gravando = new EventEmitter();
    isEdit = false;
    isVisualizacao: boolean = false;

    listaAdquirentes: Array<Adquirente> = [];
    listaEmpresas: Array<Empresa> = [];
    relacionamentos: Array<ChecklistAdquirente> = [];

    constructor(
        private _injector: Injector,
        private _modalEmpresa: EmpresaModalService,
        private _empresaService: EmpresaService,
        private _checklistAdquirenteService: ChecklistAdquirenteService,
    ) {
        super(_injector, undefined);
    }

    ngOnInit(): void {
        this.carregaEmpresas();
        this._route.data.subscribe((data) => {
            this.isVisualizacao = data.isVisualizacao;
            this.listaAdquirentes = data.listaAdquirentes ? data.listaAdquirentes : [];
            const relacionamentos = data.relacionamentos ? data.relacionamentos.adquirentes : [];
            relacionamentos.forEach((r) => {
                this.relacionamentos.push(
                    Object.assign({}, r, {
                        empresa: data.empresas.find(
                            (e) => e.id === r.idEmpresa
                        ),
                        adquirente: data.listaAdquirentes.find((a) => a.id === r.idAdquirente),
                    })
                );
            });
        });
    }

    protected crateForm(): { [key: string]: any } {
        return {
            adquirente: ['', Validators.required],
            empresa: ['', Validators.required],
            index: [],
        };
    }

    limparForm(): void {
        this.isEdit = false;
        this.form.reset();
    }

    adicionar(): void {
        const relacionamento: ChecklistAdquirente = this.form.value;
        relacionamento.idAdquirente = relacionamento.adquirente.id;
        relacionamento.idEmpresa = relacionamento.empresa.id;

        if (!this.isValidaInclusaoAdiquirente(relacionamento.adquirente, relacionamento.empresa)) {
            this._notification.error('Dados duplicados');
            return;
        }
        this.relacionamentos.push(relacionamento);
        this.limparForm();

    }

    atualizar(): void {
        const index = this.form.get('index').value;
        const relacionamento: ChecklistAdquirente = this.form.value;
        relacionamento.idAdquirente = relacionamento.adquirente.id;
        relacionamento.idEmpresa = relacionamento.empresa.id;

        if (!this.isValidaInclusaoAdiquirente(relacionamento.adquirente, relacionamento.empresa)) {
            this._notification.error('Dados duplicados');
            return;
        }

        this.relacionamentos[index] = relacionamento;
        this.limparForm();
    }

    remover(relacionamento: ChecklistAdquirente, index: number): void {
        this._confirmation
            .confirm({ titulo: `Deseja remover o adquirente?` })
            .subscribe((result) => {
                if (result) {
                    this.relacionamentos.splice(index, 1);
                }
            });
    }

    editar(relacionamento: ChecklistAdquirente, index: number): void {
        this.isEdit = true;
        this.form.patchValue(relacionamento);
        this.form.get('index').setValue(index);
    }

    gerenciarEmpresa() {
        this._modalEmpresa
            .empresaGrid(this.checklist)
            .subscribe(() => this.carregaEmpresas());
    }

    gravar() {
        this._spinner.show();
        this._checklistAdquirenteService
            .save(this.checklist.id, this.relacionamentos)
            .subscribe(
                () => {
                    this._spinner.hide();
                    this.gravando.emit(true);
                    this._notification.sucess(
                        'Adquirente salvos com sucesso!'
                    );
                },
                (error) => this.error(error)
            );
    }

    isValidaInclusaoAdiquirente(adquirente: Adquirente, empresa: Empresa): boolean {
        if (!adquirente || !empresa) return false;
        let isValid = true;

        this.relacionamentos.forEach(r => {
            if (r.adquirente.id === adquirente.id && r.empresa.id === empresa.id) {
                isValid = false;
            }
        })
        return isValid
    }

    private carregaEmpresas() {
        this._spinner.show();
        this._empresaService.find({ idChecklist: this.checklist.id }).subscribe(
            (result) => {
                this.listaEmpresas = result.data;
                this._spinner.hide();
            },
            (error) => this.error(error)
        );
    }
}
