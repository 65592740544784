import {
    ChangeDetectorRef,
    Component,
    Injector,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { CotacaoItemCotacao } from 'app/main/cotacao/shared/interfaces/cotacao-item-cotacao.interface';
import { Faturamento } from '../../shared/interfaces/faturamento.interface';
import { FiltroFaturamentoProduto } from '../../shared/interfaces/filtro-faturamento-produto.interface';
import { FaturamentoProdutoCotacaoService } from '../../shared/services/faturamento-produto-cotacao.service';

@Component({
    selector: 'faturamento-produto-grid',
    templateUrl: './faturamento-produto-grid.component.html',
    styleUrls: ['./faturamento-produto-grid.component.scss'],
})
export class FaturamentoProdutoGridComponent
    extends GridBase<
        CotacaoItemCotacao,
        FiltroFaturamentoProduto,
        FaturamentoProdutoCotacaoService
    >
    implements OnInit, OnChanges {
    public faturamento: Faturamento = {};

    constructor(
        protected _faturamentoProdutoService: FaturamentoProdutoCotacaoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef
    ) {
        super(_faturamentoProdutoService, _injector, _changeDetectorRefs);
    }

    ngOnInit(): void {
        this._route.data.subscribe((data) => {
            this.faturamento = data.faturamento;

            if (this.faturamento.id) {
                this.filtro.idFaturamento = this.faturamento.id;
                this.initGrid(this.filtro);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.atualizarGrid(this.filtro || {});
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'produto', label: '' },
            { chave: 'itemLinha', label: '' },
            { chave: 'valorItem', label: '' },
            { chave: 'quantidade', label: '' },
            { chave: 'valorTotal', label: '' },
            { chave: 'tipoItem', label: '' },
        ];
    }
}
