import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModuloListaResolver } from '../modulo/shareds/resolvers/modulo-lista.resolver';
import { ProdutoListaResolver } from '../produto/shareds/resolvers/produto-lista.resolver';
import { AdquirenteGuard } from './adquirente.guard';
import { AdquirenteCadastroPageComponent } from './page/adquirente-cadastro-page/adquirente-cadastro-page.component';
import { AdquirenteGridPageComponent } from './page/adquirente-grid-page/adquirente-grid-page.component';
import { AdquirenteDetalheResolver } from './shareds/resolvers/adquirente-detalhe.resolver';
import { AdquirenteListaResolver } from './shareds/resolvers/adquirente-lista.resolver';


const routes: Routes = [
  {
    path: '',
    canActivate: [AdquirenteGuard],
    canLoad: [AdquirenteGuard],
    resolve: {
      listaAdquirente: AdquirenteListaResolver
    },
    component: AdquirenteGridPageComponent,
  },
  {
    path: 'cadastro',
    canActivate: [AdquirenteGuard],
    canLoad: [AdquirenteGuard],
    resolve: {
      listaProdutos: ProdutoListaResolver,
      listaModulos: ModuloListaResolver
    },
    component: AdquirenteCadastroPageComponent,
  },
  {
    path: 'cadastro/:id/editar',
    canActivate: [AdquirenteGuard],
    canLoad: [AdquirenteGuard],
    resolve: {
      dadosAdiquirente: AdquirenteDetalheResolver,
      listaProdutos: ProdutoListaResolver,
      listaModulos: ModuloListaResolver,
      adquirente: AdquirenteDetalheResolver,
    },
    component: AdquirenteCadastroPageComponent,
  },
  {
    path: 'cadastro/:id/visualizar',
    canActivate: [AdquirenteGuard],
    canLoad: [AdquirenteGuard],
    resolve: {
      dadosAdiquirente: AdquirenteDetalheResolver,
      listaProdutos: ProdutoListaResolver,
      listaModulos: ModuloListaResolver,
      adquirente: AdquirenteDetalheResolver,
    },
    component: AdquirenteCadastroPageComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdquirenteRoutingModule { }
