import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { FormBase } from '@utils/base/form-base/form-base';
import { Time } from 'app/main/time/shareds/interfaces/time.interface';
import { TimeService } from 'app/main/time/shareds/services/time.service';
import { Usuario } from 'app/main/usuario/shared/interfaces/usuario.interface';
import { UsuarioService } from 'app/main/usuario/shared/services/usuario.service';
import { OsCategoria } from '../../shareds/interfaces/os-categoria.interface';
import { OsEditar } from '../../shareds/interfaces/os-editar.interface';
import { OsStatus } from '../../shareds/interfaces/os-status.interface';
import { OsCategoriaService } from '../../shareds/services/os-categoria.service';
import { OsEdicaoService } from '../../shareds/services/os-edicao.service';
import { OsStatusService } from '../../shareds/services/os-status.service';

@Component({
    selector: 'editar-os',
    templateUrl: './editar-os.component.html',
    styleUrls: ['./editar-os.component.scss'],
})
export class EditarOsComponent extends FormBase implements OnInit {
    private _osEditar: OsEditar;
    isView: boolean = false;
    isViewOsSemAbertura: boolean = false;
    osSemAberturas: Array<number> = [];
    listaStatusOs: Array<OsStatus> = [];
    listaCategorias: Array<OsCategoria> = [];
    listaResponsavel: Array<Usuario> = [];
    listaTime: Array<Time> = [];

    checkStatusOs: boolean = false;
    checkCategoria: boolean = false;
    checkResponsavel: boolean = false;
    checkTime: boolean = false;
    todosFull: boolean = false;
    checkPlannedDate: boolean = false;

    @Output() atualizado = new EventEmitter<boolean>();

    get idsOs() {
        return this._osEditar;
    }

    @Input() set idsOs(osEditar: OsEditar) {
        const idsCotacoes = osEditar.idsOs;
        this._osEditar = osEditar;
        if (idsCotacoes && idsCotacoes.length > 0) {
            this.isView = true;
        }
    }

    constructor(
        private _injector: Injector,
        private _statusService: OsStatusService,
        private _categoriaService: OsCategoriaService,
        private _usuarioService: UsuarioService,
        private _timeService: TimeService,
        private _service: OsEdicaoService,
    ) {
        super(_injector);
    }

    ngOnInit() {
        this._spinner.show();
        this._statusService.find({}).subscribe(
            (result) => {
                this.listaStatusOs = result.data;
                this._spinner.hide();
            },
            (error) => this.error(error)
        );
        this._categoriaService.find({}).subscribe(
            (result) => {
                this.listaCategorias = result.data;
                this._spinner.hide();
            },
            (error) => this.error(error)
        );
        this._usuarioService.find({ ativo: 1, tipo: 1 }).subscribe(
            (result) => {
                this.listaResponsavel = result.data;
                this._spinner.hide();
            },
            (error) => this.error(error)
        );
        this._timeService.find({}).subscribe(
            (result) => {
                this.listaTime = result.data;
                this._spinner.hide();
            },
            (error) => this.error(error)
        );
        this.hasNotOsAbertura();
    }

    hasNotOsAbertura(): void {
        if (this._osEditar.idsOs && this._osEditar.idsOs.length > 0) {
            this._spinner.show();
            this._service.validaOsAbertura(this._osEditar).subscribe(
                (result) => {
                    this._spinner.hide();
                    const osSemAberturas: Array<any> = result.data;
                    this.isViewOsSemAbertura = false;

                    osSemAberturas.forEach((result) => {
                        for (var v in result) {
                            if (!result[v]) {
                                this.isViewOsSemAbertura = true;
                                this.osSemAberturas.push(parseInt(v));
                            }
                        }
                    });
                },
                (error) => this.error(error)
            );
        }
    }

    selecionaCheckStatusOs(check) {
        this.checkStatusOs = check;
        if (!check) {
            this.form.get('statusOs').setValue('');
        }
    }
    selecionaCheckCategoria(check) {
        this.checkCategoria = check;
        if (!check) {
            this.form.get('categoria').setValue('');
        }
    }
    selecionaCheckResponsavel(check) {
        this.checkResponsavel = check;
        if (!check) {
            this.form.get('responsavel').setValue('');
        }
    }
    selecionaCheckTime(check) {
        this.checkTime = check;
        if (!check) {
            this.form.get('time').setValue('');
        }
    }
    selecionaCheckPlannedDate(check) {
        this.checkPlannedDate = check;
        if (!check) {
            this.form.get('dataPlanejada').setValue('');
        }
    }

    selecionaTodos(check) {
        if (!check) {
            this.form.reset();
        }
        this.checkStatusOs = check;
        this.checkCategoria = check;
        this.checkResponsavel = check;
        this.checkTime = check;
        this.checkPlannedDate = check;
    }

    isDesabled(chave: string) {
        switch (chave) {
            case 'checkStatusOs':
                return !this.checkStatusOs;
                break;
            case 'checkCategoria':
                return !this.checkCategoria;
                break;
            case 'checkResponsavel':
                return !this.checkResponsavel;
                break;
            case 'checkTime':
                return !this.checkTime;
                break;
            case 'checkPlannedDate':
                return !this.checkPlannedDate;
                break;
        }
    }

    alterar() {
        this._osEditar = Object.assign({}, this._osEditar, {
            idOsStatus: this.form.get('statusOs').value,
            idCategoria: this.form.get('categoria').value,
            idResponsavel: this.form.get('responsavel').value,
            idTime: this.form.get('time').value,
            dataPlanejada: this.form.get('dataPlanejada').value
        });

        if (this._osEditar.idsOs) {
            this._service.update(this._osEditar).subscribe(
                () => {
                    this.sucess('Cotações alteradas com sucesso!');
                    this.atualizado.emit(true);
                },
                (error) => this.error(error)
            );
        }
    }

    protected crateForm(): { [key: string]: any } {
        return {
            statusOs: [],
            categoria: [],
            responsavel: [],
            time: [],
            dataPlanejada: [],
        };
    }
}
