import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalDocumentUploadComponent } from '../../modal/modal-document-upload/modal-document-upload.component';

@Injectable({
  providedIn: 'root'
})
export class DocumentModalService {

  constructor(public dialog: MatDialog) { }

  upload(data: any) {
    const dialogRef = this.dialog.open(ModalDocumentUploadComponent, {
      width: '40%',
      data: data,
    });

    return dialogRef.afterClosed();
  }
}
