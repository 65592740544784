import {
    ChangeDetectorRef,
    Component,
    Injector,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { SelectionModel } from '@angular/cdk/collections';
import { Gmud } from '../../shared/interfaces/gmud.interface';
import { FiltroGmud } from '../../shared/interfaces/filtro-gmud.interface';
import { GmudService } from '../../shared/services/gmud.service';
import { GmudModalService } from '../../shared/services/gmud-modal.service';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';

@Component({
    selector: 'gmud-grid',
    templateUrl: './gmud-grid.component.html',
    styleUrls: ['./gmud-grid.component.scss'],
})
export class GmudGridComponent extends GridBase<Gmud, FiltroGmud, GmudService> implements OnInit, OnChanges {
    selection = new SelectionModel<Gmud>(true, []);

    constructor(
        protected _gmudService: GmudService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        private _modalGmud: GmudModalService,
    ) {
        super(_gmudService, _injector, _changeDetectorRefs, 'gmud');
    }

    ngOnInit(): void {
        this.initGrid(this.filtro || {});
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.atualizarGrid(this.filtro || {});
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'status', label: 'Status' },
            { chave: 'gmud_id', label: 'ID GMUD' },
            { chave: 'produto', label: 'Produto' },
            { chave: 'gmud_data', label: 'Data Execução' },
            { chave: 'created_at', label: 'Data Criação' },
            { chave: 'tipo', label: 'Tipo da Mudança' },
            { chave: 'impacto', label: 'Impacto da Mudança' },
            { chave: 'tipo_solucao', label: 'Tipo da Solução' },
        ];
    }

    cadastrar() {
        this._router.navigateByUrl('gmud/cadastro')
    }

    escolherRelatorio() {
        this._modalGmud.relatorios(this.filtro);
    }

    editar(gmud: Gmud) {
        this._router.navigate(['gmud', 'edicao', gmud.gmud_id])
    }

    visualizar(gmud: Gmud) {
        this._router.navigate(['gmud', 'visualizacao', gmud.gmud_id])
    }

    historico(gmud: Gmud) {
        this._modalGmud.historico(gmud);
    }

    recomendacao(gmud: Gmud) {
        this._modalGmud.recomendacao(gmud);
    }

    rollback(gmud: Gmud) {
        this._confirmation
            .confirm({
                titulo:
                    'Tem certeza que deseja continuar?',
                mensagem:
                    'Atenção: Você está prestes a alterar o status da GMUD para ROLLBACK REALIZADO.',
            })
            .subscribe((result) => {
                if (result) {
                    this._service.rollback(gmud.gmud_id).subscribe(
                        () => {
                            this.atualizarGrid();
                            this.sucess('Rollback realizado com sucesso!');
                        },
                        (error) => this.error(error)
                    );
                }
            });
    }

    finalizar(gmud: Gmud) {
        this._confirmation
            .confirm({
                titulo:
                    'Tem certeza que deseja continuar?',
                mensagem:
                    'Atenção: Você está prestes a FINALIZAR a GMUD.',
            })
            .subscribe((result) => {
                if (result) {
                    this._service.finalizar(gmud.gmud_id).subscribe(
                        () => {
                            this.atualizarGrid();
                            this.sucess('GMUD finalizada com sucesso!');
                        },
                        (error) => this.error(error)
                    );
                }
            });
    }

    cancelar(gmud: Gmud) {
        this._confirmation
            .confirm({
                titulo:
                    'Tem certeza que deseja continuar?',
                mensagem:
                    'Atenção: Você está prestes a CANCELAR a GMUD.',
            })
            .subscribe((result) => {
                if (result) {
                    this._service.cancelar(gmud.gmud_id).subscribe(
                        () => {
                            this.atualizarGrid();
                            this.sucess('GMUD cancelada com sucesso!');
                        },
                        (error) => this.error(error)
                    );
                }
            });
    }

    formatDate(date) {
        return new Date(date).toLocaleDateString('pt-BR', {
            timeZone: 'UTC'
        })
    }
}
