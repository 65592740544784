import { Component, Injector, OnInit } from '@angular/core';
import { ChacklistPageBase } from '../../shared/base/checklist-page-base';

@Component({
  selector: 'app-checklist-produto-financeiro-page',
  templateUrl: './checklist-produto-financeiro-page.component.html',
  styleUrls: ['./checklist-produto-financeiro-page.component.scss']
})
export class ChecklistProdutoFinanceiroPageComponent extends ChacklistPageBase implements OnInit {
  carregarMenu: boolean;
  validate: Function;
  constructor(protected _injector: Injector) {
    super(_injector)
  }

  ngOnInit() {
  }

  atualizarMenu(rusult) {
    this.carregarMenu = Object.assign({});
  }

  refreshValidation(event) {
    this.validate = (valid) => {
      if (valid) {
        event[0]();
        return;
      }
      event[1]();
    }
  }

}
